import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import LazyLoad from 'react-lazyload'

const NewsLoad = ({ selectedType, selectedlang }) => {
  const [newsData, setNewsData] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)

  const fetchData = async page => {
    setLoading(true)
    let apiUrl = ''
    if (selectedType == 'bitcoin') {
      // console.log('bitcoin')
      apiUrl = `https://api.coinotify.io/news/getUpdatesByCoin?page=${page}&limit=10&type=${selectedType}`
    } else {
      apiUrl = `https://api.coinotify.io/news/allNews?page=${page}&limit=10&type=${selectedType}`
      // apiUrl = `https://api.coinotify.io/news/allNews?page=1&limit=10&type=${selectedType}&lang=${selectedlang}`
    }
    try {
      const response = await fetch(apiUrl)
      if (!response.ok) {
        throw new Error('Failed to fetch data')
      }
      const data = await response.json()
      setNewsData(prevData => [...prevData, ...data.data])
      // console.log(data.data)
    } catch (error) {
      console.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setNewsData([])
    setPage(1)
    fetchData(1)
  }, [selectedType])

  const handleShowMore = () => {
    const nextPage = page + 1
    setPage(nextPage)
    fetchData(nextPage)
  }

  const formatTimestamp = timestamp => {
    const oneDayInMs = 24 * 60 * 60 * 1000 // One day in milliseconds
    const now = new Date()
    const articleDate = new Date(timestamp)
    const timeDifference = now - articleDate
    const isOlderThanOneDay = timeDifference > oneDayInMs

    if (isOlderThanOneDay) {
      return `${articleDate.toLocaleDateString()} ${articleDate.toLocaleTimeString(
        [],
        { hour: '2-digit', minute: '2-digit', hour12: true }
      )}`
    } else {
      // Display time otherwise
      return articleDate.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      })
    }
  }

  const navigate = useNavigate()

  // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // bookmark functinality//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [bookmarks, setBookmarks] = useState({})
  const [error, setError] = useState('')

  const token = localStorage.getItem('token')

  useEffect(() => {
    // Fetch initial bookmark status for all articles
    const fetchInitialBookmarks = async () => {
      try {
        // Replace with your actual API endpoint for fetching bookmark status
        const response = await fetch('https://api.coinotify.io/user/bookmark', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        })
        if (!response.ok) {
          throw new Error('Failed to fetch initial bookmark status')
        }
        const data = await response.json()
        const initialBookmarks = {}
        data.forEach(bookmark => {
          initialBookmarks[bookmark.articleId] = true // Assuming your API returns the bookmark status
        })
        setBookmarks(initialBookmarks)
      } catch (error) {
        // console.error('Error fetching initial bookmark status:', error)
        // setError('Failed to fetch initial bookmark status. Please try again.')
      }
    }

    fetchInitialBookmarks()
  }, [])
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const handleCloseError = () => {
    setError('')
  }

  const [bookmarkedIds, setBookmarkedIds] = useState([])

  useEffect(() => {
    // Fetch bookmark status from the server on mount
    const fetchBookmarkStatus = async () => {
      try {
        const response = await fetch('https://api.coinotify.io/user/bookmark', {
          headers: {
            Authorization: `token ${token}`
          }
        })
        if (response.ok) {
          const data = await response.json()
          const bookmarks = data.map(bookmark => bookmark.id) // Assuming 'id' is the key in the bookmark data
          setBookmarkedIds(bookmarks)
        } else {
          // console.error('Error fetching bookmark status:', response.statusText)
        }
      } catch (error) {
        // console.error('Error fetching bookmark status:', error)
      }
    }

    fetchBookmarkStatus()
  }, [newsData, token])

  const handleBookmarkToggle = async id => {
    if (!token) {
      setError('Please log in first to bookmark articles.')
      setTimeout(() => setError(''), 2000)
      return
    }

    try {
      if (bookmarkedIds.includes(id)) {
        const response = await fetch('https://api.coinotify.io/user/bookmark', {
          method: 'DELETE',
          body: JSON.stringify({
            article: {
              articleId: id
            }
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        })
        setBookmarkedIds(prevState =>
          prevState.filter(bookmarkId => bookmarkId !== id)
        )
      } else {
        // Call the API to add the bookmark using POST method
        const response = await fetch('https://api.coinotify.io/user/bookmark', {
          method: 'POST',
          body: JSON.stringify({
            article: {
              articleId: id
            }
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        })

        if (response.status === 201) {
          setShowSuccessModal(true)
          setTimeout(() => setShowSuccessModal(false), 2000)
        }
        // console.log('bokmarkkkkkkkkkkkkkkkkkkkkkkk', response)
        setBookmarkedIds(prevState => [...prevState, id])
      }
    } catch (error) {
      console.error('Error toggling bookmark:', error)
    }
  }
  // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // utils.js

  const slugifyTitle = title => {
    const truncatedTitle = title.split(' ').slice(0, 6).join(' ') // Get first 6 words
    return truncatedTitle
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^\s+|\s+$)+/g, '')
      .replace(/\s+/g, '-')
  }

  const transformType = type => {
    return type === 'allCoin' ? 'crypto' : type
  }

  const handleNavigation = (id, title) => {
    const path = `/news/${transformType(selectedType)}/${id}/${slugifyTitle(
      title
    )}`
    navigate(path)
  }
  // ////////////////////////
  return (
    <>
      <div className='flex flex-wrap gap-10 w-full'>
        {newsData.map((item, index) => (
          <div
            key={index}
            className='relative bg-cover bg-center group h-full w-[395px] rounded-lg overflow-hidden shadow-lg cursor-pointer z-[99]'
          >
            <div className='h-[150px]'>
              <LazyLoad height={500} offset={100}>
                <img
                  src={item.thumbNail}
                  alt={`Slide ${index}`}
                  className='slideshowimage w-full h-[150px]'
                  onClick={() => handleNavigation(item.id, item.articleTitle)}
                />
              </LazyLoad>
            </div>
            <div className=' inset-0 bg-[#363636] bg-opacity-50 flex flex-col justify-end p-4 !font-lora !font-normal'>
              <h1
                onClick={() => handleNavigation(item.id, item.articleTitle)}
                className='text-[18px] group-hover:underline text-[#fff]  textlg'
              >
                {item.articleTitle}
                {/* {truncateTitle(item.articleTitle)} */}
              </h1>
              <div className='text-white mt-2 flex justify-between items-center'>
                <Link
                  to={item.articleSource}
                  target='_blank'
                  rel='noopener noreferrer'
                  className=' text-[#fff] '
                  onClick={() => handleNavigation(item.id, item.articleTitle)}
                >
                  {item.articleSource.replace('https://', '')}
                </Link>
                <p className='textgray-300 flex gap-2 text-[20px] text-[#fff] mt-0 z-[999999999999999999999999999999999999999]'>
                  {formatTimestamp(item.articleTimestamp)}

                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    fill={bookmarkedIds.includes(item.id) ? 'yellow' : 'none'}
                    stroke='currentColor'
                    className='w-6 h-6 cursor-pointer'
                    onClick={e => {
                      e.stopPropagation()
                      handleBookmarkToggle(item.id)
                    }}
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={2}
                      d='M5 3v18l7-5 7 5V3z'
                    />
                  </svg>
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {!loading && (
        <button
          onClick={handleShowMore}
          className='show-more-button bg-amber-600 p-3 rounded-md'
        >
          Show More
        </button>
      )}

      {showSuccessModal && (
        <div
          className='fixed top-40 right-4 w[500px] z-50 max-w-xs bg-[#15ff21] text-white p-4 rounded-lg shadow-lg transform transition-transform duration-500 ease-in-out translate-x-full'
          style={{
            transform: showSuccessModal ? 'translateX(0)' : 'translateX(130%)'
          }}
        >
          <div className='flex justify-between items-center'>
            <span>Bookmark added successfully!</span>
            <button onClick={handleCloseError} className='ml-4 text-xl'>
              &times;
            </button>
          </div>
        </div>
      )}

      {error && (
        <div
          className='fixed bottom-28 w[500px] right-4 z-50 max-w-xs bg-red-500 text-white p-4 rounded-lg shadow-lg transform transition-transform duration-500 ease-in-out translate-x-full'
          style={{ transform: error ? 'translateX(0)' : 'translateX(130%)' }}
        >
          <div className='flex justify-between items-center'>
            <span>{error}</span>
            <button onClick={handleCloseError} className='ml-4 text-xl'>
              &times;
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default NewsLoad
