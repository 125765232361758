import React from "react";

import { Img, Text } from "components";

const MacBookPro14FourColumn = (props) => {
  return (
    <>
      <div className={props.className}>
        <div className="bg-white-A700 flex flex-col items-start justify-start rounded w-auto md:w-full">
          <div className="bg-amber-600 flex flex-row gap-1 h-10 md:h-auto items-center justify-start p-3 rounded w-32 md:w-full">
            <Text
              className="flex-1 text-center text-sm text-white-A700 tracking-[-0.28px] w-auto"
              size="txtRalewaySemiBold14"
            >
              {props?.signintext}
            </Text>
            <Img
              className="h-4 w-4"
              src="images/img_arrowdown.svg"
              alt="arrowdown"
            />
          </div>
        </div>
      </div>
    </>
  );
};

MacBookPro14FourColumn.defaultProps = { signintext: "Sign In" };

export default MacBookPro14FourColumn;
