import React from "react";

import { Text } from "components";

const MacBookPro14FourColumnprice1 = (props) => {
  const truncatedDescription =
    props?.description?.props?.children[0]?.substring(0, 350);

  return (
    <>
      <div className={props.className}>
        <div className="border-b border-black-900_1e border-solid flex flex-col items-start justify-start pb-3 w-full">
          <div className="flex flex-col gap-3 items-start justify-start w-full">
            <Text
              className="max-w-[358px] md:max-w-full text-white-A700 text-xl"
              size="txtLoraRomanSemiBold20"
            >
              {props?.title}
            </Text>
            <div className="flex flex-row gap-[15px] items-start justify-start w-full">
              <Text
                className="flex-1 text-white-A700 text-xs w-auto"
                size="txtAssistantRomanRegular12WhiteA700"
              >
                {props?.website}
              </Text>
              <Text
                className="text-white-A700 text-xs w-auto"
                size="txtAssistantRomanRegular12WhiteA700"
              >
                {props?.time}
              </Text>
            </div>
          </div>
        </div>
        <Text
          className="max-w-[358px] md:max-w-full text-base text-indigo-100"
          size="txtLoraRomanRegular16"
        >
          <div className="description">{truncatedDescription}...</div>
        </Text>
      </div>
    </>
  );
};

MacBookPro14FourColumnprice1.defaultProps = {
  title: "Circle USDC Soars $100M in Weekly Circulation Boost",
  website: "Bitcoinsistemi.com",
  time: "Feb. 4, 2023, 10:10 PM",
  description: (
    <>
      Circle USDC, a stablecoin backed by the US dollar, has seen a significant
      increase in its circulation in the past week. According to the latest data
      from [Circle], the total supply of USDC has grown by over $100 million,
      reaching a new all-time high of $1.4 billion as of November 9, 2023.
      <br />
      The surge in USDC circulation comes amid a growing demand for stablecoins
      in the crypto market, especially as Bitcoin and other cryptocurrencies
    </>
  ),
};

export default MacBookPro14FourColumnprice1;
