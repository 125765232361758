import React, { useEffect, useState } from 'react'
import { useParams, useLocation, Link, useNavigate } from 'react-router-dom'
import DOMPurify from 'dompurify'
import './Sider.css'
import { Helmet } from 'react-helmet'

const ArticlePage = () => {
  // const { selectedType, articleUrl } = useParams()
  // const { articleUrl } = useParams()

  const formatTimestamp = timestamp => {
    const oneDayInMs = 24 * 60 * 60 * 1000 // One day in milliseconds
    const now = new Date()
    const articleDate = new Date(timestamp)
    const timeDifference = now - articleDate
    const isOlderThanOneDay = timeDifference > oneDayInMs

    if (isOlderThanOneDay) {
      return `${articleDate.toLocaleDateString()} ${articleDate.toLocaleTimeString(
        [],
        { hour: '2-digit', minute: '2-digit', hour12: true }
      )}`
    } else {
      // Display time otherwise
      return articleDate.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      })
    }
  }

  const navigate = useNavigate()
  // const domainName = getDomainName(articleUrl)

  // const { articleTitle } = useParams()
  // const [articleData, setArticleData] = useState(null)
  const [articleHead, setArticleHead] = useState(null)
  const [articleUrl, setArticleUrl] = useState(null)
  const [articleTime, setArticleTime] = useState(null)
  const [articleSum, setArticleSum] = useState(null)
  const { id, selectedType } = useParams()
  // console.log(articleTitle)
  // console.log(articleUrl)

  const unslugifyTitle = slug => {
    return slug
      .replace(/-/g, ' ') // Replace hyphens with spaces
      .replace(/\b\w/g, char => char.toUpperCase()) // Capitalize first letter of each word
  }
  // console.log(unslugifyTitle(articleTitle))

  // const transformType = type => {
  const typee = selectedType === 'crypto' ? 'allCoin' : selectedType
  // }
  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch(
          // 'https://api.coinotify.io/news/allNews?page=1&limit=100'
          // `https://api.coinotify.io/news/allNews?page=1&limit=1000&type=${typee}`
          `https://api.coinotify.io/news/getSummaryById?id=${id}`
        ) // Adjust the URL and limit as needed
        if (!response.ok) {
          throw new Error('Failed to fetch articles')
        }
        const data = await response.json()
        // console.log(decodedTitle)
        // console.log('Fetched data:', data)
        // console.log('Fetched datajjjjjjjjjjjjjjjjj:', data.articleUrl)
        setArticleSum(data.articleSummary)
        setArticleHead(data.articleTitle)
        setArticleUrl(data.articleUrl)
        setArticleTime(data.articleTimestamp)
        // console.log('Fetched url.........................:', articleUrl)
        // console.log(foundArticle)
      } catch (error) {
        console.error('Error fetching articles:', error.message)
      }
    }

    fetchArticles()
  }, [id])

  // useEffect(() => {
  //   if (!articleData) {
  //     const fetchArticleData = async () => {
  //       try {
  //         const response = await fetch(
  //           `https://api.coinotify.io/news/getSummaryByURL?url=${articleUrl}`
  //         )
  //         if (!response.ok) {
  //           throw new Error('Network response was not ok')
  //         }
  //         const data = await response.json()
  //         setArticleData(data)
  //       } catch (error) {
  //         console.error(
  //           'There has been a problem with your fetch operation:',
  //           error
  //         )
  //       }
  //     }

  //     fetchArticleData()
  //   }
  // }, [articleUrl, articleData])
  const [articleSummary, setArticleSummary] = useState('')

  const sanitizedHtml = DOMPurify.sanitize(articleSum)

  useEffect(() => {
    const sanitizedHtml = DOMPurify.sanitize(articleSum)
    const parser = new DOMParser()
    const doc = parser.parseFromString(sanitizedHtml, 'text/html')
    const textContent = doc.body.textContent || ''
    const first200Words = textContent.split(/\s+/).slice(0, 28).join(' ')
    setArticleSummary(first200Words)
  }, [articleSum])
  // console.log(articleSummary)

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{articleHead}</title>
        <meta name='description' content={articleSummary} />
        {/* <link rel='canonical' href={`/news/${type}/${articleTitle}`} /> */}
      </Helmet>

      <>
        {' '}
        <div className='mt-20 md:mt-40 max-w-7xl mx-auto article-container !font-lora !font-normal'>
          <div className=' w-[70%] relative md:w-full md:p-4'>
            <div>
              <h1>{articleHead}</h1>

              <div className='flex items-center gap-2 mb-10 !text-[14px]'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                >
                  <path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1 16.057v-3.057h2.994c-.059 1.143-.212 2.24-.456 3.279-.823-.12-1.674-.188-2.538-.222zm1.957 2.162c-.499 1.33-1.159 2.497-1.957 3.456v-3.62c.666.028 1.319.081 1.957.164zm-1.957-7.219v-3.015c.868-.034 1.721-.103 2.548-.224.238 1.027.389 2.111.446 3.239h-2.994zm0-5.014v-3.661c.806.969 1.471 2.15 1.971 3.496-.642.084-1.3.137-1.971.165zm2.703-3.267c1.237.496 2.354 1.228 3.29 2.146-.642.234-1.311.442-2.019.607-.344-.992-.775-1.91-1.271-2.753zm-7.241 13.56c-.244-1.039-.398-2.136-.456-3.279h2.994v3.057c-.865.034-1.714.102-2.538.222zm2.538 1.776v3.62c-.798-.959-1.458-2.126-1.957-3.456.638-.083 1.291-.136 1.957-.164zm-2.994-7.055c.057-1.128.207-2.212.446-3.239.827.121 1.68.19 2.548.224v3.015h-2.994zm1.024-5.179c.5-1.346 1.165-2.527 1.97-3.496v3.661c-.671-.028-1.329-.081-1.97-.165zm-2.005-.35c-.708-.165-1.377-.373-2.018-.607.937-.918 2.053-1.65 3.29-2.146-.496.844-.927 1.762-1.272 2.753zm-.549 1.918c-.264 1.151-.434 2.36-.492 3.611h-3.933c.165-1.658.739-3.197 1.617-4.518.88.361 1.816.67 2.808.907zm.009 9.262c-.988.236-1.92.542-2.797.9-.89-1.328-1.471-2.879-1.637-4.551h3.934c.058 1.265.231 2.488.5 3.651zm.553 1.917c.342.976.768 1.881 1.257 2.712-1.223-.49-2.326-1.211-3.256-2.115.636-.229 1.299-.435 1.999-.597zm9.924 0c.7.163 1.362.367 1.999.597-.931.903-2.034 1.625-3.257 2.116.489-.832.915-1.737 1.258-2.713zm.553-1.917c.27-1.163.442-2.386.501-3.651h3.934c-.167 1.672-.748 3.223-1.638 4.551-.877-.358-1.81-.664-2.797-.9zm.501-5.651c-.058-1.251-.229-2.46-.492-3.611.992-.237 1.929-.546 2.809-.907.877 1.321 1.451 2.86 1.616 4.518h-3.933z' />
                </svg>
                <a
                  href={articleUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='!text-[#000]  flex items-center justify-start gap-1'
                >
                  {articleUrl &&
                    articleUrl.replace(
                      /^(?:https?:\/\/)?(?:www\.)?([^\/]+).*$/,
                      '$1'
                    )}
                </a>
                <span className='text-[14px]'>
                  {formatTimestamp(articleTime)}
                </span>
              </div>
            </div>
            <div
              className='relative'
              dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
            />

            <div className=' mt-10'>
              <a
                href={articleUrl}
                target='_blank'
                rel='noopener noreferrer'
                className='text-[#fff] mt-10 flex items-center justify-start gap-1'
              >
                {articleUrl &&
                  articleUrl.replace(
                    /^(?:https?:\/\/)?(?:www\.)?([^\/]+).*$/,
                    '$1'
                  )}

                <svg
                  width='15'
                  height='15'
                  viewBox='0 0 25 25'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M10.4116 17.5933L15.4116 12.5933L10.4116 7.59326'
                    stroke='#007bff'
                    strokeWidth='3'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </a>
            </div>
          </div>
          <div className='w-[30%] md:hidden'></div>
        </div>
      </>

      {/* <h1 className='bg-black-900_3d h-screen'>hwuelad</h1> */}
    </>
  )
}

export default ArticlePage
