import React from "react";
import PropTypes from "prop-types";

const shapes = {
  round: "rounded",
  circle: "rounded-[50%]",
  square: "rounded-none",
};
const variants = {
  fill: {
    indigo_A200: "bg-indigo-A200",
    green_A200: "bg-green-A200",
    blue_gray_900_02: "bg-blue_gray-900_02",
    yellow_700: "bg-yellow-700",
    blue_gray_900_01: "bg-blue_gray-900_01 text-white-A700",
    black_900_1e: "bg-black-900_1e text-white-A700",
    amber_600: "bg-amber-600 text-white-A700",
  },
};
const sizes = { xs: "p-0.5", sm: "p-2.5", md: "p-[23px] sm:px-5" };

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape = "",
  size = "",
  variant = "",
  color = "",
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""} ${(variant && variants[variant]?.[color]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  shape: PropTypes.oneOf(["round", "circle", "square"]),
  size: PropTypes.oneOf(["xs", "sm", "md"]),
  variant: PropTypes.oneOf(["fill"]),
  color: PropTypes.oneOf([
    "indigo_A200",
    "green_A200",
    "blue_gray_900_02",
    "yellow_700",
    "blue_gray_900_01",
    "black_900_1e",
    "amber_600",
  ]),
};

export { Button };
