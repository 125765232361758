import React from 'react'

import { Img } from 'components'

import img2 from './assets/img_uillanguage.svg'
import img3 from './assets/img_arrowdown_white_a700.svg'
import { Link } from 'react-router-dom'

const MacBookPro14ThirteenColumnOne = props => {
  return (
    <>
      <div className={props.className}></div>
    </>
  )
}

MacBookPro14ThirteenColumnOne.defaultProps = {}

export default MacBookPro14ThirteenColumnOne
