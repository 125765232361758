import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'
import TopNav from 'components/topnav'
import { Text } from 'components'
import { Input } from 'components/Input'
import { TextArea } from 'components/TextArea'
import Footer from 'components/Footer'
// import { Button } from "components";
import MacBookPro14ThirteenColumn from 'components/MacBookPro14ThirteenColumn'
import MacBookPro14ThirteenColumnOne from 'components/MacBookPro14ThirteenColumnOne'
import { Helmet } from 'react-helmet'
const Img = ({
  className,
  src = 'defaultNoData.png',
  alt = 'testImg',
  ...restProps
}) => {
  return (
    <img
      className={className}
      src={src}
      alt={alt}
      {...restProps}
      loading={'lazy'}
    />
  )
}
const shapes = { round: 'rounded' }
const variants = {
  fill: {
    yellow_900: 'bg-yellow-900 text-white-A700',
    amber_600: 'bg-amber-600'
  }
}
const sizes = { xs: 'py-2.5', sm: 'p-[13px]' }

const Button = ({
  children,
  className = '',
  leftIcon,
  rightIcon,
  shape = '',
  size = '',
  variant = '',
  color = '',
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${(shape && shapes[shape]) || ''} ${
        (size && sizes[size]) || ''
      } ${(variant && variants[variant]?.[color]) || ''}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  )
}
const Advertise = () => {
  const navigate = useNavigate()

  const [email, setEmail] = useState('')

  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const [fillerror, setFillError] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState('')
  const handleSubmit = async e => {
    if (!email || !message) {
      setFillError(true)
      return
    }
    setFillError(false)
    e.preventDefault()

    // console.log(email)
    // console.log(message)
    // console.log(resume);
    try {
      const response = await fetch('https://api.coinotify.io/user/contactUs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          message: message
        })
      })

      if (!response.ok) {
        console.error('An error occurred:', response)
        // console.log(response)
        return
      }

      const data = await response.json()
      // console.log('Response data:', data)
      // alert(response.status)
      setError(response.message)
      // console.log(data.message)
      setTimeout(() => setError(''), 3000)
      setShowSuccessModal(data.message)
      setTimeout(() => setShowSuccessModal(''), 3000)
    } catch (error) {
      // alert('Failed to send email')
      console.error('Error occurred while sending email:', error)
    }
  }

  const handleCloseError = () => {
    setError('')
  }

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Coinotify || Advertise</title>
        <link rel='canonical' href='https://coinotify.io/advertise' />
      </Helmet>
      <div className='bg-gray-900 h- flex flex-col pb-10 font-inter items-center justify-start mx-auto w-full'>
        <div className='flex flex-col items-start justify-start w-full'>
          {/* <TopNav /> */}
          <div className='bg-gray-900 flex flex-col font-raleway h-[57px] md:h-auto items-center justify-end md:ml-[0] ml-[69px] mt-[38px] md:px-5 px-6 py-[18px] w-[159px]'></div>
          <div className='flex md:flex-col flex-row font-raleway md:gap-10 items-start justify-center gap-20 max-w-[1378px] mt-[22px] mx-auto md:px-5 w-full'>
            <div className='bg-blue_gray-900 flex md:flex-1 flex-col items-center justify-end p-[39px] sm:px-5 rounded-[5px] w-2/5 md:w-full'>
              <input
                style={{
                  borderColor: fillerror ? '#ff0000' : '#ffffff'
                }}
                type='email'
                required
                name='frame4001'
                placeholder='Enter your email'
                onChange={e => setEmail(e.target.value)}
                className='font-medium font-plusjakartasans md:h-auto bg-blue_gray-900 border-[1px] text-[#fff]  p-3 placeholder:text-[#fff] sm:h-auto text-left text-lg w-full'
                wrapClassName='mt-[51px] w-[99%]'
              />
              <textarea
                style={{
                  borderColor: fillerror ? '#ff0000' : '#ffffff'
                }}
                className='bg-transparent  border-solid border-white-A700_11 text-[#fff] border-[1px] font-medium font-plusjakartasans gap-2 h-[169px] mt-[58px] px-4 py-2.5 rounded-lg placeholder:text-[#fff]  text-left text-lg w-full'
                name='frame4354'
                placeholder='Type something...'
                onChange={e => setMessage(e.target.value)}
                required
              ></textarea>
              <Button
                className='cursor-pointer font-bold font-raleway h-[45px] leading-[normal] my-[30px] rounded-[22px] text-[15px] text-center tracking-[0.10px] w-[457px] md:w-full'
                color='yellow_900'
                size='sm'
                variant='fill'
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
          {/* <Footer className='bg-blue_gray-900_01 flex items-center justify-center mt-[1361px] md:px-5 w-full' /> */}
        </div>
      </div>

      <div
        className='fixed bottom-28 w[500px] right-4 z-50 max-w-xs bg-red-500 text-white p-4 rounded-lg shadow-lg transform transition-transform duration-500 ease-in-out translate-x-full'
        style={{ transform: error ? 'translateX(0)' : 'translateX(130%)' }}
      >
        <div className='flex justify-between items-center'>
          <span>{error}</span>
          <button onClick={handleCloseError} className='ml-4 text-xl'>
            &times;
          </button>
        </div>
      </div>

      <div
        className='fixed top-40 right-4 w[500px] z-50 max-w-xs bg-[#15ff21] text-white p-4 rounded-lg shadow-lg transform transition-transform duration-500 ease-in-out translate-x-full'
        style={{
          transform: showSuccessModal ? 'translateX(0)' : 'translateX(130%)'
        }}
      >
        <div className='flex justify-between items-center'>
          <span>Email sent successfull</span>
          <button onClick={handleCloseError} className='ml-4 text-xl'>
            &times;
          </button>
        </div>
      </div>
    </>
  )
}

export default Advertise
