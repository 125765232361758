import React, { useState, useEffect } from 'react'
import { Text } from 'components'
import { Link, useParams } from 'react-router-dom'

const NewsTND = ({ selectedType }) => {
  const [newsData, setNewsData] = useState([])
  const [filter, setFilter] = useState('1h')
  const { searchTerm } = useParams()

  const BitcoinApi = `https://api.coinotify.io/news/getUpdatesByCoin?page=1&limit=10&type=${searchTerm}`
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BitcoinApi)
        if (!response.ok) {
          throw new Error('Failed to fetch data')
        }
        const data = await response.json()
        setNewsData(data.data)
        // console.log(data.data)
        //         console.log(newsData)
      } catch (error) {
        console.error(error.message)
      }
    }

    fetchData()
  }, [])

  // console.log(newsData)

  const [visibleCount, setVisibleCount] = useState(9)

  const calculateTimeAgo = createdAt => {
    const currentTime = new Date()
    const createdAtTime = new Date(createdAt)
    const timeDifferenceInMs = currentTime - createdAtTime
    const timeDifferenceInSeconds = Math.floor(timeDifferenceInMs / 1000)
    const timeDifferenceInMinutes = Math.floor(timeDifferenceInSeconds / 60)
    const timeDifferenceInHours = Math.floor(timeDifferenceInMinutes / 60)

    if (timeDifferenceInMinutes < 1) {
      return 'Just now'
    } else if (timeDifferenceInMinutes < 60) {
      return `${timeDifferenceInMinutes} ${
        timeDifferenceInMinutes === 1 ? 'minute' : 'minutes'
      } ago`
    } else if (timeDifferenceInHours < 24) {
      return `${timeDifferenceInHours} ${
        timeDifferenceInHours === 1 ? 'hour' : 'hours'
      } ago`
    } else {
      return createdAtTime.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      })
    }
  }

  //   const filterNewsData = (data, filter) => {
  //     const now = new Date()
  //     switch (filter) {
  //       case '1h':
  //         return data.filter(item => now - new Date(item.createdAt) <= 3600000) // 1 hour in ms
  //       case '24h':
  //         return data.filter(item => now - new Date(item.createdAt) <= 86400000) // 24 hours in ms
  //       case '7d':
  //         return data.filter(item => now - new Date(item.createdAt) <= 604800000) // 7 days in ms
  //       default:
  //         return data
  //     }
  //   }

  //   const filteredNewsData = filterNewsData(newsData, filter)

  return (
    <div>
      {/* <div className='bg-blue_gray-900_01 flex flex-col items-center justify-center sm:px-5 px-6 py-3 rounded-tl rounded-tr w-[400px] md:w-full'>
        <Text
          className='text-base text-white-A700 w-auto'
          size='txtRalewayMedium16'
        >
          Trending news
        </Text>
      </div> */}
      <div>
        <div className='tabs mt-20'></div>
        {newsData.slice(0, visibleCount).map((item, index) => (
          <article
            className='border-b-[1px] border-[#fff] bg-blue_gray-900'
            key={index}
            style={{ backgroundColor: 'hite' }}
          >
            <div className='text flex p-5 py-10'>
              <div>
                <p className='p text-[16px]' style={{ color: 'white' }}>
                  {item.articleTitle}
                </p>
                <div className='flex justify-between items-center mt-4'>
                  <a
                    className='a text-[#fff] font-semibold'
                    href={item.articleSource}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {item.articleSource.replace('https://', '')}
                  </a>
                  <p className='time text-[#fff]'>
                    {calculateTimeAgo(item.articleTimestamp)}
                  </p>
                </div>
              </div>
            </div>
          </article>
        ))}
        {/* {filteredNewsData.length > 3 && (
          <button onClick={showMoreArticles}>
            {visibleCount === 3 ? 'Show More' : 'Show Less'}
          </button>
        )} */}
      </div>
    </div>
  )
}

export default NewsTND
