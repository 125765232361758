import React, { useEffect, useState } from 'react'
import { Button, Img, List, SelectBox, Text } from 'components'
import MacBookPro14FourMorenewsdark from 'components/MacBookPro14FourMorenewsdark'

const Hometradingcoin = () => {
  const [coins, setCoins] = useState([])
  const [timeFrame, setTimeFrame] = useState('1h')

  const cointrading = 'https://api.coinotify.io/market/trendingCoin'
  // const apiUrl = `https://api.coinotify.io/news/allNews?page=1&limit=10&type=trending`

  useEffect(() => {
    const cointrand = async () => {
      try {
        const response = await fetch(cointrading)
        if (!response.ok) {
          throw new Error('Failed to fetch data')
        }
        const data = await response.json()
        setCoins(data)
        // console.log(data)
        // console.log('kaju', data)
        // console.log(coins)
      } catch (error) {
        console.error(error.message)
      }
    }

    cointrand()
  }, [])
  // console.log(coins)
  const getPercentChange = coin => {
    switch (timeFrame) {
      case '1h':
        return coin.alias_percentChange1h
      case '24h':
        return coin.alias_percentChange24h
      case '7d':
        return coin.alias_percentChange7d
      default:
        return coin.alias_percentChange1h
    }
  }
  return (
    <>
      <div className='flex flex-col items-start justify-start mt-[50px] w-auto sm:w-full'>
        <div className='flex flex-col items-start justify-start mt-[35px] w-auto sm:w-full'>
          <Button
            className='cursor-pointer font-medium leading-[normal] rounded-tl md:w-full rounded-tr text-base text-center w-[400px]'
            color='blue_gray_900_01'
            size='sm'
            variant='fill'
          >
            Trending Coin
          </Button>
          <div className='flex sm:flex-col flex-row font-assistant sm:gap-5 items-center justify-center w-[400px] sm:w-full'>
            <Button
              className='cursor-pointer font-bold leading-[normal] text-[15px] text-center w-full'
              shape='square'
              color={timeFrame === '1h' ? 'amber_600' : 'black_900_1e'}
              size='sm'
              variant='fill'
              onClick={() => setTimeFrame('1h')}
            >
              1Hr
            </Button>
            <Button
              className='cursor-pointer font-bold h-full leading-[normal] text-[15px] text-center w-full'
              shape='square'
              color={timeFrame === '24h' ? 'amber_600' : 'black_900_1e'}
              size='sm'
              variant='fill'
              onClick={() => setTimeFrame('24h')}
            >
              24Hr
            </Button>
            <Button
              className='border-l border-solid border-white-A700_1e cursor-pointer font-bold leading-[normal] text-[15px] text-center w-full'
              shape='square'
              color={timeFrame === '7d' ? 'amber_600' : 'black_900_1e'}
              size='sm'
              variant='fill'
              onClick={() => setTimeFrame('7d')}
            >
              7D
            </Button>
          </div>

          <List
            className='bg-blue_gray-900 flex flex-col font-assistant gap-5 items-start pt-3 rounded-bl-[5px] rounded-br-[5px] shadow-bs w-full'
            orientation='vertical'
          >
            {coins.slice(0, 6).map(coin => (
              <div
                key={coin.alias_id}
                className='border-b border-solid border-white-A700_1e flex flex-1 flex-col items-start justify-start my-0 pb-[5px] w-full'
              >
                <div className='flex flex-row gap-3 items-center justify-start px-4 py-3.5 w-full'>
                  <img
                    className='h-5 md:h-auto object-cover w-5'
                    // src='images/img_logosbitcoin.png' // Replace with the actual image URL if available
                    src={coin.alias_logoUrl} // Replace with the actual image URL if available
                    alt={coin.alias_name}
                  />
                  <Text
                    className='text-base text-white-A700 w-auto'
                    size='txtAssistantRomanSemiBold16'
                  >
                    {coin.alias_symbol.toUpperCase()}
                  </Text>
                  <Text
                    className='flex-1 text-base text-center text-white-A700 w-auto'
                    size='txtAssistantRomanSemiBold16'
                  >
                    {Number(coin.alias_currentPrice).toFixed(1)}
                  </Text>
                  <div className='flex flex-row gap-1 items-center justify-start w-auto'>
                    {parseFloat(getPercentChange(coin)) < 0 ? (
                      <>
                        <img
                          className='h-3 w-3'
                          src='images/img_fedropdown.svg'
                          alt='trend'
                        />
                        <Text
                          className='text-xs w-auto text-[#fff]'
                          size='txtAssistantRomanSemiBold12'
                        >
                          {getPercentChange(coin)}%
                        </Text>
                      </>
                    ) : (
                      <>
                        <Img
                          className='h-3 w-3'
                          src='images/img_fedropup.svg'
                          alt='fedropup'
                        />
                        <Text
                          className='text-xs w-auto text-[#fff]'
                          size='txtAssistantRomanSemiBold12'
                        >
                          {getPercentChange(coin)}%
                        </Text>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </List>
        </div>
      </div>
    </>
  )
}

export default Hometradingcoin
