import React, { useEffect, useState } from 'react'
import { Button, Img, Text } from 'components'

import Poll from 'components/Poll'

import './head.css'

import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Sidebar from './Sidebar'

import img1 from './logo.png'

const Mobile = ({ onTypeChange }) => {
  const [pollVisible, setPollVisible] = useState(false)
  const navigate = useNavigate()

  const handleHomeClick = () => {
    navigate('/')
    setactive(false)
  }

  const handlePortfolioClick = () => {
    navigate('/portfolio')
  }

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [dropdownOpen2, setDropdownOpen2] = useState(false)
  const [dropdownOpen3, setDropdownOpen3] = useState(false)
  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [selectedType, setSelectedType] = useState('allCoin')

  const handleTypeChange = type => {
    setSelectedType(type)
    onTypeChange(type)
    setDropdownOpen(false)
  }
  // ///////////////////////////////////////////////////////////////

  const [coindata, setCoinData] = useState([])

  const apiUrl =
    'https://api.coinotify.io/market/coinMarket?page=1&limit=5&type=cryptoAsset'
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl)
        if (!response.ok) {
          throw new Error('Failed to fetch data')
        }
        const data = await response.json()
        setCoinData(data.data)
      } catch (error) {
        console.error(error.message)
      }
    }

    // fetchData()
  }, [selectedType])

  // /////////////////////////////////////////////////////////////////////////////
  const [isSearchActive, setIsSearchActive] = useState(false)
  const handleSearchClick = () => {
    setIsSearchActive(true)
  }
  const handleBlur = () => {
    setIsSearchActive(false)
  }

  // //////////////////////////////////////////////////////////////
  const [timeFrame, setTimeFrame] = useState('1h')
  const getPercentChange = coin => {
    switch (timeFrame) {
      case '1h':
        return coin.percentChange1h
      case '24h':
        return coin.percentChange24h
      case '7d':
        return coin.percentChange7d
      default:
        return coin.alias_percentChange1h
    }
  }
  // ///////////////////////////////////////////////////////////////////////////////
  const [searchTerm, setSearchTerm] = useState('')

  const handleInputChange = event => {
    setSearchTerm(event.target.value)
  }

  const handleKeyPress = async event => {
    if (event.key === 'Enter') {
      try {
        const response = await fetch(
          `https://api.coinotify.io/news/getUpdatesByCoin?page=1&limit=10&type=${searchTerm}`
        )
        // console.log(response.data)
        navigate(`/news/${searchTerm}`)
        window.location.reload()
      } catch (error) {
        console.error('Error fetching search results:', error)
      }
    }
  }

  //   /////////////////////////////
  let [active, setactive] = useState(false)
  const hamburger = () => {
    setactive(!active)
  }
  return (
    <>
      <div className='sticky top-0 z-[999999999999999999999999999] h-[100px]'>
        <header className='bg-gray-900 flex flex-col items-center justify-center  w-full '>
          <div className='flex flex-col gap-[px] items-center justify-center mb-1.5 md:ml-[0] w-full p'>
            <div className='flex  flex-row md:gap-10 items-center justify-between w-full p-3'>
              <div className='flex flex-col items-center justify-center md:mt-0 mt-2.5 w-auto'>
                <Button
                  className='bg-clip-text bg-gradient1  text-3xl sm:text-[26px] md:text-[28px] text-transparent w-auto'
                  onClick={handleHomeClick}
                >
                  <Img
                    onClick={handleHomeClick}
                    className='h-[80px] w-[80px]'
                    src={img1}
                    alt='save'
                  />
                </Button>
              </div>

              <div className='flex items-center'>
                <div
                  className={`flex flex-row gap-6 h-10 md:h-auto items-center justify-start px-3 ${
                    isSearchActive ? 'w-[100%]' : 'w-[50px]'
                  }`}
                >
                  {isSearchActive ? (
                    <input
                      type='text'
                      className='flex-1 p-2 text-center text-sm text-[#000] tracking-[-0.28px] bg-[#fff]'
                      placeholder='Search...'
                      value={searchTerm}
                      onChange={handleInputChange}
                      onKeyPress={handleKeyPress}
                      onBlur={handleBlur}
                      autoFocus
                    />
                  ) : (
                    <Img
                      className='h-4 w-4 cursor-pointer'
                      src='images/img_rewind.svg'
                      alt='rewind'
                      onClick={handleSearchClick}
                    />
                  )}
                </div>
                <button onClick={hamburger}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='65'
                    height='42'
                    viewBox='0 0 65 42'
                    fill='#fff'
                  >
                    <line
                      x1='13.4353'
                      y1='12.1875'
                      x2='51.5647'
                      y2='12.1875'
                      stroke='white'
                    />
                    <line
                      x1='13.4353'
                      y1='20.3594'
                      x2='51.5647'
                      y2='20.3594'
                      stroke='white'
                    />
                    <line
                      x1='13.4353'
                      y1='28.813'
                      x2='51.5647'
                      y2='28.813'
                      stroke='white'
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div
              className={`flex sm:flex-1 sm:flex-col flex-row font-raleway gap-8 h-10 md:h-auto items-start justify-start mb-[7px] w-auto sm:w-full lists ${
                active ? 'active ' : ''
              }`}
            >
              <Sidebar onclose={hamburger} onclick={hamburger} />
            </div>

            <div
              className='flex  flex-row font-raleway md:gap-5 items-center justify-between
           w-full'
            >
              <div className='flex flex-wrap flex-row font-raleway md:gap-0 items-center justify-  w-full'>
                <div className='relative wfull'>
                  <button
                    onClick={() => {
                      setDropdownOpen3(false)
                      setDropdownOpen2(false)
                      // setDropdownOpen(false)
                      setDropdownOpen(!dropdownOpen)
                    }}
                    className='text-[#fff] px-4 py-2 flex items-center font-semibold'
                  >
                    All Coins
                    <svg
                      className={`w-4 h-4 ml-1 transition-transform duration-200 ${
                        dropdownOpen ? 'transform rotate-180' : ''
                      }`}
                      fill='none'
                      stroke='currentColor'
                      viewBox='0 0 24 24'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M19 9l-7 7-7-7'
                      ></path>
                    </svg>
                  </button>
                </div>

                <div className='relative'>
                  <button
                    onClick={() => {
                      setDropdownOpen3(!dropdownOpen3)
                      setDropdownOpen2(false)
                      setDropdownOpen(false)
                    }}
                    className='text-[#fff] px-4 py-2 flex items-center  font-semibold'
                  >
                    Market Cap
                  </button>
                  {dropdownOpen3 && (
                    <div className='absolute -right-[100px] mt-4 w-[300px] bg-[#fff] rounded-md shadow-lg py-0 roundedsm z-[99999999]'>
                      <div className='flex w-full flex-col'>
                        <div className='flex w-full flecol'>
                          <button
                            onClick={() => setTimeFrame('1h')}
                            // className=''
                            className={`text-[#fff] w-full px-4 py-2  ${
                              timeFrame === '1h' ? 'bg-[#FAB605]' : 'bg-[#000]'
                            }`}
                          >
                            1Hr
                          </button>
                          <button
                            onClick={() => setTimeFrame('24h')}
                            className={`text-[#fff] w-full px-4 py-2  ${
                              timeFrame === '24h' ? 'bg-[#FAB605]' : 'bg-[#000]'
                            }`}
                          >
                            24Hr
                          </button>
                        </div>

                        {coindata.map((item, index) => (
                          <article
                            key={index}
                            className='relative bg-cover bg-center h-[50px] w-[300px] rounded-lg overflow-hidden shadow-lg'
                          >
                            <div className='flex  justify-between text-[#000] bg-[#fff] p-3  border-b-2 border-[#000]'>
                              <div className=' leftd flex gap-3 items-center  bg[#fff]'>
                                <Img
                                  className='h-5 md:h-auto object-cover w-5'
                                  src={item.logoUrl}
                                  alt='logosbitcoin'
                                />
                                <Text
                                  className='text-base  w-auto uppercase'
                                  size='txtAssistantRomanSemiBold16'
                                >
                                  {item.symbol}
                                </Text>
                              </div>
                              <div className=' rightd flex flex-col justify-end  bg-[#fff]'>
                                <Text
                                  className='flex-1 text-base text-center  w-auto'
                                  size='txtAssistantRomanSemiBold16'
                                >
                                  {Number(item.currentPrice).toFixed(1)}
                                </Text>
                                <div className='flex flex-row gap-1 items-center justify-end w-auto'>
                                  {parseFloat(getPercentChange(item)) < 0 ? (
                                    <>
                                      <img
                                        className='h-3 w-3'
                                        src='images/img_fedropdown.svg'
                                        alt='trend'
                                      />
                                      <Text
                                        className='text-xs w-auto text-[#000]'
                                        size='txtAssistantRomanSemiBold12'
                                      >
                                        {getPercentChange(item)}%
                                      </Text>
                                    </>
                                  ) : (
                                    <>
                                      <Img
                                        className='h-3 w-3'
                                        src='images/img_fedropup.svg'
                                        alt='fedropup'
                                      />
                                      <Text
                                        className='text-xs w-auto text-[#000]'
                                        size='txtAssistantRomanSemiBold12'
                                      >
                                        {getPercentChange(item)}%
                                      </Text>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </article>
                        ))}

                        <Link
                          to='/marketcap'
                          className='flex flexcol justify-center cursor-pointer text-[#000] bg-[#fff] p-3  border-b-2 border-[#000]'
                          onClick={() => {
                            setDropdownOpen3(false)
                            setDropdownOpen2(false)
                            setDropdownOpen(false)
                          }}
                        >
                          View More
                        </Link>
                      </div>
                    </div>
                  )}
                </div>

                <div className='flex flex-col items-center justify-center md:ml-[0] ml-[23px] px-2.5 py-[7px] wauto'>
                  <Button
                    className='common-pointer bg-transparent cursor-pointer font-semibold h-10 leading-[normal] text-base text-center text-white-A700'
                    onClick={handlePortfolioClick}
                    size='xs'
                  >
                    Portfolio
                  </Button>
                </div>

                <div>
                  <div className='flex flex-col items-center justify-center md:ml-[0] ml-[23px] px-2.5 py-[7px] w-auto'>
                    <button
                      className='common-pointer bg-transparent cursor-pointer font-semibold h-10 leading-[normal] text-base text-center text-white-A700'
                      onClick={() => setPollVisible(true)}
                    >
                      Poll
                    </button>
                  </div>

                  {pollVisible && (
                    <Poll onClose={() => setPollVisible(false)} />
                  )}
                </div>

                {dropdownOpen && (
                  <div className=' -right-[60px] mt-2 w-full bg[#fff] text-[#fff] rounded-md shadowlg py-1 z-[99999999]'>
                    <div className='flex w-full flex-wrap gap-2 justify-center items-center'>
                      <Link
                        to={`/news/${'bitcoin'}`}
                        className='block px-4 py-2 text-sm bg-[#363636] rounded-[4px] '
                        onClick={() => handleTypeChange('bitcoin')}
                      >
                        Bitcoin
                      </Link>
                      <Link
                        to={`/news/${'trending'}`}
                        className='block px-4 py-2 text-sm bg-[#363636] rounded-[4px] '
                        onClick={() => handleTypeChange('trending')}
                      >
                        Trending
                      </Link>
                      <Link
                        to={`/news/${'priceAnalysis'}`}
                        className='block px-4 py-2 text-sm bg-[#363636] rounded-[4px] '
                        onClick={() => handleTypeChange('priceAnalysis')}
                      >
                        Price Analysis
                      </Link>
                      <Link
                        to={`/news/${'gameFi'}`}
                        className='block px-4 py-2 text-sm bg-[#363636] rounded-[4px] '
                        onClick={() => handleTypeChange('gameFi')}
                      >
                        Gamefi
                      </Link>

                      <Link
                        to={`/news/${'nft'}`}
                        className='block px-4 py-2 text-sm bg-[#363636] rounded-[4px] '
                        onClick={() => handleTypeChange('nft')}
                      >
                        NFT
                      </Link>
                      <Link
                        to={`/news/${'press'}`}
                        className='block px-4 py-2 text-sm bg-[#363636] rounded-[4px] '
                        onClick={() => handleTypeChange('press')}
                      >
                        press
                      </Link>
                      <Link
                        to={`/news/${'policy'}`}
                        className='block px-4 py-2 text-sm bg-[#363636] rounded-[4px] '
                        onClick={() => handleTypeChange('policy')}
                      >
                        policy
                      </Link>
                      <Link
                        to={`/news/${'defi'}`}
                        className='block px-4 py-2 text-sm bg-[#363636] rounded-[4px] '
                        onClick={() => handleTypeChange('defi')}
                      >
                        defi
                      </Link>
                    </div>
                  </div>
                )}
              </div>

              <div className=' flex-row gap-5 w-[50%] items-start justify-end md:ml-[0] ml[556px] wauto hidden'>
                <div
                  className={`flex flex-row gap-6 h-10 md:h-auto items-center justify-start px-3 ${
                    isSearchActive ? 'w-full' : 'w-[110px]'
                  }`}
                >
                  {isSearchActive ? (
                    <input
                      type='text'
                      className='flex-1 p-2 text-center text-sm text-[#000] tracking-[-0.28px] bg-[#fff]'
                      placeholder='Search...'
                      value={searchTerm}
                      onChange={handleInputChange}
                      onKeyPress={handleKeyPress}
                      onBlur={handleBlur}
                      autoFocus
                    />
                  ) : (
                    <Text
                      className='flex-1 text-center text-sm text-white-A700 tracking-[-0.28px] w-auto cursor-pointer'
                      size='txtRalewaySemiBold14'
                      onClick={handleSearchClick}
                    >
                      Search
                    </Text>
                  )}
                  <Img
                    className='h-4 w-4 cursor-pointer'
                    src='images/img_rewind.svg'
                    alt='rewind'
                    onClick={handleSearchClick}
                  />
                </div>

                <Img
                  className='h-10 md:h-auto object-cover w-[30px]'
                  src='images/img_appstore.png'
                  alt='appstore'
                />
                <Img
                  className='h-10 w-[30px]'
                  src='images/img_close.svg'
                  alt='close'
                />

                <div className='relative w-[30px]'>
                  <button
                    onClick={() => setDropdownOpen2(!dropdownOpen2)}
                    className='text-[#fff] px-0 py2 flex items-center  font-semibold'
                  >
                    <Img
                      className='h-full w[30px]'
                      src='images/img_send.svg'
                      alt='send'
                    />

                    {/* <svg
                    className={`w-4 h-4 ml-1 transition-transform duration-200 ${
                      dropdownOpen1 ? 'transform rotate-180' : ''
                    }`}
                    fill='none'
                    stroke='currentColor'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M19 9l-7 7-7-7'
                    ></path>
                  </svg> */}
                  </button>
                  {dropdownOpen2 && (
                    <div className='absolute -right-[60px] mt-2 w-20 bg[#fff] rounded-md shadow-lg py-1 z-[99999999]'>
                      {/* <Link
                        to='/'
                        className='block px-4 py-2 text-sm  hover:bg-gray-100'
                      >
                        tele
                      </Link>
                      <Link
                        to='/'
                        className='block px-4 py-2 text-sm  hover:bg-gray-100'
                      >
                        Linkedin
                      </Link>
                      <Link
                        to='/'
                        className='block px-4 py-2 text-sm  hover:bg-gray-100'
                      >
                        facebook
                      </Link>
                      <Link
                        to='/'
                        className='block px-4 py-2 text-sm  hover:bg-gray-100'
                      >
                        x
                      </Link>
                      <Link
                        to='/'
                        className='block px-4 py-2 text-sm  hover:bg-gray-100'
                      >
                        redit
                      </Link>
                      <Link
                        to='/'
                        className='block px-4 py-2 text-sm  hover:bg-gray-100'
                      >
                        insta
                      </Link> */}
                      <Img
                        className='h-[30px] w-[30px] '
                        src='images/img_save.svg'
                        alt='save'
                      />
                      <Img
                        className='h-[30px] w-[30px] my-3'
                        src='images/img_mdilinkedin.svg'
                        alt='mdilinkedin'
                      />
                      <Img
                        className='h-[30px] w-[30px]'
                        src='images/img_icbaselinefacebook.svg'
                        alt='icbaselinefaceb'
                      />
                      <Img
                        className='h-[30px] w-[30px]  my-3'
                        src='images/img_close_white_a700.svg'
                        alt='close_One'
                      />
                      <Img
                        className='h-[30px] w-[30px]'
                        src='images/img_riinstagramfill.svg'
                        alt='riinstagramfill'
                      />
                      <Img
                        className='h-[30px] w-[30px] my-3'
                        src='images/img_icbaselinereddit.svg'
                        alt='icbaselinereddi'
                      />
                      <Img
                        className='h-[30px] w-[30px]'
                        src='images/img_icbaselinediscord.svg'
                        alt='icbaselinedisco'
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  )
}

export default Mobile
