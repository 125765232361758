import React from 'react'

const NotFound = () => {
  return (
    <div>
      <h1 className='text-[100px] bg-teal-400 h-screen'>
        Opps! We ran out of code
      </h1>
    </div>
  )
}

export default NotFound
