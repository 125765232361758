import React from 'react'
import { Button, Img, Text } from 'components'
import { Link, useNavigate } from 'react-router-dom'

const Footer = props => {
  const navigate = useNavigate()

  const handletermsClick = () => {
    navigate('/terms')
    window.scrollTo(0, 0)
  }
  const handlepolicyClick = () => {
    navigate('/policy')
    window.scrollTo(0, 0)
  }

  const Effect = () => {
    window.scrollTo(0, 0)
  }

  return (
    <>
      <footer className={props.className}>
        <div className='flex flex-col items-center justify-center mb-[34px] mt-[51px] mx-auto w-[69%] md:w-[90%]'>
          <ul className='flex flex-col items-center justify-center w-full common-column-list'>
            <li>
              <div className='flex md:flex-col flex-row md:gap-10 items-start justify-between'>
                <div className='flex sm:flex-wrap flex-row gap-[39px] items-start justify-start w-auto sm:w-full'>
                  <div className='flex flex-col gap-1.5 items-start justify-start w-auto'>
                    <Link
                      to='/aboutus'
                      className='text-base text-white-A700 w-auto'
                      size='txtRalewaySemiBold16'
                      onClick={Effect}
                    >
                      About Us
                    </Link>
                    <Link
                      to='/advertise'
                      className='text-base text-white-A700 w-auto'
                      size='txtAssistantRomanSemiBold16'
                      onClick={Effect}
                    >
                      Advertise
                    </Link>
                  </div>
                  <div className='flex flex-col gap-1.5 items-start justify-start w-auto'>
                    {/* <Button
                      className="text-base text-white-A700 w-auto cursor-pointer"
                      size="txtRalewaySemiBold16"
                      onClick={handleNavigateToTerms}
                    >
                      Terms of Use
                    </Button> */}
                    <Button
                      className='common-pointer bg-transparent cursor-pointer font-semibold h-6 leading-[normal] text-base text-center text-white-A700'
                      onClick={handletermsClick}
                      size='xs'
                      // onClick={Effect}
                    >
                      Terms of Use
                    </Button>
                    <Link
                      to='/career'
                      className='text-base text-white-A700 w-auto'
                      size='txtAssistantRomanSemiBold16'
                      onClick={Effect}
                    >
                      Career
                    </Link>
                  </div>
                  <ul className='flex flex-col gap-1.5 items-start justify-start w-auto md:w-full common-column-list'>
                    <li>
                      <Text
                        className='text-base text-white-A700'
                        size='txtRalewaySemiBold16'
                        onClick={Effect}
                      >
                        Develop
                      </Text>
                    </li>
                    <li>
                      <Text
                        className='text-[15px] text-white-A700'
                        size='txtAssistantRomanSemiBold15WhiteA700'
                      >
                        API
                      </Text>
                    </li>
                    <li>
                      <Text
                        className='text-[15px] text-white-A700'
                        size='txtAssistantRomanSemiBold15WhiteA700'
                      >
                        Widgets
                      </Text>
                    </li>
                    <li>
                      <Text
                        className='text-base text-white-A700'
                        size='txtAssistantRomanSemiBold16'
                      >
                        Bots
                      </Text>
                    </li>
                  </ul>
                  <div className='flex flex-col gap-1.5 items-start justify-start w-auto'>
                    {/* <Text
                      className="text-base text-white-A700 w-auto"
                      size="txtRalewaySemiBold16"
                    >
                      Policies
                    </Text> */}
                    <ul className='flex flex-col gap-[7px] items-start justify-start w-full common-column-list'>
                      <li>
                        <Button
                          className='common-pointer bg-transparent cursor-pointer font-semibold h-10 leading-[normal] text-base text-center text-white-A700'
                          onClick={handlepolicyClick}
                          size='xs'
                        >
                          Privacy Policy
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='flex flex-col gap-3 items-start justify-start w-auto'>
                  <Text
                    className='text-base text-gray-50 w-auto'
                    size='txtRalewaySemiBold16Gray50'
                  >
                    Follow Us
                  </Text>
                  <div className='flex flex-row gap-3.5 items-start justify-center w-auto'>
                    <Img
                      className='h-[30px] w-[30px]'
                      src='images/img_save.svg'
                      alt='save'
                    />
                    <Img
                      className='h-[30px] w-[30px]'
                      src='images/img_mdilinkedin.svg'
                      alt='mdilinkedin'
                    />
                    <Img
                      className='h-[30px] w-[30px]'
                      src='images/img_icbaselinefacebook.svg'
                      alt='icbaselinefaceb'
                    />
                    <Img
                      className='h-[30px] w-[30px]'
                      src='images/img_close_white_a700.svg'
                      alt='close_One'
                    />
                    <Img
                      className='h-[30px] w-[30px]'
                      src='images/img_riinstagramfill.svg'
                      alt='riinstagramfill'
                    />
                    <Img
                      className='h-[30px] w-[30px]'
                      src='images/img_icbaselinereddit.svg'
                      alt='icbaselinereddi'
                    />
                    <Img
                      className='h-[30px] w-[30px]'
                      src='images/img_icbaselinediscord.svg'
                      alt='icbaselinedisco'
                    />
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className='flex md:flex-col flex-row md:gap-10 items-center justify-between mt-11'>
                <div className='border border-solid border-white-A700 flex flex-row gap-3 items-center justify-start p-2.5 rounded w-auto'>
                  <input
                    type='email'
                    className='text-base text-white-A700 w-auto bg-transparent border-none'
                    size='txtRalewayMedium16'
                    placeholder=' Subscribe to our newsletter'
                  />

                  <Button
                    className='!text-white-A700 cursor-pointer font-raleway font-semibold h-10 text-center text-sm tracking-[-0.28px] w-32'
                    shape='round'
                    color='amber_600'
                    size='sm'
                    variant='fill'
                  >
                    Subscribe
                  </Button>
                </div>
                {/* <div className='border border-solid border-white-A700 flex flex-row gap-3 items-center justify-start p-2.5 rounded w-auto'>
                  <Text
                    className='text-base text-white-A700 w-auto'
                    size='txtRalewayMedium16'
                  >
                    Want to advertise ?
                  </Text>
                  <Button
                    className='!text-white-A700 cursor-pointer font-raleway font-semibold h-10 text-center text-sm tracking-[-0.28px] w-32'
                    shape='round'
                    color='amber_600'
                    size='sm'
                    variant='fill'
                  >
                    Mail Us
                  </Button>
                </div> */}
              </div>
            </li>
            <li>
              <Text
                className='mt-[30px] text-base text-white-A700'
                size='txtAssistantRomanSemiBold16'
              >
                © 2023 CryptoNews. All rights reserved.
              </Text>
            </li>
          </ul>
        </div>
      </footer>
    </>
  )
}

Footer.defaultProps = {}

export default Footer
