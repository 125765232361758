import React from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import LazyLoad from 'react-lazyload'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Autoplay, Pagination, Navigation } from 'swiper/modules'
import './Sider.css'
import { useNavigate } from 'react-router-dom'

const Slideshow = ({ newsDataa, selectedType }) => {
  const navigate = useNavigate()

  // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // utils.js

  const slugifyTitle = title => {
    const truncatedTitle = title.split(' ').slice(0, 6).join(' ') // Get first 6 words
    return truncatedTitle
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^\s+|\s+$)+/g, '')
      .replace(/\s+/g, '-')
  }

  const transformType = type => {
    return type === 'allCoin' ? 'crypto' : type
  }

  const handleNavigation = (id, title) => {
    const path = `/news/${transformType(selectedType)}/${id}/${slugifyTitle(
      title
    )}`
    navigate(path)
  }
  return (
    <>
      <div className='swieo md:'>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 5500,
            disableOnInteraction: false
          }}
          pagination={{
            clickable: true
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className='mySwiper relative bg-black-900_7f'
        >
          {newsDataa.map((item, index) => (
            <SwiperSlide key={index}>
              <div
                onClick={() => handleNavigation(item.id, item.articleTitle)}
                key={index}
                className='relative bg-cover bg-center !font-lora !font-normal group h-full w[395px] rounded-lg overflow-hidden shadow-lg cursor-pointer'
              >
                <div className='h-[500px]'>
                  <LazyLoad height={500} offset={100}>
                    <img
                      src={item.thumbNail}
                      alt='Slide-img'
                      className='slideshow-image h[150px]'
                    />
                  </LazyLoad>
                </div>

                <h2 className='slide-title mt-[90px] text-[20px] md:text-[20px] md:h-[200px] font-semibold text-[#fff] w-full bgblack-900_7f mt0 hfull p-4'>
                  {item.articleTitle}
                </h2>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  )
}

export default Slideshow
