import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import ChooseCoin from 'components/ChooseCoin/chooseCoin'
import { Text } from 'components'
import { Input } from 'components/Input'
import { Heading } from 'components/Heading'
import Footer from 'components/Footer'
import MacBookPro14ThirteenColumn from 'components/MacBookPro14ThirteenColumn'
import MacBookPro14ThirteenColumnOne from 'components/MacBookPro14ThirteenColumnOne'
import MacBookPro14TenStackgroupseven from 'components/MacBookPro14TenStackgroupseven'
import TopNav from 'components/topnav'

import imgf from './assets/29.png'
import { Helmet } from 'react-helmet'
const shapes = { round: 'rounded' }
const variants = {
  fill: {
    yellow_900: 'bg-yellow-900 text-white-A700',
    amber_600: 'bg-amber-600'
  }
}
const sizes = { xs: 'py-2.5', sm: 'p-[13px]' }
const Img = ({
  className,
  src = 'defaultNoData.png',
  alt = 'testImg',
  ...restProps
}) => {
  return (
    <img
      className={className}
      src={src}
      alt={alt}
      {...restProps}
      loading={'lazy'}
    />
  )
}
const Button = ({
  children,
  className = '',
  leftIcon,
  rightIcon,
  shape = '',
  size = '',
  variant = '',
  color = '',
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${(shape && shapes[shape]) || ''} ${
        (size && sizes[size]) || ''
      } ${(variant && variants[variant]?.[color]) || ''}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  )
}
const Line = ({ className, ...restProps }) => {
  return <div className={className} {...restProps} />
}
const AboutUs = () => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Coinotify || About</title>
        <link rel='canonical' href='https://coinotify.io/aboutus' />
        <meta
          name='description'
          content='Coinotify is a comprehensive platform for news and insights on
                cryptocurrencies and blockchain. We aggregate updates from a
                variety of websites and sources to provide our users with the
                most relevant and up-to-date information.'
        />
      </Helmet>
      <div
        className={`bg-gray-900 flex flex-col font-inter items-center justify-start mx-auto w-full `}
      >
        <div className='bg-gray-900 p-8 md:mt-20 mt-10'>
          <div className='max-w-5xl mx-auto bg-white shadow-md rounded-lg overflow-hidden'>
            <img
              src={imgf} // Replace with your image URL
              alt='Crypto Overview'
              className='w-full h-[33rem] object-cover'
            />
            <div className='p-6'>
              <h1 className='text-2xl font-bold text-gray-800'>About Us</h1>
              <p className='mt-4 text-gray-600'>
                Coinotify is a comprehensive platform for news and insights on
                cryptocurrencies and blockchain. We aggregate updates from a
                variety of websites and sources to provide our users with the
                most relevant and up-to-date information.
              </p>
              <p className='mt-2 text-gray-600'>
                Our coverage encompasses Bitcoin, altcoins, DeFi, GameFi, NFTs,
                fundamental and technical analysis, airdrops, portfolio
                management, and much more.
              </p>
              <p className='mt-2 text-gray-600'>
                Our objective is to offer a comprehensive solution for all
                authentic market updates and trends, complemented by fundamental
                and technical analysis related to cryptocurrencies and
                blockchain. We aggregate information from both primary and
                secondary sources to ensure our users have access to the most
                reliable and insightful data available.
              </p>
            </div>
          </div>
        </div>
        {/* <Footer className='bg-blue_gray-900_01 flex items-center justify-center mt-[300px] md:px-5 w-full' /> */}
      </div>
    </>
  )
}

export default AboutUs
