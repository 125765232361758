import ProfileUpdateForm from 'componentsbyaman/profileupdate/ProfileUpdateForm'
import React, { useState } from 'react'

const Profile = () => {
  const [activeTab, setActiveTab] = useState(0)

  const handleTabClick = index => {
    setActiveTab(index)
  }
  const tabs = [
    {
      label: 'Profile',
      content: <ProfileUpdateForm />,
      id: 'local'
    }
    // { label: 'Account setting ', content: 'road', id: 'roas' },
    // { label: 'Notifications', content: 'faw' }
  ]
  return (
    <>
      <div className='tabbed-content flex justify-start items-start gap-10 pt-32 flexwrap  px-0 mb-20 '>
        <div className='tabs-container w-[25%] px-10 '>
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`tab bg-[#fff] shadow-lg text-[#000] w-full m-1 ${
                index === activeTab ? 'active' : ''
              }`}
              onClick={() => handleTabClick(index)}
            >
              {tab.label}
            </div>
          ))}
        </div>
        <div className='tab-content w[70%] -ml52 max-sm:ml-0 max-sm:w-full w-full'>
          {tabs[activeTab].content}
        </div>
      </div>
    </>
  )
}

export default Profile
