import React from 'react'
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts'

const CoinPieChart = ({ coinData }) => {
  // Calculate total quantity
  const totalQuantity = coinData.reduce(
    (acc, coin) => acc + Math.abs(coin.quantity),
    0
  )

  // Prepare data for the pie chart
  const chartData = coinData.map(coin => ({
    name: coin.name,
    value: Math.abs(coin.quantity) // Use absolute value to avoid negative quantities
  }))

  // Define colors for the pie chart
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF']

  return (
    <PieChart width={400} height={400}>
      <Pie
        data={chartData}
        cx={200}
        cy={200}
        labelLine={false}
        outerRadius={150}
        fill='#8884d8'
        dataKey='value'
      >
        {chartData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
      <Legend />
    </PieChart>
  )
}

export default CoinPieChart
