import React from "react";

const sizes = {
  "3xl": "text-base font-semibold",
  "2xl": "text-[15px] font-semibold",
  "5xl": "text-xl font-semibold",
  "4xl": "text-[17px] font-semibold",
  "7xl": "text-[27px] font-semibold md:text-[25px] sm:text-[23px]",
  "6xl": "text-[22px] font-bold",
  "9xl": "text-3xl font-extrabold md:text-[28px] sm:text-[26px]",
  "8xl": "text-[28px] font-semibold md:text-[26px] sm:text-2xl",
  xl: "text-sm font-semibold",
  s: "text-[10px] font-semibold",
  md: "text-xs font-semibold",
  "12xl": "text-[44px] font-semibold md:text-[40px] sm:text-[34px]",
  xs: "text-[9px] font-semibold",
  lg: "text-[13px] font-semibold",
  "11xl": "text-[42px] font-semibold md:text-[38px] sm:text-[32px]",
  "10xl": "text-4xl font-semibold md:text-[34px] sm:text-[32px]",
};

const Heading = ({
  children,
  className = "",
  size = "3xl",
  as,
  ...restProps
}) => {
  const Component = as || "h6";

  return (
    <Component
      className={`text-white-A700 font-assistant ${className} ${sizes[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Heading };
