import React from 'react'

import { CircularProgressbar } from 'react-circular-progressbar'

import { Text } from 'components'

import 'react-circular-progressbar/dist/styles.css'

const MacBookPro14TenStackgroupseven = props => {
  return (
    <>
      <div className='z-[9999999999999999999999] bg-lime-600'>
        <div className='!w-[314px] absolute h-[313px] inset-[0] justify-center m-auto overflow-hidden z-[9999999999999999999999] bg-lime-600'>
          <CircularProgressbar
            className='!w[314px] absolute h-[313px] inset-[0] justify-center m-auto overflow-hidden z-[9999999999999999999999]'
            value={16}
            counterClockwise
            strokeWidth={14}
            styles={{
              trail: { strokeWidth: 14, stroke: '#c78c8c' },
              path: {
                strokeLinecap: 'square',
                height: '100%',
                transformOrigin: 'center',
                transform: 'rotate(224deg)',
                stroke: '#c78c8c'
              }
            }}
          ></CircularProgressbar>
        </div>
        {/* <div className='!w-[313px] absolute h-[313px] inset-[0] justify-center m-auto overflow-hidden'>
          <CircularProgressbar
            className='!w-[313px] absolute h-[313px] inset-[0] justify-center m-auto overflow-hidden'
            value={11}
            counterClockwise
            strokeWidth={14}
            styles={{
              trail: { strokeWidth: 14, stroke: '#c78c8c' },
              path: {
                strokeLinecap: 'square',
                height: '100%',
                transformOrigin: 'center',
                transform: 'rotate(165deg)',
                stroke: '#c78c8c'
              }
            }}
          ></CircularProgressbar>
        </div>
        <div className='!w-[313px] absolute h-[313px] inset-[0] justify-center m-auto overflow-hidden'>
          <CircularProgressbar
            className='!w-[313px] absolute h-[313px] inset-[0] justify-center m-auto overflow-hidden'
            value={5}
            counterClockwise
            strokeWidth={14}
            styles={{
              trail: { strokeWidth: 14, stroke: '#c78c8c' },
              path: {
                strokeLinecap: 'square',
                height: '100%',
                transformOrigin: 'center',
                transform: 'rotate(120deg)',
                stroke: '#c78c8c'
              }
            }}
          ></CircularProgressbar>
        </div>
        <div className='!w-[313px] absolute h-[313px] inset-[0] justify-center m-auto overflow-visible'>
          <CircularProgressbar
            className='!w-[313px] absolute h-[313px] inset-[0] justify-center m-auto overflow-visible'
            value={2}
            counterClockwise
            strokeWidth={14}
            styles={{
              trail: { strokeWidth: 14, stroke: '#c78c8c' },
              path: {
                strokeLinecap: 'square',
                height: '100%',
                transformOrigin: 'center',
                transform: 'rotate(99deg)',
                stroke: '#c78c8c'
              }
            }}
          ></CircularProgressbar>
        </div>
        <div className='!w-[313px] absolute h-[313px] inset-[0] justify-center m-auto overflow-visible'>
          <CircularProgressbar
            className='!w-[313px] absolute h-[313px] inset-[0] justify-center m-auto overflow-visible'
            value={30}
            strokeWidth={14}
            styles={{
              trail: { strokeWidth: 14, stroke: '#c78c8c' },
              path: {
                strokeLinecap: 'square',
                height: '100%',
                transformOrigin: 'center',
                transform: 'rotate(225deg)',
                stroke: '#c78c8c'
              }
            }}
          ></CircularProgressbar>
        </div> */}
        <div className='absolute bottom-[8%] flex flex-col gap-1.5 inset-x-[0] justify-start mx-auto w-[91%]'>
          <div className='flex flex-row items-start justify-end w-full'>
            <Text
              className='mt-[98px] text-[10px] text-center text-white-A700'
              size='txtAssistantRomanBold10'
            >
              {props?.thirty}
            </Text>
            <div className='flex flex-col md:gap-10 gap-[200px] items-center justify-start ml-48'>
              <Text
                className='text-[10px] text-center text-white-A700'
                size='txtAssistantRomanBold10'
              >
                {props?.thirtyOne}
              </Text>
              <Text
                className='text-[10px] text-center text-white-A700'
                size='txtAssistantRomanBold10'
              >
                {props?.thirteen}
              </Text>
            </div>
            <div className='flex flex-col gap-[15px] justify-start ml-[29px] mt-[120px]'>
              <Text
                className='h-3.5 ml-2 md:ml-[0] text-[10px] text-center text-white-A700'
                size='txtAssistantRomanBold10'
              >
                {props?.four}
              </Text>
              <Text
                className='h-3.5 mr-2 text-[10px] text-center text-white-A700'
                size='txtAssistantRomanBold10'
              >
                {props?.eight}
              </Text>
            </div>
          </div>
          <Text
            className='md:ml-[0] ml-[95px] text-[10px] text-center text-white-A700'
            size='txtAssistantRomanBold10'
          >
            {props?.fifteen}
          </Text>
        </div>
      </div>
    </>
  )
}

MacBookPro14TenStackgroupseven.defaultProps = {
  thirty: '30%',
  thirtyOne: '30%',
  thirteen: '13%',
  four: '4%',
  eight: '8%',
  fifteen: '15%'
}

export default MacBookPro14TenStackgroupseven
