import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Img, Text } from 'components'

import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { AuthContext } from 'Context/AuthContext'
import img1 from './logo.png'
import img2 from './assets/search.svg'
import img3 from './assets/img_appstore.png'
import img4 from './assets/img_close.svg'
import img5 from './assets/img_send.svg'
import img6 from './assets/img_brightness.svg'
import ChatComponent from 'components/Poll/ChatComponent'

import img12 from './assets/img_uillanguage.svg'
import img13 from './assets/img_arrowdown_white_a700.svg'

const Desktop = ({ onTypeChange, onLangChange }) => {
  const [pollVisible, setPollVisible] = useState(false)
  const navigate = useNavigate()
  const handleLearnClick = () => {
    // Redirect to the Learn page
    navigate('/learn')
    window.scrollTo(0, 0)
  }
  const handleHomeClick = () => {
    // Redirect to the Learn page
    navigate('/')
    window.scrollTo(0, 0)
  }
  const handleContactClick = () => {
    // Redirect to the Learn page
    navigate('/contact')
    window.scrollTo(0, 0)
  }

  const handlePortfolioClick = () => {
    // Redirect to the Learn page
    navigate('/portfolio')
    window.scrollTo(0, 0)
  }

  const handleAboutClick = () => {
    navigate('/aboutus')
    window.scrollTo(0, 0)
  }
  const Effect = () => {
    window.scrollTo(0, 0)
  }

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [dropdownOpen2, setDropdownOpen2] = useState(false)
  const [dropdownOpen3, setDropdownOpen3] = useState(false)

  const dropdownRef = useRef(null)
  const dropdownRef1 = useRef(null)

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false)
    }
    if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
      setDropdownOpen3(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [selectedType, setSelectedType] = useState('crypto')

  const handleTypeChange = type => {
    setSelectedType(type)
    onTypeChange(type)
    setDropdownOpen(false)
  }
  const handleLangChange = lang => {
    onLangChange(lang)
    setDropdownOpen(false)
  }
  // ///////////////////////////////////////////////////////////////

  const [coindata, setCoinData] = useState([])

  const apiUrl =
    'https://api.coinotify.io/market/coinMarket?page=1&limit=10&type=cryptoAsset'
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl)
        if (!response.ok) {
          throw new Error('Failed to fetch data')
        }
        const data = await response.json()
        setCoinData(data.data)
        // console.log(data.data)
      } catch (error) {
        console.error(error.message)
      }
    }

    fetchData()
  }, [selectedType])

  // /////////////////////////////////////////////////////////////////////////////
  const [isSearchActive, setIsSearchActive] = useState(false)
  const handleSearchClick = () => {
    setIsSearchActive(true)
  }
  const handleBlur = () => {
    setIsSearchActive(false)
  }

  // //////////////////////////////////////////////////////////////
  const [timeFrame, setTimeFrame] = useState('1h')
  const getPercentChange = coin => {
    switch (timeFrame) {
      case '1h':
        return coin.percentChange1h
      case '24h':
        return coin.percentChange24h
      case '7d':
        return coin.percentChange7d
      default:
        return coin.alias_percentChange1h
    }
  }
  // ///////////////////////////////////////////////////////////////////////////////
  const [searchTerm, setSearchTerm] = useState('')

  const handleInputChange = event => {
    setSearchTerm(event.target.value)
  }

  const handleKeyPress = async event => {
    if (event.key === 'Enter') {
      // Perform the search
      try {
        const response = await fetch(
          `https://api.coinotify.io/news/getUpdatesByCoin?page=1&limit=10&type=${searchTerm}`
        )
        // console.log(response.data) // Handle the response data
        navigate(`/search/news/${searchTerm}`)
        window.location.reload() // Handle the response data
        // Navigate to the search results page
      } catch (error) {
        console.error('Error fetching search results:', error)
      }
    }
  }
  //   ///////////////////////////////////////////////////////////////////////////////////////////////////
  const { logout } = useContext(AuthContext)
  const user = localStorage.getItem('token')
  // console.log(user)
  /////////////////////////////////////////

  const chatRef = useRef(null)

  const handleClickOutsidee = event => {
    if (chatRef.current && !chatRef.current.contains(event.target)) {
      setPollVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsidee)
    return () => {
      document.removeEventListener('mousedown', handleClickOutsidee)
    }
  }, [])
  return (
    <>
      <div className='sticky top-0 z-[999999999999999999999999999] h-[100px] md:hidden'>
        <header className='bg-gray-900 flex flex-col items-center justify-center md:px-5 w-full '>
          <div className='flex flex-col gap-[0px] items-center justify-center mb-1.5 md:ml-[0] ml-[59px] mr-16 mt-[0px] w-[92%]'>
            <div className='flex md:flex-col flex-row md:gap-10 items-center justify-between w-full'>
              <div className='flex flex-col items-center justify-center md:mt-0 mt-2.5 w-auto'>
                <Button
                  className='bg-clip-text bg-gradient1  text-3xl sm:text-[26px] md:text-[28px] text-transparent w-auto'
                  onClick={handleHomeClick}
                >
                  {/* <span className='text-[#FBA512] text-[30px] font-bold'>
                    Crypto News
                  </span> */}
                  <Img
                    onClick={handleHomeClick}
                    className='h-[80px] w-[80px]'
                    src={img1}
                    alt='save'
                  />
                </Button>
              </div>
              <div className='flex sm:flex-1 sm:flex-col flex-row font-raleway gap-8 h-10 md:h-auto items-start justify-start mb-[7px] w-auto sm:w-full'>
                <Button
                  className='common-pointer bg-transparent cursor-pointer font-semibold h-10 leading-[normal] text-base text-center text-white-A700'
                  onClick={handleContactClick}
                  size='xs'
                >
                  Contact Us
                </Button>
                <div className='flex flex-col h-10 md:h-auto items-start justify-start'>
                  <Button
                    className='common-pointer bg-transparent cursor-pointer font-semibold h-10 leading-[normal] text-base text-center text-white-A700'
                    onClick={handleAboutClick}
                    size='xs'
                  >
                    About Us
                  </Button>
                </div>
                <div className='flex flex-col h-10 md:h-auto items-start justify-start'>
                  <Button
                    className='common-pointer bg-transparent cursor-pointer font-semibold h-10 leading-[normal] text-base text-center text-white-A700'
                    onClick={handleLearnClick}
                    size='xs'
                  >
                    Learn
                  </Button>
                </div>
                <div className='flex flex-row h-10 md:h-auto items-start justify-start w-auto'>
                  {/* <MacBookPro14ThirteenColumn
                  className="flex flex-col items-start justify-start w-auto"
                  signintext="Sign In"
                  dropdownicon="images/img_arrowdown_white_a700.svg"
                /> */}
                  {/* <Button
                  className='bg-amber-600 flex flex-row gap-1 h-10 md:h-auto items-center justify-start p-3 rounded w-32 md:w-full'
                  onClick={handleSignInClick}
                  size='xs'
                >
                  <Text
                    className='flex-1 text-center text-sm text-white-A700 tracking-[-0.28px] w-auto'
                    size='txtRalewaySemiBold14'
                  >
                    SignIn
                  </Text>
                </Button> */}

                  {user ? (
                    <>
                      <div class='relative group'>
                        <div className='h-10 w-10 bg-[#fff] rounded-full group mb-2'>
                          {/* Welcome, {user.email} */}
                        </div>
                        <div class='absolute -right-10 pt-3 w-48 bg-[#fff] rounded-md shadow-lg hidden group-hover:block'>
                          <Link
                            to='/profile'
                            // onClick={}
                            class='block px-4 py-2 text-gray-800 hover:bg-gray-200'
                          >
                            Wishlist
                          </Link>

                          <Link
                            to='/portfolio'
                            class='block px-4 py-2 text-gray-800 hover:bg-gray-200'
                          >
                            Portfolio
                          </Link>
                          <Link
                            to='#'
                            class='block px-4 py-2 text-gray-800 hover:bg-gray-200'
                          >
                            Settings
                          </Link>
                          <Link
                            to='#'
                            class='block px-4 py-2 text-gray-800 hover:bg-gray-200'
                          >
                            Logout
                          </Link>
                        </div>
                      </div>
                      <button
                        className='h-10 w-20 bg-yellow-900 rounded-full ml-3'
                        onClick={logout}
                      >
                        Logout
                      </button>
                    </>
                  ) : (
                    <div className='relative group w-32 md:w-full'>
                      <Link
                        to='/login'
                        onClick={Effect}
                        className='bg-amber-600 flex flex-row gap-1 hoverbg-[#fff] h-10 items-center justify-start p-3 rounded w-full'
                      >
                        <span className='flex-1 text-center text-sm text-white tracking-[-0.28px]'>
                          Sign In
                        </span>
                      </Link>
                      <div className='absolute top-full left-0 w-full hidden group-hover:block'>
                        <Link
                          to='/signup'
                          onClick={Effect}
                          className='bg-[#fff] rounded hover:bg-amber-600 flex flex-row gap-1 items-center justify-start p-3  w-full'
                        >
                          <span className='flex-1 text-center text-sm text-white tracking-[-0.28px]'>
                            Sign Up
                          </span>
                        </Link>
                        {/* <button className='bg-[#fff]  hover:bg-amber-600  flex flex-row gap-1 items-center justify-start p-3  ro w-full'>
	  <span className='flex-1 text-center text-sm text-white tracking-[-0.28px]'>
	    Sign Up
	  </span>
	</button> */}
                      </div>
                    </div>
                  )}

                  {/* <MacBookPro14ThirteenColumn
                  className="flex flex-col items-start justify-start w-auto"
                  signintext="Sign In"
                  dropdownicon="images/img_arrowdown_white_a700.svg"
                  onClick={handleSignInClick} // Call handleSignInClick on click
                /> */}
                  {/* <MacBookPro14ThirteenColumnOne className='flex flex-col items-start justify-start w-auto' />
                   */}
                  <div className='flex flex-col items-start justify-start w-auto'>
                    <div className='flex flex-col items-start justify-start rounded w-auto md:w-full group'>
                      <div className='flex flex-row gap-1.5 h-10 md:h-auto items-center justify-center p-3 rounded w-32 md:w-full '>
                        <Img
                          className='h-4 w-4'
                          src={img12}
                          alt='uillanguage'
                        />
                        <Img
                          className='h-4 w-4'
                          src={img13}
                          alt='arrowdown_One'
                        />
                      </div>
                      <div class='absolute -right10 top-14 pt-3 w-28 bg-[#fff] rounded-md shadow-lg hidden group-hover:block'>
                        <button
                          onClick={() => handleLangChange('english')}
                          class='block px-4 py-2 text-gray-800 hover:bg-gray-200'
                        >
                          English
                        </button>
                        <button
                          onClick={() => handleLangChange('hindi')}
                          class='block px-4 py-2 text-gray-800 hover:bg-gray-200'
                        >
                          Hindi
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-col h-10 md:h-auto items-center justify-start'>
                    <div className='flex flex-col items-start justify-start w-5'>
                      <Img className='h-5 w-5' src={img6} alt='brightness' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='flex md:flex-col flex-row font-raleway md:gap-5 items-center justify-between
           w-full'
            >
              <div
                className='flex md:flex-col flex-row font-raleway md:gap-5 items-center justify-
           wull'
              >
                <div className='relative wfull'>
                  <button
                    onClick={() => {
                      setDropdownOpen3(false)
                      setDropdownOpen2(false)
                      // setDropdownOpen(false)
                      setDropdownOpen(!dropdownOpen)
                    }}
                    className='text-[#fff] px-4 py-2 flex items-center font-semibold'
                  >
                    All Coins
                    <svg
                      className={`w-4 h-4 ml-1 transition-transform duration-200 ${
                        dropdownOpen ? 'transform rotate-180' : ''
                      }`}
                      fill='none'
                      stroke='currentColor'
                      viewBox='0 0 24 24'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M19 9l-7 7-7-7'
                      ></path>
                    </svg>
                  </button>
                  {dropdownOpen && (
                    <div
                      ref={dropdownRef}
                      className='absolute -right-[60px] mt-2 w-[200px] bg-[#fff] rounded-md shadow-lg py-1 z-[99999999]'
                    >
                      <div className='flex'>
                        <div className='fex cursor-pointer'>
                          <Link
                            to='/'
                            className='block px-4 py-2 text-sm hover:bg-gray-100'
                            onClick={() => handleTypeChange('allCoin')}
                          >
                            All Coin
                          </Link>
                          <Link
                            to={`/news/${'bitcoin'}`}
                            className='block px-4 py-2 text-sm hover:bg-gray-100'
                            onClick={() => handleTypeChange('bitcoin')}
                          >
                            bitcoin
                          </Link>
                          <Link
                            to={`/news/${'trending'}`}
                            className='block px-4 py-2 text-sm hover:bg-gray-100'
                            onClick={() => handleTypeChange('trending')}
                          >
                            Trending
                          </Link>
                          <Link
                            to={`/news/${'priceAnalysis'}`}
                            className='block px-4 py-2 text-sm hover:bg-gray-100'
                            onClick={() => handleTypeChange('priceAnalysis')}
                          >
                            Price Analysis
                          </Link>
                          <Link
                            to={`/news/${'gameFi'}`}
                            className='block px-4 py-2 text-sm hover:bg-gray-100'
                            onClick={() => handleTypeChange('gameFi')}
                          >
                            Gamefi
                          </Link>
                        </div>
                        <div className='fex cursor-pointer'>
                          <Link
                            to={`/news/${'nft'}`}
                            className='block px-4 py-2 text-sm hover:bg-gray-100'
                            onClick={() => handleTypeChange('nft')}
                          >
                            NFT
                          </Link>
                          <Link
                            to={`/news/${'press'}`}
                            className='block px-4 py-2 text-sm hover:bg-gray-100'
                            onClick={() => handleTypeChange('press')}
                          >
                            press
                          </Link>
                          <Link
                            to={`/news/${'policy'}`}
                            className='block px-4 py-2 text-sm hover:bg-gray-100'
                            onClick={() => handleTypeChange('policy')}
                          >
                            policy
                          </Link>
                          <Link
                            to={`/news/${'defi'}`}
                            className='block px-4 py-2 text-sm hover:bg-gray-100'
                            onClick={() => handleTypeChange('defi')}
                          >
                            defi
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className='relative'>
                  <button
                    onClick={() => {
                      setDropdownOpen3(!dropdownOpen3)
                      setDropdownOpen2(false)
                      setDropdownOpen(false)
                    }}
                    className='text-[#fff] px-4 py-2 flex items-center  font-semibold'
                  >
                    Market Cap
                  </button>
                  {dropdownOpen3 && (
                    <div
                      ref={dropdownRef1}
                      className='absolute -right-[100px] mt-4 w-[300px] bg-[#fff] rounded-md shadow-lg py-0 roundedsm z-[99999999]'
                    >
                      <div className='flex w-full flex-col'>
                        <div className='flex w-full flecol'>
                          <button
                            onClick={() => setTimeFrame('1h')}
                            // className=''
                            className={`text-[#fff] w-full px-4 py-2  ${
                              timeFrame === '1h' ? 'bg-[#FAB605]' : 'bg-[#000]'
                            }`}
                          >
                            1Hr
                          </button>
                          <button
                            onClick={() => setTimeFrame('24h')}
                            className={`text-[#fff] w-full px-4 py-2  ${
                              timeFrame === '24h' ? 'bg-[#FAB605]' : 'bg-[#000]'
                            }`}
                          >
                            24Hr
                          </button>
                        </div>

                        <div className='h-[250px] overflow-y-scroll overflow-x-hidden'>
                          {coindata.map((item, index) => (
                            <article
                              key={index}
                              className='relative bg-cover bg-center h-[50px] w-[300px] rounded-lg overflow-hidden shadow-lg'
                            >
                              <div className='flex  justify-between text-[#000] bg-[#fff] p-3  border-b-2 border-[#000]'>
                                <div className=' leftd flex gap-3 items-center  bg[#fff]'>
                                  <Img
                                    className='h-5 md:h-auto object-cover w-5'
                                    src={item.logoUrl}
                                    alt='logosbitcoin'
                                  />
                                  <Text
                                    className='text-base  w-auto uppercase'
                                    size='txtAssistantRomanSemiBold16'
                                  >
                                    {item.symbol}
                                  </Text>
                                </div>
                                <div className=' rightd flex flex-col justify-end  bg-[#fff]'>
                                  <Text
                                    className='flex-1 text-base text-center  w-auto'
                                    size='txtAssistantRomanSemiBold16'
                                  >
                                    {Number(item.currentPrice).toFixed(1)}
                                  </Text>
                                  <div className='flex flex-row gap-1 items-center justify-end w-auto'>
                                    {parseFloat(getPercentChange(item)) < 0 ? (
                                      <>
                                        <img
                                          className='h-3 w-3'
                                          src='images/img_fedropdown.svg'
                                          alt='trend'
                                        />
                                        <Text
                                          className='text-xs w-auto text-[#000]'
                                          size='txtAssistantRomanSemiBold12'
                                        >
                                          {getPercentChange(item)}%
                                        </Text>
                                      </>
                                    ) : (
                                      <>
                                        <Img
                                          className='h-3 w-3'
                                          src='images/img_fedropup.svg'
                                          alt='fedropup'
                                        />
                                        <Text
                                          className='text-xs w-auto text-[#000]'
                                          size='txtAssistantRomanSemiBold12'
                                        >
                                          {getPercentChange(item)}%
                                        </Text>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </article>
                          ))}
                        </div>

                        <Link
                          to='/marketcap'
                          className='flex flexcol justify-center cursor-pointer text-[#000] bg-[#fff] p-3  border-b-2 border-[#000]'
                          onClick={() => {
                            setDropdownOpen3(false)
                            setDropdownOpen2(false)
                            setDropdownOpen(false)
                          }}
                        >
                          View More
                        </Link>
                      </div>
                    </div>
                  )}
                </div>

                <div className='flex flex-col items-center justify-center md:ml-[0] ml-[23px] px-2.5 py-[7px] wauto'>
                  <Button
                    className='common-pointer bg-transparent cursor-pointer font-semibold h-10 leading-[normal] text-base text-center text-white-A700'
                    onClick={handlePortfolioClick}
                    size='xs'
                  >
                    Portfolio
                  </Button>
                </div>

                <div>
                  <div className='flex flex-col items-center justify-center md:ml-[0] ml-[23px] px-2.5 py-[7px] w-auto'>
                    <button
                      className='common-pointer bg-transparent cursor-pointer font-semibold h-10 leading-[normal] text-base text-center text-white-A700'
                      onClick={() => setPollVisible(true)}
                    >
                      Poll
                    </button>
                  </div>

                  {/* {pollVisible && (
                    <Poll onClose={() => setPollVisible(false)} />
                  )} */}
                  {/* {pollVisible && user && (
                    <ChatComponent onClose={() => setPollVisible(false)} />
                  )} */}
                  {pollVisible &&
                    (user ? (
                      <>
                        <div className='fixed bottom-[13rem] right-[27%] my-4'>
                          <div className='bg-gray-700 text-white p-2 rounded-t flex justify-between'>
                            <button
                              className='text-white'
                              onClick={() => setPollVisible(false)}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                        <ChatComponent onClose={() => setPollVisible(false)} />
                      </>
                    ) : (
                      <>
                        <div
                          ref={chatRef}
                          className='bg-gray-700 text-white p-2 rounded-t flex justify-between fixed bottom-[13rem] right-[27%] my-4'
                        >
                          <button
                            className='text-white'
                            onClick={() => {
                              setPollVisible(false)
                            }}
                          >
                            Close
                          </button>
                        </div>
                        <div className='fixed bottom-0 right-0 m-4 h-[30%] w-[30%] bg-[#fff] overflow-y-scroll'>
                          Please log in first to chat.
                        </div>
                      </>
                    ))}
                </div>
              </div>

              <div className='flex flex-row gap-5 w-[50%] items-start justify-end md:ml-[0] ml[556px] wauto'>
                <div
                  className={`flex flex-row gap-6 h-10 md:h-auto items-center justify-start px-3 ${
                    isSearchActive ? 'w-[50%]' : 'w-[110px]'
                  }`}
                >
                  {isSearchActive ? (
                    <input
                      type='text'
                      className='flex-1 p-2 text-center text-sm text-[#000] tracking-[-0.28px] bg-[#fff]'
                      placeholder='Search...'
                      value={searchTerm}
                      onChange={handleInputChange}
                      onKeyPress={handleKeyPress}
                      onBlur={handleBlur}
                      autoFocus
                    />
                  ) : (
                    <Text
                      className='flex-1 text-center text-sm text-white-A700 tracking-[-0.28px] w-auto cursor-pointer'
                      size='txtRalewaySemiBold14'
                      onClick={handleSearchClick}
                    >
                      Search
                    </Text>
                  )}
                  <Img
                    className='h-4 w-4 cursor-pointer'
                    src={img2}
                    alt='rewind'
                    onClick={handleSearchClick}
                  />
                </div>
                <Img
                  className='h-10 md:h-auto object-cover w-[30px]'
                  src={img3}
                  alt='appstore'
                />
                <Img className='h-10 w-[30px]' src={img4} alt='close' />

                <div className='relative w-[30px]'>
                  <button
                    onClick={() => setDropdownOpen2(!dropdownOpen2)}
                    className='text-[#fff] px-0 py2 flex items-center  font-semibold'
                  >
                    <Img className='h-full w[30px]' src={img5} alt='send' />

                    {/* <svg
                    className={`w-4 h-4 ml-1 transition-transform duration-200 ${
                      dropdownOpen1 ? 'transform rotate-180' : ''
                    }`}
                    fill='none'
                    stroke='currentColor'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M19 9l-7 7-7-7'
                    ></path>
                  </svg> */}
                  </button>
                  {dropdownOpen2 && (
                    <div className='absolute -right-[60px] mt-2 w-20 bg[#fff] rounded-md shadow-lg py-1 z-[99999999]'>
                      {/* <Link
                        to='/'
                        className='block px-4 py-2 text-sm  hover:bg-gray-100'
                      >
                        tele
                      </Link>
                      <Link
                        to='/'
                        className='block px-4 py-2 text-sm  hover:bg-gray-100'
                      >
                        Linkedin
                      </Link>
                      <Link
                        to='/'
                        className='block px-4 py-2 text-sm  hover:bg-gray-100'
                      >
                        facebook
                      </Link>
                      <Link
                        to='/'
                        className='block px-4 py-2 text-sm  hover:bg-gray-100'
                      >
                        x
                      </Link>
                      <Link
                        to='/'
                        className='block px-4 py-2 text-sm  hover:bg-gray-100'
                      >
                        redit
                      </Link>
                      <Link
                        to='/'
                        className='block px-4 py-2 text-sm  hover:bg-gray-100'
                      >
                        insta
                      </Link> */}
                      <Img
                        className='h-[30px] w-[30px] '
                        src='images/img_save.svg'
                        alt='save'
                      />
                      <Img
                        className='h-[30px] w-[30px] my-3'
                        src='images/img_mdilinkedin.svg'
                        alt='mdilinkedin'
                      />
                      <Img
                        className='h-[30px] w-[30px]'
                        src='images/img_icbaselinefacebook.svg'
                        alt='icbaselinefaceb'
                      />
                      <Img
                        className='h-[30px] w-[30px]  my-3'
                        src='images/img_close_white_a700.svg'
                        alt='close_One'
                      />
                      <Img
                        className='h-[30px] w-[30px]'
                        src='images/img_riinstagramfill.svg'
                        alt='riinstagramfill'
                      />
                      <Img
                        className='h-[30px] w-[30px] my-3'
                        src='images/img_icbaselinereddit.svg'
                        alt='icbaselinereddi'
                      />
                      <Img
                        className='h-[30px] w-[30px]'
                        src='images/img_icbaselinediscord.svg'
                        alt='icbaselinedisco'
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  )
}

export default Desktop
