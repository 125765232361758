import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { RatingBar } from 'components/RatingBar'
import { List } from 'components'
import { Text } from 'components'
import Footer from 'components/Footer'
// import { Img } from "components"; // Import Img component
import { Button } from 'components' // Import Button component
import TopNav from 'components/topnav'
import { Helmet } from 'react-helmet'
const Img = ({
  className,
  src = 'defaultNoData.png',
  alt = 'testImg',
  ...restProps
}) => {
  return (
    <img
      className={className}
      src={src}
      alt={alt}
      {...restProps}
      loading={'lazy'}
    />
  )
}
const Learn = () => {
  const navigate = useNavigate()
  const [courses, setCourses] = useState([])

  useEffect(() => {
    async function fetchData () {
      try {
        const response = await fetch(
          'https://api.coinotify.io/learning/getCourses?page=1&limit=10',
          {
            headers: {
              Authorization:
                'token eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwidXNlcm5hbWUiOiJyYWh1bDEiLCJlbWFpbCI6InJhaHVsMUBnbWFpbC5jb20iLCJpYXQiOjE3MDc0NjAxNDd9.idPE8kcmILBatYKYSLpTVjVBr5K9JZKmwrOeaUlla4g'
            }
          }
        )
        if (response.ok) {
          const data = await response.json()
          setCourses(data.data) // Update to access the 'data' array
          // console.log(data)
        } else {
          console.error('Failed to fetch courses:', response.statusText)
        }
      } catch (error) {
        console.error('Error fetching courses:', error)
      }
    }
    fetchData()
  }, []) // Empty dependency array ensures useEffect runs only once

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Coinotify || Learn</title>
        <link rel='canonical' href='https://coinotify.io/learn' />
        <meta name='description' content='Learn about coins' />
      </Helmet>
      <div className='bg-gray-900 flex flex-col font-inter items-start justify-start mx-auto w-full py-20'>
        <div className='flex flex-col items-center w-full'>
          {/* <TopNav /> */}
        </div>
        <List
          className='flex flex-col gap-[42px] items-center max-w-[1264px] mx-auto md:py-10  md:px-5 w-full'
          // orientation='vertical'
        >
          {courses &&
            courses.map((course, index) => (
              <div
                key={index}
                className='flex flex-1 md:flex-col flex-row gap-20 items-start justify-start my-0 w-full'
              >
                <Img
                  className='h-[300px] md:h-auto object-cover rounded-md md:w-full'
                  src={course.thumbNail}
                  alt={`Course thumbnail ${index}`}
                  onClick={() => window.open(course.url, '_blank')}
                />
                <div className='flex flex-col items-start justify-start'>
                  <div className='flex flex-col h-[55px] md:h-auto items-center justify-center p-2.5 w-auto'>
                    <Text
                      className='text-[22px] sm:text-lg text-white-A700 md:text-xl w-auto cursor-pointer'
                      size='txtLoraRomanMedium22'
                      onClick={() => window.open(course.url, '_blank')}
                    >
                      {course.title}
                    </Text>
                  </div>
                  <div className='flex flex-row font-assistant gap-2.5 h-[25px] md:h-auto items-center justify-start mt-[10px] px-2.5 w-auto'>
                    <Text
                      className='text-[17px] text-blue_gray-200 w-auto'
                      size='txtAssistantRomanRegular17'
                    >
                      Reviews:
                    </Text>
                    <div className='flex flex-row items-center justify-center'>
                      <RatingBar
                        className='flex justify-between rounded-[1px] w-[102px]'
                        value={course.rating || 0} // Handle null rating
                        starCount={5}
                        color='#89898966'
                        activeColor='#fab605'
                        size={20}
                      />
                      <Text
                        className='text-[17px] text-blue_gray-200 w-auto'
                        size='txtAssistantRomanRegular17'
                      >
                        ({course.reviews} ratings)
                      </Text>
                    </div>
                  </div>

                  <div className='flex flex-col font-lora h-full md:h-auto items-start justify-start max-w-[676px] mt-3 p-2.5 w-full'>
                    <Text
                      className='max-w-[656px] md:max-w-full text-[15px] text-gray-400'
                      size='txtLoraRomanMedium15'
                    >
                      {course.summary}
                    </Text>
                  </div>
                  <div className='flex flex-row font-assistant sm:gap-10 gap-97px] h-[22px] md:h-auto items-start justify-between max-w-[653px]  px-2.5 w-full'>
                    <a
                      href={course.url}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[15px] text-gray-400 underline w-auto '
                    >
                      {/* {course.url} */}
                      udemy.com
                    </a>
                    <Text
                      className='text-blue_gray-200 text-center text-sm w-auto'
                      size='txtAssistantRomanSemiBold14'
                    >
                      {course.level}
                    </Text>
                  </div>
                </div>
              </div>
            ))}
        </List>
        {/* Your existing JSX */}
      </div>
      {/* <Footer className='bg-blue_gray-900_01 flex items-center justify-center md:px-5 w-full' /> */}
    </>
  )
}

export default Learn
