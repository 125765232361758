import React, { useState, useEffect, useRef } from 'react'
import io from 'socket.io-client'
import axios from 'axios'

const ChatComponent = ({ onClose }) => {
  const [messageList, setMessageList] = useState([])
  const [tempMessageList, setTempMessageList] = useState([])
  const [message, setMessage] = useState('')
  const [socket, setSocket] = useState(null)
  const chatRef = useRef(null)
  const saveChatInterval = 5 * 60 * 1000 // 5 minutes in milliseconds

  useEffect(() => {
    const newSocket = io.connect('https://api.coinotify.io')
    setSocket(newSocket)
    newSocket.on('connect', () => {
      // console.log('Connected to server')
      newSocket.emit('join-room', { room: 'chatRoom' })
    })

    newSocket.on('receive-message', msgObj => {
      // console.log(`Received message: ${msgObj}`)
      setMessageList(prevMessageList => [...prevMessageList, msgObj])
      setTempMessageList(prevTempMessageList => [
        ...prevTempMessageList,
        msgObj
      ])
    })

    newSocket.on('error', error => {
      // console.log('socket.io-client: ', error)
    })

    newSocket.on('disconnect', () => {
      // console.log('Connection closed')
    })

    return () => {
      newSocket.disconnect()
    }
  }, [])

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight
    }
  }, [messageList])

  useEffect(() => {
    const interval = setInterval(() => {
      if (tempMessageList.length > 0) {
        axios
          .post('http://localhost:5001/user/saveChat', tempMessageList)
          .then(response => {
            // console.log('Chat saved:', response.data)
            setTempMessageList([]) // Clear temp messages after sending
          })
          .catch(error => {
            console.error('Error saving chat:', error)
          })
      }
    }, saveChatInterval)

    return () => clearInterval(interval)
  }, [tempMessageList])

  const sendMessage = e => {
    e.preventDefault()
    if (!message || !socket) return

    const msgObj = {
      time: new Date().toLocaleTimeString(),
      room: 'chatRoom',
      text: message,
      user: 'user1',
      groupId: 'cryptoIQ'
    }

    socket.emit('send-message', msgObj)
    setMessage('')
  }

  const handleClickOutside = event => {
    if (chatRef.current && !chatRef.current.contains(event.target)) {
      onClose()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const linkify = text => {
    const urlPattern = /(https?:\/\/[^\s]+)/g
    return text.split(urlPattern).map((part, index) =>
      urlPattern.test(part) ? (
        <a
          key={index}
          className='font-bold hover:opacity-70'
          href={part}
          target='_blank'
          rel='noopener noreferrer'
        >
          {part}
        </a>
      ) : (
        part
      )
    )
  }
  return (
    <>
      {/* <div className='bg-gray-700 text-white p-2 rounded-t flex justify-between'>
        <button className='text-white' onClick={onClose}>
          Close
        </button>
      </div> */}
      <div
        ref={chatRef}
        className='fixed bottom-0 right-0 m-4 h-[30%] w-[30%] bg-[#fff] overflow-y-scroll'
      >
        <div className='flex-grow p-4 overflow-y-scroll h[500px]'>
          {messageList.map((msg, index) => (
            <div key={index} className='mb-2'>
              <span className='text-sm text-gray-600'>{msg.time}</span>
              <div className='p-2 rounded bg-blue-200 inline-block'>
                <strong>{msg.user}:</strong> {linkify(msg.text)}
              </div>
            </div>
          ))}
        </div>
        <form
          onSubmit={sendMessage}
          className='flex p-4 bg-white border-t border-gray-200 '
        >
          <input
            type='text'
            value={message}
            onChange={e => setMessage(e.target.value)}
            placeholder='Type your message...'
            className='flex-grow p-2 border rounded mr-2'
          />
          <button type='submit' className='p-2 bg-blue-500 text-white rounded'>
            Send
          </button>
        </form>
      </div>
    </>
  )
}

export default ChatComponent
