import React, { useState, useEffect } from 'react'
// import { Button, List, Text } from 'components'
import './tab.css'
// import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

const MacBookPro14FourTrendingdark = ({ selectedType, onTypeChange }) => {
  const [newsData, setNewsData] = useState([])
  const apiUrl = `https://api.coinotify.io/news/allNews?page=1&limit=10&type=trending`

  const navigate = useNavigate()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl)
        if (!response.ok) {
          throw new Error('Failed to fetch data')
        }
        const data = await response.json()
        setNewsData(data.data)
      } catch (error) {
        console.error(error.message)
      }
    }

    fetchData()
  }, [apiUrl])

  // ////////////////////////

  const calculateTimeAgo = createdAt => {
    const currentTime = new Date()
    const createdAtTime = new Date(createdAt)
    const timeDifferenceInMs = currentTime - createdAtTime
    const timeDifferenceInSeconds = Math.floor(timeDifferenceInMs / 1000)
    const timeDifferenceInMinutes = Math.floor(timeDifferenceInSeconds / 60)
    const timeDifferenceInHours = Math.floor(timeDifferenceInMinutes / 60)

    if (timeDifferenceInMinutes < 1) {
      return 'Just now'
    } else if (timeDifferenceInMinutes < 60) {
      return `${timeDifferenceInMinutes} ${
        timeDifferenceInMinutes === 1 ? 'minute' : 'minutes'
      } ago`
    } else if (timeDifferenceInHours < 24) {
      return `${timeDifferenceInHours} ${
        timeDifferenceInHours === 1 ? 'hour' : 'hours'
      } ago`
    } else {
      return createdAtTime.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      })
    }
  }

  // //////////////////////////////////////////////////////////////////////////////////////
  const handleTypeChange = type => {
    onTypeChange(type)
  }
  return (
    <>
      <div>
        <div className='bg-blue_gray-900_01 flex flex-col items-center justify-center sm:px-5 px-6 py-3 rounded-tl rounded-tr w-[400px] md:w-full mt-0'>
          <h2
            className='text-base text-white-A700 w-auto'
            // size='txtRalewayMedium16'
          >
            TRENDING NEWS
          </h2>
        </div>

        <div>
          <div className='tabs mt-0'>
            {newsData.slice(0).map((item, index) => (
              <article
                className='border-b-[1px] p- border-[#fff] bg-blue_gray-900 w-full'
                key={index}
              >
                <div className='p-4'>
                  <p className='text-[16px] text-[#fff]'>{item.articleTitle}</p>
                  <div className='flex justify-between items-center mt-4'>
                    <a
                      className='a text-[#fff] font-semibold'
                      href={item.articleSource}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {item.articleSource.replace('https://', '')}
                    </a>
                    <p className='time text-[#fff]'>
                      {calculateTimeAgo(item.articleTimestamp)}
                    </p>
                  </div>
                </div>
              </article>
            ))}
          </div>
          {newsData.length > 3 && (
            <button
              onClick={() => {
                handleTypeChange('trending')
                navigate('/')
              }}
              className='text-[#fff]'
            >
              {'Show More'}
            </button>
          )}
        </div>
      </div>
    </>
  )
}

// MacBookPro14FourTrendingdark.defaultProps = {
//   trendingtext: 'Trending',
//   button1hr: '1Hr',
//   button24hr: '24Hr',
//   button7d: '7D',
//   newstitle1:
//     'Bloomberg Senior Strategist Mike McGlone Has An Interesting Theory About Why Bitcoin Is Plunging',
//   newssource1: 'Bitcoinsistemi.com',
//   newstime1: '10: 10 PM',
//   newstitle2:
//     'Bloomberg Senior Strategist Mike McGlone Has An Interesting Theory About Why Bitcoin Is Plunging',
//   newssource2: 'Bitcoinsistemi.com',
//   newstime2: '10: 10 PM',
//   bloombergseniorOne:
//     'Bloomberg Senior Strategist Mike McGlone Has An Interesting Theory About Why Bitcoin Is Plunging',
//   newssource3: 'Bitcoinsistemi.com',
//   newstime3: '10: 10 PM',
//   advertisementbutton: 'Advertisment',
//   moretext: 'More'
// }

export default MacBookPro14FourTrendingdark
