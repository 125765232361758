import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import ChooseCoin from 'components/ChooseCoin/chooseCoin'
import { Text } from 'components'
import { Input } from 'components/Input'
import { Heading } from 'components/Heading'
import Footer from 'components/Footer'
import MacBookPro14ThirteenColumn from 'components/MacBookPro14ThirteenColumn'
import MacBookPro14ThirteenColumnOne from 'components/MacBookPro14ThirteenColumnOne'
import MacBookPro14TenStackgroupseven from 'components/MacBookPro14TenStackgroupseven'
import TopNav from 'components/topnav'
import Chart from 'chart.js/auto'
import { Helmet } from 'react-helmet'

const Terms = () => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Coinotify || Terms & conditions</title>
        <link rel='canonical' href='https://coinotify.io/terms' />
      </Helmet>
      <div
        className={`bg-gray-900 flex flex-col font-raleway items-center justify-start mx-auto w-full pb-20 `}
      >
        <div className='bg-gray-900 flex flex-col font-raleway items-center justify-start mx-auto w-full'>
          <div className='flex flex-col items-center justify-start w-full'>
            {/* <TopNav /> */}
            <div className='mx-auto mt-[103px] flex w-full max-w-[1329px] flex-col items-start md:p-5'>
              <Heading
                size='10xl'
                as='hl'
                className='text-center !font-raleway !text-[36.72px] !text-amber-600'
              >
                Terms and Conditions
              </Heading>

              <div className='mt-7 flex items-start gap-[17px] self-stretch md:flex-col md:w-full'>
                <Text
                  size='9xl'
                  as='p'
                  className='font-raleway text-[25.72px] leading-[140%] text-white-A700 md:w-full'
                >
                  <span className='font-bold text-white-A700 '>
                    1. Content and Services
                    <br />
                  </span>
                  <span className='text-white-A700 text-lg '>
                    Coinotify is a news aggregator that collects and delivers
                    cryptocurrency news stories from various sources. We do not
                    create the content we provide, and we are not responsible
                    for its accuracy, completeness, or reliability. We strive to
                    provide accurate and up-to-date information, but we cannot
                    guarantee its accuracy or completeness. You should not rely
                    on our content for making investment decisions, and you
                    should conduct your own research before making any financial
                    decisions. We reserve the right to modify or remove any
                    content at any time without notice.
                    <br />
                    <br />
                  </span>
                  <span className='font-bold text-white-A700'>
                    2. User Conduct
                    <br />
                  </span>
                  <span className='text-white-A700 text-lg'>
                    You agree to use the Website in a lawful and responsible
                    manner. You agree not to use the Website for any harmful or
                    illegal purposes, including but not limited to: Infringing
                    on the intellectual property rights of others. Violating the
                    privacy of others. Transmitting spam or other unsolicited
                    communications. Engaging in fraudulent or deceptive
                    activities. Interfering with the operation of the Website.
                    <br />
                    <br />
                  </span>
                  <span className='font-bold text-white-A700'>
                    3. Third-Party Links
                    <br />
                  </span>
                  <span className='text-white-A700 text-lg'>
                    The Website may contain links to third-party websites. These
                    links are provided for your convenience only, and we are not
                    responsible for the content or privacy practices of these
                    websites. You should review the terms and conditions and
                    privacy policies of any third-party websites you visit
                    before using them.
                    <br />
                    <br />
                  </span>
                  <span className='font-bold text-white-A700'>
                    4. Disclaimer of Warranties
                    <br />
                  </span>
                  <span className='text-white-A700 text-lg'>
                    THE WEBSITE AND ITS CONTENT ARE PROVIDED " AS IS" AND
                    WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED. WE
                    DISCLAIM ALL WARRANTIES, INCLUDING BUT NOT LIMITED TO, THE
                    IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                    PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE DO NOT WARRANT
                    THAT THE WEBSITE WILL BE UNINTERRUPTED, ERROR-FREE, OR
                    VIRUS-FREE. YOU UNDERSTAND AND AGREE THAT YOU USE THE
                    WEBSITE AT YOUR OWN RISK.
                    <br />
                    <br />
                  </span>
                  <span className='font-bold text-white-A700'>
                    5. Limitation of Liability
                    <br />
                  </span>
                  <span className='text-white-A700 text-lg'>
                    WE WILL NOT BE LIABLE FOR ANY DAMAGES ARISING OUT OF OR IN
                    CONNECTION WITH YOUR USE OF THE WEBSITE, INCLUDING BUT NOT
                    LIMITED TO, DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR
                    PUNITIVE DAMAGES. THIS LIMITATION OF LIABILITY APPLIES TO
                    THE FULLEST EXTENT PERMITTED BY LAW.
                    <br />
                    <br />
                  </span>
                  <span className='font-bold text-white-A700'>
                    6. Indemnification
                    <br />
                  </span>
                  <span className='text-white-A700 text-lg'>
                    You agree to indemnify and hold harmless coinotify, its
                    officers, directors, employees, agents, and licensors from
                    and against any and all claims, losses, expenses, damages,
                    and costs, including reasonable attorneys' fees, arising out
                    of or in connection with your use of the Website.
                    <br />
                    <br />
                  </span>
                  <span className='font-bold text-white-A700'>
                    7. Changes to Terms
                    <br />
                  </span>
                  <span className='text-white-A700 text-lg'>
                    We may update these Terms at any time by posting a new
                    version on the Website. You are responsible for checking
                    these Terms periodically for updates. Your continued use of
                    the Website following the posting of any changes constitutes
                    your acceptance of those changes.
                    <br />
                    <br />
                  </span>
                  <span className='font-bold text-white-A700'>
                    8. Termination
                    <br />
                  </span>
                  <span className='text-white-A700 text-lg'>
                    We may terminate your access to the Website at any time, for
                    any reason, without notice. Upon termination, all rights and
                    obligations under these Terms will cease, except that the
                    following provisions will survive: sections 3, 5, 6, and 7.
                    <br />
                    <br />
                  </span>
                  <span className='font-bold text-white-A700'>
                    9. Governing Law
                    <br />
                  </span>
                  <span className='text-white-A700 text-lg'>
                    These Terms and your use of the Website will be governed by
                    and construed in accordance with the laws of India, without
                    regard to its conflict of laws provisions. Additionally, in
                    the event of any dispute arising out of or relating to these
                    Terms or your use of the Website, you agree to submit to the
                    exclusive jurisdiction of the courts located in Noida, Uttar
                    Pradesh, India.
                    <br />
                    <br />
                  </span>
                  <span className='font-bold text-white-A700'>
                    10. Entire Agreement
                    <br />
                  </span>
                  <span className='text-white-A700 text-lg'>
                    These Terms constitute the entire agreement between you and
                    us with respect to your use of the Website.
                    <br />
                    <br />
                  </span>
                  <span className='font-bold text-white-A700 '>
                    11. Contact Us
                  </span>
                  <br />
                  <span className='font-bold text-white-A700 '>
                    If you have any questions about these Terms, please contact
                    <span className='font-bold text-[#4227bd] '>
                      {' '}
                      us at coinotify@coinotify.io
                    </span>
                  </span>

                  <br />
                </Text>
              </div>
            </div>

            {/* <Footer className="bg-blue_gray-900_01 flex items-center justify-center mt-[300px] md:px-5 w-full" /> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default Terms
