import React, { useEffect, useState } from 'react'

import Desktop from './Desktop'
import Mobile from './Mobile'

import { useLocation } from 'react-router-dom'

const TopNav = ({ onTypeChange, onLangChange }) => {
  const location = useLocation()
  let [width, setWidth] = useState(0)
  useEffect(() => {
    const updateWidth = () => {
      setWidth(window.innerWidth)
    }
    updateWidth()
    window.addEventListener('resize', updateWidth)
    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [])

  return (
    <>
      {width <= 900 ? (
        <Mobile onTypeChange={onTypeChange} />
      ) : location.pathname === '/marketcap' ? (
        // <DesktopForMarketCap onTypeChange={onTypeChange} />
        <Desktop onTypeChange={onTypeChange} onLangChange={onLangChange} />
      ) : (
        // <Desktop onTypeChange={onTypeChange} />
        <Desktop onTypeChange={onTypeChange} onLangChange={onLangChange} />
      )}
    </>
  )
}

export default TopNav
