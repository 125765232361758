import React, { useContext, useState } from 'react'
import { Heading } from 'components/Heading'
import { Input } from 'components/Input'
import { CheckBox } from 'components/CheckBox'
import { Link, useNavigate } from 'react-router-dom'
import { AuthContext } from 'Context/AuthContext'
import { Helmet } from 'react-helmet'
const shapes = { round: 'rounded' }
const variants = {
  fill: {
    yellow_900: 'bg-yellow-900 text-white-A700',
    amber_600: 'bg-amber-600'
  }
}
const sizes = { xs: 'py-2.5', sm: 'p-[13px]' }

const Button = ({
  children,
  className = '',
  leftIcon,
  rightIcon,
  shape = '',
  size = '',
  variant = '',
  color = '',
  onClick,
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${(shape && shapes[shape]) || ''} ${
        (size && sizes[size]) || ''
      } ${(variant && variants[variant]?.[color]) || ''}`}
      onClick={onClick}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  )
}

export default function SignupPage () {
  const { login } = useContext(AuthContext)
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [fillerror, setFillError] = useState(false)
  const navigate = useNavigate()

  const handleClick = () => {
    // Redirect to the Learn page
    navigate('/')
  }

  const [errorMessage, setErrorMessage] = useState('')
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const [emailfill, setEmailfiill] = useState('')
  const [userfill, setUserfiill] = useState('')
  const [passfill, setPassfiill] = useState('')
  const handleSignup = () => {
    // Make API call to create account
    if (!termsAccepted) {
      setErrorMessage('You must accept the terms and conditions!')
      setFillError(false)
      return
    } else {
      setFillError(false)
      // setErrorMessage('You must accept the terms and conditions!')
      fetch('https://api.coinotify.io/user/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user: {
            username: username,
            email: email,
            password: password
          }
        })
      })
        .then(response => {
          if (!response.ok) {
            return response.json().then(errorData => {
              const errorMessage =
                errorData.message || response.statusText || 'An error occurred'
              console.error(`Error: ${response.status} - ${errorMessage}`)
              // setError(`Error: ${response.status} - ${errorMessage}`)
              setFillError(false)
              setError(`${errorMessage}`)
              setTimeout(() => setError(''), 5000)
              setErrorMessage(false)
              setFillError(false)
              throw new Error(`Error: ${response.status} - ${errorMessage}`)
            })
            // throw new Error('Network response was not ok')
          }
          setFillError(false)
          setErrorMessage(false)
          setError('')
          setShowSuccessModal(true)
          return response.json()
        })
        .then(data => {
          // window.location.reload()
          localStorage.setItem('token', data.user.token)
          login(data.user)
          // alert('Account created successfully!')
          setShowSuccessModal(true)
          // setTimeout(() => setShowSuccessModal(false), 500)
          // console.log(data)
          // console.log('Generated Token:', data.user.token)
          setTimeout(() => navigate('/'), 3000)
          // navigate('/login')
        })
        .catch(error => {
          console.error('Error:', error)

          // Handle error if the API call fails
        })
    }
  }

  const handleCloseError = () => {
    setError('')
  }

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false)
    // history.push('/home'); // Redirect to /home after closing the modal
  }
  // //////////////////////////////////////////////////////////

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Coinotify || Signup</title>
        <link rel='canonical' href='https://coinotify.io/signup' />
      </Helmet>
      <div className='flex justify-center w-full px-14 py-[88px] md:p-5 bg-gray-900 h-full  md:pt-20'>
        <div className='flex flex-col items-center w-[32%] md:w-full mb-[34px] gap-6 p-[21px] sm:p-5 bg-gray-700 rounded-[15px]'>
          <Heading
            size='8xl'
            as='h1'
            className='tracking-[0.14px] !font-raleway text-center'
          >
            Sign up now !
          </Heading>
          <div className='w-[84%] md:w-full mb-[5px]'>
            <input
              name='username'
              placeholder='User Name'
              type='text'
              style={{
                width: '100%',
                padding: '10px',
                marginBottom: '15px',
                backgroundColor: '#4a5568',
                color: '#ffffff',
                border: '1px solid',
                borderColor: userfill ? '#ff0000' : '#ffffff',
                borderRadius: '5px'
              }}
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
            <input
              name='email'
              type='email'
              placeholder='Email'
              style={{
                width: '100%',
                padding: '10px',
                marginBottom: '15px',
                backgroundColor: '#4a5568',
                color: '#ffffff',
                borderColor: emailfill ? '#ff0000' : '#ffffff',
                borderRadius: '5px'
              }}
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <input
              name='password'
              placeholder='Password'
              type='password'
              style={{
                width: '100%',
                padding: '10px',
                marginBottom: '15px',
                backgroundColor: '#4a5568',
                color: '#ffffff',
                borderColor: passfill ? '#ff0000' : '#ffffff',
                borderRadius: '5px'
              }}
              value={password}
              onChange={e => setPassword(e.target.value)}
            />

            <Button
              className='cursor-pointer font-bold font-raleway h-[45px] leading-[normal] my-[30px] rounded-[12px] text-[15px] text-center tracking-[0.10px] w-[320px]] w-[100%]'
              color='yellow_900'
              size='sm'
              variant='fill'
              onClick={handleSignup}
            >
              Create Account
            </Button>
            <p className='text-[#fff]'>
              Already have an account?{' '}
              <Link to='/login' className='!text-indigo-300'>
                Sign In
              </Link>
            </p>
            {/* <CheckBox
              name='byusingthiswebs'
              label='By using this website, you acknowledge that you have read, understood, and agreed to these terms and conditions.'
              id='byusingthiswebs'
              className='mt-2 gap-[17px] text-white-A700 font-plusjakartasans text-left text-xs leading-[18px] w-[100%]'
            /> */}

            <div className='flex justify-between items-center'>
              <span className='text-red-500'>{errorMessage}</span>
              {/* <button onClick={handleCloseError} className='ml-4 text-xl'>
                &times;
              </button> */}
            </div>

            <div className='mt-2'>
              <input
                type='checkbox'
                name='byusingthiswebs'
                id='byusingthiswebs'
                checked={termsAccepted}
                onChange={e => setTermsAccepted(e.target.checked)}
              />
              <label
                htmlFor='byusingthiswebs'
                className='ml-2 text-white-A700 font-plusjakartasans text-left text-xs leading-[18px]'
              >
                By using this website, you acknowledge that you have read,
                understood, and agreed to these terms and conditions.
              </label>
            </div>
          </div>
        </div>

        <div
          className='fixed bottom-28 right-4 w[500px] z-50 max-w-xs bg-red-500 text-white p-4 rounded-lg shadow-lg transform transition-transform duration-500 ease-in-out translate-x-full'
          style={{ transform: error ? 'translateX(0)' : 'translateX(130%)' }}
        >
          <div className='flex justify-between items-center'>
            <span>{error}</span>
            <button onClick={handleCloseError} className='ml-4 text-xl'>
              &times;
            </button>
          </div>
        </div>

        {showSuccessModal && (
          <div
            className='fixed top-40 right-4 w[500px] z-50 max-w-xs bg-[#15ff21] text-white p-4 rounded-lg shadow-lg transform transition-transform duration-500 ease-in-out translate-x-full'
            style={{
              transform: showSuccessModal ? 'translateX(0)' : 'translateX(130%)'
            }}
          >
            <div className='flex justify-between items-center'>
              <span>Account created successfully!</span>
              <button onClick={handleCloseError} className='ml-4 text-xl'>
                &times;
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
