import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate
} from 'react-router-dom'
import Home from 'pages/Home'
import NotFound from 'pages/NotFound'
import Learn from 'pages/Learn'
import Contact from 'pages/Contact'
import MarketCap from 'pages/marketcap'
import Portfolio from 'pages/Portfolio'
import SignupPage from 'pages/Signup'
import Login from 'pages/Login'
import AboutUs from 'pages/AboutUs'
import Terms from 'pages/Terms'
import Policy from 'pages/Policy'
import MacBookPro14TenStackgroupseven from 'components/MacBookPro14TenStackgroupseven'
import { useState } from 'react'
import TopNav from 'components/topnav'
import Footer from 'components/Footer'
import SearchResults from 'pages/SearchResults'
import PrivateRoute from 'componentsbyaman/PrivateRoute/PrivateRoute'
import Advertise from 'pages/Advertise'
import Career from 'pages/Career'
import NewsResult from 'pages/NewsResult'
import ArticlePage from 'pages/ArticlePage'
import Profile from 'pages/Profile'
// import { ArticleProvider } from 'ArticleProvider'
const MacBookPro14Four = React.lazy(() => import('pages/MacBookPro14Four'))
const ProjectRoutes = () => {
  const [selectedType, setSelectedType] = useState('allCoin')
  const [selectedlang, setSelectedLang] = useState('hindi')
  const [loading, setLoading] = useState(false)

  const handleTypeChange = type => {
    // setSelectedType(type)
    setLoading(true) // Show loader
    setSelectedType(type)
    setTimeout(() => {
      setLoading(false) // Hide loader after 1 seconds
    }, 1000)
  }
  const handleLangChange = lang => {
    setLoading(true)
    setSelectedLang(lang)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Router>
        <TopNav
          onTypeChange={handleTypeChange}
          onLangChange={handleLangChange}
        />
        {loading && (
          <div className='loader fixed h-full w-full bg-blue_gray-900 opacity-90 z-[99999999999999999999999999999999999999999] text-[50px] text-center text-[#fff]  top0'>
            <div className='spinner'>
              <br />
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 50 50'
                className='spinner-svg'
              >
                <circle
                  className='path'
                  cx='25'
                  cy='25'
                  r='20'
                  fill='none'
                  strokeWidth='5'
                />
              </svg>
            </div>
          </div>
        )}

        <Routes>
          <Route
            path='/news/:searchTermm'
            element={
              <Home
                selectedType={selectedType}
                selectedlang={selectedlang}
                onTypeChange={handleTypeChange}
                onLangChange={handleLangChange}
              />
            }
          />
          <Route
            path='/'
            element={
              <Home
                selectedType={selectedType}
                selectedlang={selectedlang}
                onTypeChange={handleTypeChange}
                onLangChange={handleLangChange}
              />
            }
          />
          <Route
            path='/search/news/:searchTerm'
            element={<SearchResults onLangChange={handleLangChange} />}
          />

          <Route
            path='/news/:selectedType/:id/:articleTitle'
            element={<ArticlePage onLangChange={handleLangChange} />}
          />
          <Route
            path='/news/:selectedType/:articleUrl'
            element={<ArticlePage />}
          />

          {/* <Route path='/news/:type/:title' element={<ArticlePage />} /> */}
          {/* <Route path='*' element={<NotFound />} /> */}
          {/* <Route path="/macbookpro14four" element={<MacBookPro14Four />} /> */}
          <Route path='/learn' element={<Learn />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/marketcap' element={<MarketCap />} />
          {/* <Route path='/portfolio' element={<Portfolio />} /> */}
          <Route
            path='/portfolio'
            element={<PrivateRoute element={<Portfolio />} />}
          />
          <Route path='/aboutus' element={<AboutUs />} />
          <Route path='/advertise' element={<Advertise />} />
          <Route path='/career' element={<Career />} />
          <Route
            path='/macbookpro14ten'
            element={<MacBookPro14TenStackgroupseven />}
          />
          <Route path='/signup' element={<SignupPage />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/login' element={<Login />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/policy' element={<Policy />} />
        </Routes>

        <Footer className='bg-blue_gray-900_01 flex items-center justify-center mt[1361px] md:px-5 w-full' />
      </Router>
    </React.Suspense>
  )
}

export default ProjectRoutes
