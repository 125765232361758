import React from 'react'
import './styles/color.css'
import './styles/font.css'
import ReactDOM from 'react-dom'
import App from './App'
import './styles/index.css'
import './styles/tailwind.css'
import { AuthProvider } from 'Context/AuthContext'
// import { ArticleProvider } from 'ArticleProvider'
ReactDOM.render(
  <React.StrictMode>
    {/* <ArticleProvider> */}
    <AuthProvider>
      <App />
    </AuthProvider>
    {/* </ArticleProvider> */}
  </React.StrictMode>,
  document.getElementById('root')
)
