// import React from "react";

// import { Text } from "components";

// const MacBookPro14FourColumnprice = (props) => {
//   return (
//     <>
//       <div
//         className={props.className}
//         style={{ backgroundImage: "url('images/img_newsframeimg.png')" }}
//       >
//         <Text
//           className="max-w-[358px] md:max-w-full text-white-A700 text-xl"
//           size="txtLoraRomanSemiBold20"
//         >
//           {props?.title}
//         </Text>
//         <div className="flex flex-row gap-[15px] items-center justify-start w-full">
//           <Text
//             className="flex-1 text-white-A700_cc text-xs w-auto"
//             size="txtAssistantRomanRegular12"
//           >
//             {props?.website}
//           </Text>
//           <Text
//             className="text-gray-50 text-xs w-auto"
//             size="txtAssistantRomanRegular12Gray50"
//           >
//             {props?.timestamp}
//           </Text>
//         </div>
//       </div>
//     </>
//   );
// };

// MacBookPro14FourColumnprice.defaultProps = {
//   title: "Circle USDC Soars $100M in Weekly Circulation Boost",
//   website: "Bitcoinsistemi.com",
//   timestamp: "Feb. 4, 2023,  10 : 10 PM ",
//   description: (
//     <>
//       Circle USDC, a stablecoin backed by the US dollar, has seen a significant
//       increase in its circulation in the past week. According to the latest data
//       from [Circle], the total supply of USDC has grown by over $100 million,
//       reaching a new all-time high of $1.4 billion as of November 9, 2023.
//       <br />
//       The surge in USDC circulation comes amid a growing demand for stablecoins
//       in the crypto market, especially as Bitcoin and other cryptocurrencies
//     </>
//   ),
// };

// export default MacBookPro14FourColumnprice;

// import React from "react";
// import { Text } from "components";

// const MacBookPro14FourColumnprice = (props) => {
//   // Function to truncate the description to the first 15 words
//   const truncateDescription = (description) => {
//     const words = description && description.toString().split(" ");
//     const truncatedWords = words && words.slice(0, 15);
//     return (
//       truncatedWords &&
//       truncatedWords.join(" ") + (words.length > 15 ? "..." : "")
//     );
//   };

//   return (
//     <>
//       <div
//         className={`${props.className} relative overflow-hidden`}
//         style={{
//           backgroundImage: "url('images/img_newsframeimg.png')",
//           transition: "transform 0.3s ease",
//         }}
//       >
//         <div className="group">
//           <Text
//             className="max-w-[358px] md:max-w-full text-white-A700 text-xl transition-transform group-hover:translate-y-[-50%]"
//             size="txtLoraRomanSemiBold20"
//           >
//             {props?.title}
//           </Text>
//           <div className="flex flex-row gap-[15px] items-center justify-start w-full transition-transform group-hover:translate-y-[-50%]">
//             <Text
//               className="flex-1 text-white-A700_cc text-xs w-auto"
//               size="txtAssistantRomanRegular12"
//             >
//               {props?.website}
//             </Text>
//             <Text
//               className="text-gray-50 text-xs w-auto"
//               size="txtAssistantRomanRegular12Gray50"
//             >
//               {props?.timestamp}
//             </Text>
//             <Text
//               className="max-w-[358px] md:max-w-full text-base text-white-100"
//               size="txtLoraRomanRegular16"
//             >
//               {truncateDescription(props?.description)}
//             </Text>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// MacBookPro14FourColumnprice.defaultProps = {
//   title: "Circle USDC Soars $100M in Weekly Circulation Boost",
//   website: "Bitcoinsistemi.com",
//   timestamp: "Feb. 4, 2023,  10:10 PM ",
//   description: (
//     <>
//       Circle USDC, a stablecoin backed by the US dollar, has seen a significant
//       increase in its circulation in the past week. According to the latest data
//       from [Circle], the total supply of USDC has grown by over $100 million,
//       reaching a new all-time high of $1.4 billion as of November 9, 2023.
//       <br />
//       The surge in USDC circulation comes amid a growing demand for stablecoins
//       in the crypto market, especially as Bitcoin and other cryptocurrencies
//     </>
//   ),
// };

// export default MacBookPro14FourColumnprice;
import React from "react";
import { Text } from "components";

const MacBookPro14FourColumnprice = (props) => {
  const truncateDescription = (description) => {
    if (!description) return "";

    // Convert React element to a string
    const descriptionString =
      typeof description === "string"
        ? description
        : React.Children.toArray(description)
            .map((child) => (typeof child === "string" ? child : ""))
            .join(" ");

    // Split the string into words
    const words = descriptionString.split(" ");

    // Truncate the words and join them
    const truncatedWords = words.slice(0, 15);
    const truncatedDescription =
      truncatedWords.join(" ") + (words.length > 15 ? "..." : "");

    return truncatedDescription;
  };

  return (
    <>
      <div
        className={`${props.className} relative overflow-hidden`}
        style={{
          backgroundImage: "url('images/img_newsframeimg.png')",
          transition: "transform 0.3s ease",
        }}
      >
        <div className="group">
          <Text
            className="max-w-[358px] md:max-w-full text-white-A700 text-xl transition-transform group-hover:translate-y-[-50%]"
            size="txtLoraRomanSemiBold20"
          >
            {props?.title}
          </Text>
          <div className="flex flex-row gap-[15px] items-center justify-start w-full transition-transform group-hover:translate-y-[-50%]">
            <Text
              className="flex-1 text-white-A700_cc text-xs w-auto"
              size="txtAssistantRomanRegular12"
            >
              {props?.website}
            </Text>
            <Text
              className="text-gray-50 text-xs w-auto"
              size="txtAssistantRomanRegular12Gray50"
            >
              {props?.timestamp}
            </Text>
          </div>
          <Text
            className="max-w-[358px] md:max-w-full text-base text-white-A700 text-l opacity-0 transition-opacity group-hover:opacity-100"
            size="txtLoraRomanRegular16"
          >
            {/* {truncateDescription(props?.description)} */}
            {props?.description}
          </Text>
        </div>
      </div>
    </>
  );
};

MacBookPro14FourColumnprice.defaultProps = {
  title: "Circle USDC Soars $100M in Weekly Circulation Boost",
  website: "Bitcoinsistemi.com",
  timestamp: "Feb. 4, 2023,  10:10 PM ",
  description: (
    <>
      Circle USDC, a stablecoin backed by the US dollar, has seen a significant
      increase in its circulation in the past week.
    </>
  ),
};

export default MacBookPro14FourColumnprice;
