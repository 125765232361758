import React, { useState, useEffect } from 'react'
import { Text, Button } from 'components'
import { List } from 'components'
import AddFolio from 'components/AddFolio'

const Line = ({ className, ...restProps }) => {
  return <div className={className} {...restProps} />
}

const Img = ({
  className,
  src = 'defaultNoData.png',
  alt = 'testImg',
  ...restProps
}) => {
  return (
    <img
      className={className}
      src={src}
      alt={alt}
      {...restProps}
      loading={'lazy'}
    />
  )
}

const ChooseCoin = ({ onClose }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [coins, setCoins] = useState([])
  const [filteredCoins, setFilteredCoins] = useState([])
  const [selectedCoin, setSelectedCoin] = useState(null) // State to track selected coin
  const [isAddFolioOpen, setIsAddFolioOpen] = useState(false) // State to manage popup visibility

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=50&page=1'
        )
        const data = await response.json()
        const coinData = data.map(coin => ({
          name: coin.name,
          image: coin.image,
          symbol: coin.symbol
        }))
        setCoins(coinData)
        setFilteredCoins(coinData)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [])

  const handleSearchChange = event => {
    const query = event.target.value
    setSearchQuery(query)
    const filtered = coins.filter(coin =>
      coin.name.toLowerCase().includes(query.toLowerCase())
    )
    setFilteredCoins(filtered)
  }

  const handleCoinSelect = coin => {
    setSelectedCoin(coin)
    setIsAddFolioOpen(true)
  }

  const handleClose = () => {
    onClose()
  }

  const handleCloseAddFolio = () => {
    setIsAddFolioOpen(false)
    onClose()
  }

  return (
    <>
      <div className='absolute bg-gray-900 flex flex-col gap-[21px] justify-start left-[30%] p-5 rounded-[5px] top-[6%] w-[30%] md:w-full md:left-0 md:top-[30%]'>
        {isAddFolioOpen && selectedCoin && (
          <AddFolio
            coin={selectedCoin}
            onClose={handleCloseAddFolio}
            selectedCoin={selectedCoin}
          />
        )}
      </div>

      {!isAddFolioOpen && (
        <div className='bg-[#374151] rounded-[5px] p-2 absolute  flex flex-col gap-[21px] justify-start left-[30%] p5 -[5px] top-[30%] w-[30%] md:w-full md:left-0 md:top-[30%]'>
          <button onClick={handleClose} className='common-pointer'>
            <img
              className='h-[38px] w-[38px]'
              src='images/close.png'
              alt='close_Two'
            />
          </button>
          <div className='flex flex-col items-start justify-start mb-[50px] mx-auto w-[77%] md:w-full'>
            <Text
              className='sm:text-[16.19px] md:text-[18.19px] text-[20.19px] text-white-A700'
              size='txtRalewayRomanMedium2019'
            >
              Search your favorite coin
            </Text>

            <div className='h-14 md:h-[93px] mt-10 relative w-full'>
              <div className='absolute border border-blue_gray-900 border-solid flex flex-col h-full inset-[0] items-start justify-center m-auto p-[13px] rounded-[5px] w-full'>
                <input
                  id='coinSearch'
                  type='text'
                  className='h-[27px] md:ml-[0] ml-[3px] rounded-[5px] w-full text-white-A700 bg-transparent'
                  placeholder='Enter coin name'
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
            <div className='h-[377px] md:h-[401px] mt-[27px] relative w-full'>
              <div
                className='absolute bg-blue_gray-900_03 flex flex-col gap-3 h-full inset-[0] justify-center m-auto py-[9px] rounded-[5px] w-full overflow-y-scroll max-h-[377px]'
                // style={{ overflowY: 'scroll', maxHeight: '377px' }}
              >
                <List
                  className='flex flex-col gap-3 items-center pt-3 px-3 w-full bg-gray-800 mt-[0%]'
                  orientation='vertical'
                >
                  {filteredCoins.map((coin, index) => (
                    <React.Fragment key={index}>
                      <div
                        className='flex flex-row gap-[26px] h-[38px] md:h-auto items-center justify-start w-[250px] cursor-pointer'
                        onClick={() => handleCoinSelect(coin)} // Handle click event for selecting coin
                      >
                        <div className='flex flex-col h-7 items-center justify-start w-7'>
                          <Img
                            className='h-7 md:h-auto rounded-[50%] w-7'
                            src={coin.image}
                            alt={coin.name}
                          />
                        </div>
                        <Text
                          className='text-[19.02px] text-white-A700 w-auto'
                          size='txtRalewayRomanMedium1902'
                        >
                          {coin.name}
                        </Text>
                      </div>
                      <Line className='self-center h-px bg-gray-700 w-full' />
                    </React.Fragment>
                  ))}
                </List>
              </div>
              <div className='absolute bg-blue_gray-100 flex flex-col h-full inset-y-[0] items-center justify-start my-auto py-[90px] right-[0] rounded w-[3%]'>
                <div className='bg-yellow-900_01 h-[156px] mb-[38px] rounded w-full'></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ChooseCoin
