import React, { useState, useEffect, useRef } from 'react'
import send from '../../assets/images/send.png'
import userIcon from '../../assets/images/user.svg'
import io from 'socket.io-client'

const Poll = ({ onClose }) => {
  const [messages, setMessages] = useState([])
  const [inputValue, setInputValue] = useState('')
  const socketRef = useRef(null)

  useEffect(() => {
    const socket = io('https://api.coinotify.io')
    socketRef.current = socket

    socket.on('connect', () => {
      // console.log('Connected to server')
      socket.emit('join-room', { room: 'chatRoom' })
    })

    socket.on('receive-message', msgObj => {
      setMessages(prevMessages => [...prevMessages, msgObj])
      // console.log(prevMessages => [...prevMessages, msgObj])
    })

    socket.on('error', error => {
      // console.log('socket.io-client: ', error)
    })

    socket.on('disconnect', () => {
      // console.log('Connection closed')
    })

    return () => {
      socket.disconnect()
    }
  }, [])

  const handleSendMessage = () => {
    if (inputValue.trim() === '') return

    const newMessage = {
      text: inputValue,
      sender: 'user',
      time: new Date().toLocaleTimeString(),
      room: 'chatRoom',
      user: 'user1',
      groupId: 'cryptoIQ'
    }

    setMessages([...messages, newMessage])
    socketRef.current.emit('send-message', newMessage)
    setInputValue('')
  }

  return (
    <div className='fixed bottom-0 right-0 m-4 h-900'>
      <div className='bg-gray-700 text-white-A700 p-2 rounded-t flex justify-between'>
        <h2>Poll</h2>
        <button className='text-white' onClick={onClose}>
          Close
        </button>
      </div>
      <div className='bg-gray-50 border'>
        <div className='overflow-y-auto h-40 w-8/12 p-5 mb-1'>
          {messages.map((message, index) => (
            <div
              key={index}
              className={`p-2 ${
                message.sender === 'user'
                  ? 'bg-gray-200 rounded-br'
                  : 'bg-white rounded-bl'
              }`}
              style={{ marginBottom: '5px' }}
            >
              {message.sender === 'user' && (
                <div className='flex items-center mb-1'>
                  <img
                    src={userIcon}
                    alt='User'
                    className='w-4 h-4 mr-1 rounded-full'
                  />
                  <span className='text-xs font-bold'>You</span>
                </div>
              )}

              <span>{message.text}</span>
              <span className='text-xs float-right'>{message.time}</span>
            </div>
          ))}
        </div>
        <div className='flex mt-4'>
          <input
            type='text'
            className='flex-1 border border-gray-300 rounded-l p-2'
            placeholder='Type your message...'
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
          />
          <button
            className='bg-orange-500 text-white px-4 py-2 rounded-full'
            onClick={handleSendMessage}
          >
            <img src={send} alt='Send' className='w-6 h-6' />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Poll
