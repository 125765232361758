import React from "react";

import { Img } from "components";

const MacBookPro14FourColumnOne = (props) => {
  return (
    <>
      <div className={props.className}>
        <div className="flex flex-col items-start justify-start rounded w-auto md:w-full">
          <div className="flex flex-row gap-1.5 h-10 md:h-auto items-center justify-center p-3 rounded w-32 md:w-full">
            <Img
              className="h-4 w-4"
              src="images/img_uillanguage.svg"
              alt="uillanguage"
            />
            <Img
              className="h-4 w-4"
              src="images/img_arrowdown.svg"
              alt="arrowdown_One"
            />
          </div>
        </div>
      </div>
    </>
  );
};

MacBookPro14FourColumnOne.defaultProps = {};

export default MacBookPro14FourColumnOne;
