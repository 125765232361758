import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const SearchNewsLoad = ({ selectedType }) => {
  const [newsData, setNewsData] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)

  const { searchTerm } = useParams()
  const [results, setResults] = useState([])
  const fetchData = async page => {
    setLoading(true)

    const BitcoinApi = `https://api.coinotify.io/news/getUpdatesByCoin?page=${page}&limit=10&type=${searchTerm}`
    try {
      const response = await fetch(BitcoinApi)
      if (!response.ok) {
        throw new Error('Failed to fetch data')
      }
      const data = await response.json()
      setNewsData(prevData => [...prevData, ...data.data])
      // console.log(data.data)
    } catch (error) {
      console.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setNewsData([])
    setPage(1)
    fetchData(1)
  }, [selectedType])

  const handleShowMore = () => {
    const nextPage = page + 1
    setPage(nextPage)
    fetchData(nextPage)
  }

  // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // utils.js
  const navigate = useNavigate()

  const slugifyTitle = title => {
    const truncatedTitle = title.split(' ').slice(0, 6).join(' ') // Get first 6 words
    return truncatedTitle
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^\s+|\s+$)+/g, '')
      .replace(/\s+/g, '-')
  }

  const transformType = type => {
    // console.log(type)
    return type === 'allCoin' ? 'crypto' : type
  }
  // console.log(transformType(selectedType))
  const handleNavigation = (id, title) => {
    const path = `/news/${searchTerm}/${id}/${slugifyTitle(title)}`
    navigate(path)
  }
  // ////////////////////////

  return (
    <>
      <div className='flex flex-wrap gap-10 w-full'>
        {newsData.map((item, index) => (
          <div
            onClick={() => handleNavigation(item.id, item.articleTitle)}
            key={index}
            className='relative bg-cover bg-center group h-full w-[395px] rounded-lg overflow-hidden shadow-lg cursor-pointer'
            // style={{ backgroundImage: `url(${item.thumbNail})` }}
          >
            <div className='h-[150px]'>
              <img
                src={item.thumbNail}
                alt={`Slide ${index}`}
                className='slideshowimage w-full h-[150px]'
              />
            </div>
            <div className=' inset-0 bg-[#363636] bg-opacity-50 flex flex-col justify-end p-4'>
              <h1 className='text-[18px] group-hover:underline text-[#fff]  textlg'>
                {item.articleTitle}
                {/* {truncateTitle(item.articleTitle)} */}
              </h1>
              <p className='text-white mt-2 flex justify-between items-center'>
                <a
                  href={item.articleSource}
                  target='_blank'
                  rel='noopener noreferrer'
                  className=' text-[#fff] '
                >
                  {item.articleSource.replace('https://', '')}
                </a>
                <p className='textgray-300 text-[20px] text-[#fff] mt-0'>
                  {new Date(item.articleTimestamp).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true
                  })}
                </p>
              </p>
            </div>
          </div>
        ))}
      </div>

      {!loading && (
        <button
          onClick={handleShowMore}
          className='show-more-button bg-amber-600 p-3 rounded-md'
        >
          Show More
        </button>
      )}
    </>
  )
}

export default SearchNewsLoad
