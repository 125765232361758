import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import ChooseCoin from 'components/ChooseCoin/chooseCoin'
import { Text, List } from 'components'
import Footer from 'components/Footer'
import MacBookPro14ThirteenColumn from 'components/MacBookPro14ThirteenColumn'
import MacBookPro14ThirteenColumnOne from 'components/MacBookPro14ThirteenColumnOne'
import MacBookPro14TenStackgroupseven from 'components/MacBookPro14TenStackgroupseven'
import Chart from 'chart.js/auto'
import TopNav from 'components/topnav'
import { Helmet } from 'react-helmet'

const shapes = { round: 'rounded' }
const variants = {
  fill: {
    yellow_900: 'bg-yellow-900 text-white-A700',
    amber_600: 'bg-amber-600'
  }
}
const sizes = { xs: 'py-2.5', sm: 'p-[13px]' }
const Img = ({
  className,
  src = 'defaultNoData.png',
  alt = 'testImg',
  ...restProps
}) => {
  return (
    <img
      className={className}
      src={src}
      alt={alt}
      {...restProps}
      loading={'lazy'}
    />
  )
}
const Button = ({
  children,
  className = '',
  leftIcon,
  rightIcon,
  shape = '',
  size = '',
  variant = '',
  color = '',
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${(shape && shapes[shape]) || ''} ${
        (size && sizes[size]) || ''
      } ${(variant && variants[variant]?.[color]) || ''}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  )
}
const MarketCap = () => {
  const navigate = useNavigate()
  const [isVisible, setIsVisible] = useState(false)
  const [isAdd, setIsAdd] = useState(false)
  const [coinData, setCoinData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(10)
  const [dom, setDom] = useState([])

  const [searchQuery, setSearchQuery] = useState('')

  // const apiUrl =
  //   'https://api.coinotify.io/market/coinMarket?page=1&limit=5&type=cryptoAsset'
  useEffect(() => {
    const fetchCoinData = async () => {
      try {
        const response = await fetch(
          `https://api.coinotify.io/market/coinMarket?page=${currentPage}&limit=20&type=cryptoAsset`
        )
        if (response.ok) {
          const responseData = await response.json()
          // console.log('Fetched data:', responseData) // Log the fetched data
          setCoinData(responseData.data)
          setTotalPages(responseData.totalPages) // Assuming API response contains totalPages
        } else {
          console.error('Failed to fetch data from the API')
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchCoinData()
  }, [currentPage])

  const filteredCoinData = coinData.filter(coin =>
    coin.name.toLowerCase().includes(searchQuery.toLowerCase())
  )

  // ///////////////////////////////////////////

  useEffect(() => {
    const fetchCoinData = async () => {
      try {
        const response = await fetch(
          `https://api.coinotify.io/market/coinMarketCap`
        )
        if (response.ok) {
          const responseData = await response.json()
          // console.log('dom data:', responseData) // Log the fetched data
          setDom(responseData)
          setTotalPages(responseData.totalPages) // Assuming API response contains totalPages
        } else {
          console.error('Failed to fetch data from the API')
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchCoinData()
  }, [])

  const chartRefs = useRef([])
  const loadNextPage = () => {
    setCurrentPage(currentPage + 1)
  }
  const formatPrice = price => {
    const roundedPrice = Number(price).toFixed(2) // Round to 2 decimal places
    const [integerPart, decimalPart] = roundedPrice.split('.') // Split integer and decimal parts
    const formattedIntegerPart = Number(integerPart).toLocaleString() // Format integer part with commas
    const trimmedDecimalPart =
      Number(decimalPart) === 0 ? '' : '.' + Number(decimalPart).toString() // Remove trailing zeros if present
    return formattedIntegerPart + trimmedDecimalPart // Combine integer and decimal parts
  }

  useEffect(() => {
    if (coinData && coinData.length > 0) {
      coinData.forEach((coin, index) => {
        const canvas = chartRefs.current[index]
        const ctx = canvas.getContext('2d')

        // Check if a chart instance exists and destroy it
        if (canvas.chart) {
          canvas.chart.destroy()
        }

        // Determine the line color based on the trend
        let lineColor = 'rgb(0, 255, 0)' // default green
        if (coin.sparkline7d && coin.sparkline7d.length > 0) {
          const firstValue = coin.sparkline7d[0]
          const lastValue = coin.sparkline7d[coin.sparkline7d.length - 1]
          if (lastValue < firstValue) {
            lineColor = 'rgb(255, 0, 0)' // red if values are going down
          }
        }

        // Create a new chart instance
        if (coin.sparkline7d) {
          canvas.chart = new Chart(ctx, {
            type: 'line',
            data: {
              labels: Array.from(
                { length: coin.sparkline7d.length },
                (_, i) => i + 1
              ),
              datasets: [
                {
                  label: '', // Set label to empty string
                  data: coin.sparkline7d,
                  borderColor: lineColor,
                  tension: 0,
                  fill: false,
                  pointRadius: 0,
                  borderWidth: 1.5
                }
              ]
            },
            options: {
              scales: {
                x: {
                  display: false // Hide x-axis
                },
                y: {
                  display: false // Hide y-axis
                }
              },
              plugins: {
                legend: {
                  display: false // Hide legend
                }
              }
            }
          })
        }
      })
    }

    return () => {
      // Clean up function
      coinData.forEach((coin, index) => {
        const canvas = chartRefs.current[index]
        if (canvas) {
          // console.log('canvas', canvas)
        } else {
          // console.log('error')
        }
        if (canvas && canvas.chart) {
          canvas.chart.destroy()
          delete canvas.chart
        }
      })
    }
  }, [coinData])

  // //////////////////////////////////////////////////////////////////////////////////
  const formatNumberWithCommas = number => {
    if (typeof number === 'number') {
      return number.toLocaleString('en-US')
    } else {
      // console.error('Market cap is not a number:', number)
      return number
    }
  }

  const formatPriceWithFourDecimals = price => {
    if (typeof price !== 'number') {
      price = parseFloat(price)
    }

    // Format to 7 decimal places initially
    let formattedPrice = price.toFixed(7)

    // Check if the first four decimal places are zeros
    const decimalPart = formattedPrice.split('.')[1]
    if (decimalPart && decimalPart.startsWith('0000')) {
      // Keep up to 7 decimal places
      formattedPrice = price.toFixed(5)
    } else {
      // Keep up to 3 decimal places
      formattedPrice = price.toFixed(5)
    }

    // Remove trailing zeros but keep precision for very small numbers
    formattedPrice = formattedPrice.replace(/(\.\d*[1-9])0+$|\.0*$/, '$1')
    const parts = formattedPrice.split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    return parts.join('.')
  }

  const handlePageChange = page => {
    setCurrentPage(page)
  }

  const renderPagination = () => {
    const pages = []
    const totalPages = 100 // Assuming 100 pages in total, adjust accordingly
    const maxPagesToShow = 12 // Maximum number of pages to show directly

    const handlePreviousPage = () => {
      if (currentPage > 1) {
        handlePageChange(currentPage - 1)
      }
    }

    const handleNextPage = () => {
      if (currentPage < totalPages) {
        handlePageChange(currentPage + 1)
      }
    }

    // Add "previous" arrow
    pages.push(
      <button
        key='previous'
        onClick={handlePreviousPage}
        className='m-2 cursor-pointer text-[#fff] border[1px] border[#fff] p-1'
        disabled={currentPage === 1}
      >
        &lt;
      </button>
    )

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className='m-2 cursor-pointer text-[#fff] border-[1px] border-[#fff] p-1'
            disabled={i === currentPage}
          >
            {i}
          </button>
        )
      }
    } else {
      const showLeftEllipsis = currentPage > 6
      const showRightEllipsis = currentPage < totalPages - 5

      // Always show the first page
      pages.push(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          className='m-2 cursor-pointer text-[#fff] border-[1px] border-[#fff] p-1'
          disabled={1 === currentPage}
        >
          1
        </button>
      )

      if (showLeftEllipsis) {
        pages.push(
          <span key='left-ellipsis' className='m-2 text-[#fff]'>
            ...
          </span>
        )
      }

      const startPage = Math.max(2, currentPage - 4)
      const endPage = Math.min(totalPages - 1, currentPage + 4)

      for (let i = startPage; i <= endPage; i++) {
        pages.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className='m-2 cursor-pointer text-[#fff] border-[1px] border-[#fff] p-1'
            disabled={i === currentPage}
          >
            {i}
          </button>
        )
      }

      if (showRightEllipsis) {
        pages.push(
          <span key='right-ellipsis' className='m-2 text-[#fff]'>
            ...
          </span>
        )
      }

      // Always show the last page
      pages.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className='m-2 cursor-pointer text-[#fff] border-[1px] border-[#fff] p-1'
          disabled={totalPages === currentPage}
        >
          {totalPages}
        </button>
      )
    }

    // Add "next" arrow
    pages.push(
      <button
        key='next'
        onClick={handleNextPage}
        className='m-2 cursor-pointer text-[#f9f9fa] border[1px] border[#fff] p-1'
        disabled={currentPage === totalPages}
      >
        &gt;
      </button>
    )

    return pages
  }

  const itemsPerPage = 20
  const startIndex = (currentPage - 1) * itemsPerPage + 1

  // ////////////////
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Coinotify || Marketcap</title>
        
        <link rel='canonical' href='https://coinotify.io/marketcap' />
      </Helmet>
      <div
        className={`bg-gray-900 flex flex-col font-inter items-center justify-start mx-auto w-full `}
      >
        <div className='bg-gray-900 flex flex-col font-inter items-center justify-start mx-auto w-full'>
          <div className='flex flex-col items-center justify-start w-full'>
            {/* <TopNav /> */}
            <List
              className='sm:flex-col flex-row font-radiocanada gap-[19px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-4 md:ml-[0] ml[74px] mt[39px] md:px-5 w-[75%] mt-20'
              orientation='horizontal'
            >
              <div className='bg-yellow-900 flex flex-col items-center justify-start p-[11px] rounded-[16px] w-full'>
                <div className='flex flex-col gap-2 items-start justify-start mb-[5px] mt-0.5 w-[99%] md:w-full'>
                  <Text
                    className='text-blue_gray-100 text-center text-sm tracking-[-0.28px]'
                    size='txtRadioCanadaRomanRegular14'
                  >
                    Market Cap
                  </Text>
                  <div className='flex flex-row gap-4 items-start justify-between w-full'>
                    <Text
                      className='mt-[3px] text-blue_gray-100 text-center text-sm tracking-[-0.28px]'
                      size='txtRadioCanadaRomanSemiBold14'
                    >
                      {/* {dom.totalMarketCap} */}

                      {dom.totalMarketCap
                        ? `$${formatPrice(parseFloat(dom.totalMarketCap))}`
                        : 'N/A'}
                      {/* {coinData && coinData.length > 0 && (
                      <span>
                        {coinData[0].marketCap
                          ? `$ ${formatNumberWithCommas(
                              parseFloat(coinData[0].marketCap)
                            )}`
                          : 'N/A'}
                      </span>
                    )} */}
                      {/* ₹140,810,531,259,349.53 */}
                    </Text>
                    <Button
                      className='cursor-pointer flex items-center justify-center min-w-[57px]'
                      leftIcon={
                        <div className='h-[7px] mt-1 mb-[3px] mr-1 w-[7px] bg-green00'>
                          {dom.totalMarketCapChangePer24h < 0 ? (
                            <img
                              className='h-3 w-3'
                              src='images/img_fedropdown.svg'
                              alt='trend'
                            />
                          ) : (
                            <Img
                              className='h-3 w-3'
                              src='images/img_fedropup.svg'
                              alt='fedropup'
                            />
                          )}
                        </div>
                      }
                    >
                      <div
                        className={`font-semibold text-[13px] text-center tracking-[-0.26px] textgreen-400 ${
                          dom.totalMarketCapChangePer24h < 0
                            ? 'text-red-500'
                            : 'text-green-400'
                        }`}
                      >
                        <span>
                          {dom.totalMarketCapChangePer24h < 0
                            ? `${formatPriceWithFourDecimals(
                                parseFloat(dom.totalMarketCapChangePer24h)
                              ).slice(1)}%`
                            : `${formatPriceWithFourDecimals(
                                parseFloat(dom.totalMarketCapChangePer24h)
                              )}%`}
                        </span>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
              <div className='bg-gray-900_01 flex flex-col items-center justify-start p-[11px] rounded-[16px] w-full'>
                <div className='flex flex-col gap-[9px] items-start justify-start mb-[5px] w-full'>
                  <Text
                    className='text-gray-100 text-center text-sm tracking-[-0.28px]'
                    size='txtRadioCanadaRomanRegular14'
                  >
                    Volume 24h
                  </Text>
                  <div className='flex flex-row gap-4 items-start justify-between ml-0.5 md:ml-[0] w-full'>
                    <Text
                      className='mt-[3px] text-gray-100 text-center text-sm tracking-[-0.28px]'
                      size='txtRadioCanadaRomanSemiBold14'
                    >
                      {dom.totalVolume24h
                        ? `$${formatNumberWithCommas(
                            parseFloat(dom.totalVolume24h)
                          )}`
                        : 'N/A'}
                    </Text>
                    <Button
                      className='cursor-pointer flex items-center justify-center min-w-[57px]'
                      leftIcon={
                        <div className='h-[7px] mt-1 mb-[3px] mr-1 w-[7px] bg-green00'>
                          {dom.totalVolumeChangePer24h < 0 ? (
                            <img
                              className='h-3 w-3'
                              src='images/img_fedropdown.svg'
                              alt='trend'
                            />
                          ) : (
                            <Img
                              className='h-3 w-3'
                              src='images/img_fedropup.svg'
                              alt='fedropup'
                            />
                          )}
                        </div>
                      }
                    >
                      <div className='!textred-500 font-semibold text-[13px] text-center tracking-[-0.26px]'>
                        <div
                          className={`font-semibold text-[13px] text-center tracking-[-0.26px] textgreen-400 ${
                            dom.totalVolumeChangePer24h < 0
                              ? 'text-red-500'
                              : 'text-green-400'
                          }`}
                        >
                          <span>
                            {dom.totalVolumeChangePer24h < 0
                              ? `${formatPrice(
                                  parseFloat(dom.totalVolumeChangePer24h)
                                ).slice(1)}%`
                              : `${formatPrice(
                                  parseFloat(dom.totalVolumeChangePer24h)
                                )}%`}
                          </span>
                        </div>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
              <div className='bg-gray-900_01 flex flex-col items-center justify-start p-[11px] rounded-[16px] w-full'>
                <div className='flex flex-col gap-[9px] items-start justify-start mb-1.5 w-[98%] md:w-full'>
                  <Text
                    className='text-gray-100 text-center text-sm tracking-[-0.28px]'
                    size='txtRadioCanadaRomanRegular14'
                  >
                    BTC Dominance
                  </Text>
                  <div className='flex flex-row items-start justify-between w-full'>
                    <Text
                      className='mt-0.5 text-gray-100 text-center text-sm tracking-[-0.28px]'
                      size='txtRadioCanadaRomanSemiBold14'
                    >
                      {dom.btcDominancePer
                        ? ` ${formatPrice(parseFloat(dom.btcDominancePer))}`
                        : 'N/A'}
                      %
                    </Text>
                    <Button
                      className='cursor-pointer flex items-center justify-center min-w-[57px]'
                      leftIcon={
                        <div className='h-[7px] mt-1 mb-[3px] mr-1 w-[7px] bg-green00'>
                          {/* {dom.btcDominanceChangePer24h < 0 ? (
                          <img
                            className='h-3 w-3'
                            src='images/img_fedropdown.svg'
                            alt='trend'
                          />
                        ) : (
                          <Img
                            className='h-3 w-3'
                            src='images/img_fedropup.svg'
                            alt='fedropup'
                          />
                        )} */}
                        </div>
                      }
                    >
                      <div
                        className={`font-semibold text-[13px] text-center tracking-[-0.26px] textgreen-400 ${
                          dom.btcDominanceChangePer24h < 0
                            ? 'text-red-500'
                            : 'text-green-400'
                        }`}
                      >
                        {/* <span>
                        {dom.btcDominanceChangePer24h < 0
                          ? `${formatPriceWithFourDecimals(
                              parseFloat(dom.btcDominanceChangePer24h)
                            ).slice(1)}%`
                          : `${formatPriceWithFourDecimals(
                              parseFloat(dom.btcDominanceChangePer24h)
                            )}%`}
                      </span> */}
                      </div>
                    </Button>
                  </div>
                </div>
              </div>

              <input
                type='text'
                placeholder='Search...'
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
                className='bg-gray-900_01 text-[#fff] flex flex-col items-center justify-start p-[11px] rounded-[16px] w-[50%]'
              />
            </List>

            {/* <input
            type='text'
            placeholder='Search for a coin...'
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            className='search-input'
          /> */}

            {filteredCoinData.length === 0 ? (
              <p className='h-40 mt-50'>Coin not available</p>
            ) : (
              <div className='h-full md:h-full max-w-[1362px] mt-[39px] mx-auto md:px-5 relative w-full mb-80'>
                <div className='overflow-x-auto'>
                  <table className='w-full border-collapse bg-navyBlue-800 text-gray-100 min-w-[1000px] text-right'>
                    <thead>
                      <tr className='bg-navyBlue-700'>
                        <th className='p-2 font-bold'>#</th>
                        <th className='p-2 font-bold text-left'>Name</th>
                        <th className='p-2 font-bold'>Price</th>
                        <th className='p-2 font-bold'>1h%</th>
                        <th className='p-2 font-bold'>24h%</th>
                        <th className='p-2 font-bold'>7d%</th>
                        <th className='p-2 font-bold'>Market Cap</th>
                        <th className='p-2 font-bold'>Volume(24h)</th>
                        <th className='p-2 font-bold'>Circulating Supply</th>
                        <th className='p-2 font-bold'>Last 7 Days</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredCoinData.map((coin, index) => (
                        <tr
                          key={coin.id}
                          className={`transition-all ${
                            index % 2 === 0
                              ? 'bg-navyBlue-900'
                              : 'bg-navyBlue-800'
                          } hover:bg-gray-700`}
                        >
                          {/* <td className='p-2'> */}
                          <td className='p-2'>{startIndex + index}</td>
                          {/* </td> */}
                          <td className='p-2 flex items-start justify-start'>
                            <img
                              src={coin.logoUrl}
                              alt={coin.name}
                              className='h-6 w-6 mr-2'
                            />
                            {coin.name}
                          </td>
                          <td className='p-2'>
                            {coin.currentPrice
                              ? `$${formatPriceWithFourDecimals(
                                  parseFloat(coin.currentPrice)
                                )}`
                              : 'N/A'}
                          </td>
                          <td
                            className={`p-2 ${
                              coin.percentChange1h < 0
                                ? 'text-red-500'
                                : 'text-green-400'
                            }`}
                          >
                            {coin.percentChange1h < 0
                              ? coin.percentChange1h.toString().slice(1) + '%'
                              : coin.percentChange1h + '%'}
                          </td>
                          <td
                            className={`p-2 ${
                              coin.percentChange24h < 0
                                ? 'text-red-500'
                                : 'text-green-400'
                            }`}
                          >
                            {coin.percentChange24h < 0
                              ? coin.percentChange24h.toString().slice(1) + '%'
                              : coin.percentChange24h + '%'}
                          </td>
                          <td
                            className={`p-2 ${
                              coin.percentChange7d < 0
                                ? 'text-red-500'
                                : 'text-green-400'
                            }`}
                          >
                            {coin.percentChange7d < 0
                              ? coin.percentChange7d.toString().slice(1) + '%'
                              : coin.percentChange7d + '%'}
                          </td>
                          <td className='p-2'>
                            {coin.marketCap
                              ? `$${formatNumberWithCommas(
                                  parseFloat(coin.marketCap)
                                )}`
                              : 'N/A'}
                          </td>
                          <td className='p-2'>
                            {coin.volume24h
                              ? `$ ${formatNumberWithCommas(
                                  parseFloat(coin.volume24h)
                                )}`
                              : 'N/A'}
                          </td>
                          <td className='p-2'>
                            {coin.circulatingSupply
                              ? `$ ${formatNumberWithCommas(
                                  parseFloat(coin.circulatingSupply)
                                )}`
                              : 'N/A'}
                            {coin.symbol.toUpperCase()}
                          </td>
                          <td className='p-2'>
                            <div className='h-12 w-28'>
                              <canvas
                                ref={el => (chartRefs.current[index] = el)}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}

                      {/* {coinData &&
                    coinData.map((coin, index) => (
                      <tr
                        key={coin.id}
                        className={`transition-all ${
                          index % 2 === 0
                            ? 'bg-navyBlue-900'
                            : 'bg-navyBlue-800'
                        } hover:bg-gray-700`}
                      >
                        <td className='p-2'>{startIndex + index}</td>
                        <td className='p-2 flex items-center justifycenter'>
                          <img
                            src={coin.logoUrl}
                            alt={coin.name}
                            className='h-6 w-6 mr-2'
                          />
                          {coin.name}
                        </td>

                        <td className='p-2'>
                          {coin.currentPrice
                            ? `$${formatPriceWithFourDecimals(
                                parseFloat(coin.currentPrice)
                              )}`
                            : 'N/A'}
                        </td>

                        <td
                          className={`p-2 ${
                            coin.percentChange1h < 0
                              ? 'text-red-500'
                              : 'text-green-400'
                          }`}
                        >
                          {coin.percentChange1h < 0
                            ? coin.percentChange1h.toString().slice(1) + '%'
                            : coin.percentChange1h + '%'}
                        </td>
                        <td
                          className={`p-2 ${
                            coin.percentChange24h < 0
                              ? 'text-red-500'
                              : 'text-green-400'
                          }`}
                        >
                          {coin.percentChange24h < 0
                            ? coin.percentChange24h.toString().slice(1) + '%'
                            : coin.percentChange24h + '%'}
                        </td>
                        <td
                          className={`p-2 ${
                            coin.percentChange7d < 0
                              ? 'text-red-500'
                              : 'text-green-400'
                          }`}
                        >
                          {coin.percentChange7d < 0
                            ? coin.percentChange7d.toString().slice(1) + '%'
                            : coin.percentChange7d + '%'}
                        </td>

                        <td className='p-2'>
                          {coin.marketCap
                            ? `$${formatNumberWithCommas(
                                parseFloat(coin.marketCap)
                              )}`
                            : 'N/A'}
                        </td>
                        <td className='p-2'>
                          {coin.volume24h
                            ? `$ ${formatNumberWithCommas(
                                parseFloat(coin.volume24h)
                              )}`
                            : 'N/A'}
                        </td>
                        <td className='p-2'>
                          {coin.circulatingSupply
                            ? `$ ${formatNumberWithCommas(
                                parseFloat(coin.circulatingSupply)
                              )}`
                            : 'N/A'}

                          {coin.symbol.toUpperCase()}
                        </td>
                        <td className='p-2'>
                          <div className='h-12 w-28'>
                            <canvas
                              ref={el => (chartRefs.current[index] = el)}
                            />
                          </div>
                        </td>
                      </tr>
                    ))} */}
                    </tbody>
                  </table>

                  <div className='flex justify-center items-center'>
                    {renderPagination()}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <Footer className='bg-blue_gray-900 flex items-center justify-center md:px-5 w-full' /> */}
      </div>
    </>
  )
}

export default MarketCap
