import React, { useState, useEffect } from 'react'

import { Button, Img, List, SelectBox, Text } from 'components'
import TopNav from 'components/topnav'
import MacBookPro14FourColumn from 'components/MacBookPro14FourColumn'
import MacBookPro14FourColumnOne from 'components/MacBookPro14FourColumnOne'
import MacBookPro14FourColumnprice from 'components/MacBookPro14FourColumnprice'
import MacBookPro14FourColumnprice1 from 'components/MacBookPro14FourColumnprice1'
import MacBookPro14FourHeroslides from 'components/MacBookPro14FourHeroslides'
import MacBookPro14FourMorenewsdark from 'components/MacBookPro14FourMorenewsdark'
import MacBookPro14FourTrendingdark from 'components/MacBookPro14FourTrendingdark'
import Learn from './Learn'
import Slideshow from './Slideshow'
import './Sider.css'
import { useNavigate, useParams } from 'react-router-dom'
import Footer from 'components/Footer'
import Hometradingcoin from 'componentsbyaman/hometradingcoins/Hometradingcoin'
import TND from 'componentsbyaman/TNDashboard/TND'
import NewsLoad from 'componentsbyaman/allnewlaoding/NewsLoad'
import Newsslide from 'componentsbyaman/newscompo/Newsslide'
import SearchNewsLoad from 'componentsbyaman/newscompo/SearchNewsLoad'
import NewsTND from 'componentsbyaman/newscompo/NewsTND'
import NewspageTrend from 'componentsbyaman/newscompo/NewspageTrend'

const SearchResults = ({ selectedType, onTypeChange }) => {
  const navigate = useNavigate()

  const [Bitcon, setBitcon] = useState([])
  const [newsData, setNewsData] = useState([])

  const apiUrl = `https://api.coinotify.io/news/allNews?page=1&limit=10&type=${selectedType}`
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl)
        if (!response.ok) {
          throw new Error('Failed to fetch data')
        }
        const data = await response.json()
        setNewsData(data.data)
        // console.log(data.data)
      } catch (error) {
        console.error(error.message)
      }
    }

    fetchData()
  }, [selectedType])

  const { searchTerm } = useParams()
  const [results, setResults] = useState([])

  const BitcoinApi = `https://api.coinotify.io/news/getUpdatesByCoin?page=1&limit=10&type=${searchTerm}`
  useEffect(() => {
    const Bitcoin = async () => {
      try {
        const response = await fetch(BitcoinApi)
        if (!response.ok) {
          throw new Error('Failed to fetch data')
        }
        const data = await response.json()
        setBitcon(data.data)
        // console.log('kaju', data.data)
      } catch (error) {
        console.error(error.message)
      }
    }

    Bitcoin()
  }, [])
  // const images = newsData.map(item => item.thumbNail)
  // const content = newsData.map(item => item.articleTitle)
  // ////////////////////////////////////////coin trading////////////////////////////////////////////////////

  // /////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <div className='bg-gray-900 flex flex-col font-inter items-center justify-start mx-auto w-full pb-20'>
        <div className='flex flex-col items-center justify-start w-full'>
          {/* <TopNav /> */}
          <div className='flex md:flex-col flex-row font-raleway gap-[50px] items-start justify-start max-w-[1310px] mt-16 mx-auto md:px-5 w-full'>
            <div className='flex md:flex-1 flex-col gap-8 items-center justify-start w-[64%] md:w-full'>
              <div className='h-[530px] md:h-[518px] relative w-full'>
                <div className='flex flex-col h-full items-center justify-start m-auto w-full'>
                  <div className='flex flex-col gap-3 justify-start -full w-full'>
                    {/* <Slideshow images={images} content={content} /> */}
                    <Newsslide newsDataa={Bitcon} selectedType={selectedType} />
                  </div>
                </div>
              </div>

              <SearchNewsLoad selectedType={selectedType} />
            </div>
            <div className='flex md:flex-1 flex-col gap-8 justify-start w-[33%] md:w-full'>
              <div className='flex flex-col items-center justify-start mr-[52px] w-[89%] md:w-full'>
                <NewspageTrend
                  selectedType={selectedType}
                  onTypeChange={onTypeChange}
                  className='flex flex-col items-start justify-start rounded w-auto sm:w-full'
                />
                <Hometradingcoin />
                {/* <NewsTND /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SearchResults
