import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import ChooseCoin from 'components/ChooseCoin/chooseCoin'
import { Text } from 'components'
import { Input } from 'components/Input'
import { Heading } from 'components/Heading'
import Footer from 'components/Footer'
import MacBookPro14ThirteenColumn from 'components/MacBookPro14ThirteenColumn'
import MacBookPro14ThirteenColumnOne from 'components/MacBookPro14ThirteenColumnOne'
import MacBookPro14TenStackgroupseven from 'components/MacBookPro14TenStackgroupseven'
import TopNav from 'components/topnav'
import Chart from 'chart.js/auto'
import { Helmet } from 'react-helmet'

const Policy = () => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Coinotify || Plicy & Privacy</title>
        <link rel='canonical' href='https://coinotify.io/policy' />
      </Helmet>
      <div
        className={`bg-gray-900 !font-raleway flex flex-col items-center justify-start mx-auto w-full  `}
      >
        <div className='bg-gray-900 flex flex-col fontinter items-center justify-start mxauto w-full max-w-6xl mx-auto'>
          <div className='flex flex-col items-center justify-start w-full'>
            {/* <TopNav /> */}
            <div className='mx-auto mt-[103px] flex w-full max-w-[1329px] flex-col items-start md:p-5'>
              <Heading
                size='10xl'
                as='hl'
                className='text-center !font-raleway !text-[36.72px] !text-amber-600'
              >
                Privacy Policy
              </Heading>

              <div className='mt-7 flex items-start gap-[17px] self-stretch md:flex-col'>
                <Text
                  size='9xl'
                  as='p'
                  className='font-raleway text-[25.72px] leading-[140%] text-white-A700 md:w-full'
                >
                  <span className='font-bold text-white-A700 '>
                    1. Information We Collect:
                    <br />
                  </span>
                  <span className='text-white-A700 text-lg '>
                    <Text className=' !text-amber-600'>
                      User Account Information:
                    </Text>{' '}
                    When you create an account, we collect your email address,
                    username, password. <br />
                    <Text className=' !text-amber-600'>
                      Chat Communication:
                    </Text>
                    If you use the chat feature, we collect the content of your
                    messages and the usernames of participants.
                    <br />
                    <br />
                  </span>
                  <span className='font-bold text-white-A700'>
                    2. How We Use Your Information:
                    <br />
                  </span>
                  <span className='text-white-A700 text-lg'>
                    <Text className=' !text-amber-600'>
                      Respond to your queries and requests:
                    </Text>
                    We use your information to respond to your questions,
                    requests, and feedback. <br />
                    Send you newsletters and updates: With your consent, we may
                    send you newsletters and updates about our Website,
                    services, and related news. <br />
                    <Text className=' !text-amber-600'>
                      Comply with legal requirements:
                    </Text>
                    We may use your information to comply with applicable laws
                    and regulations.
                    <br />
                    <br />
                  </span>
                  <span className='font-bold text-white-A700'>
                    3. Sharing Your Information:
                    <br />
                  </span>
                  <span className='text-white-A700 text-lg'>
                    We do not share your personal information with any
                    third-party service providers or other companies for any
                    purpose. This includes marketing, advertising, or data
                    analytics. We understand the importance of your privacy and
                    are committed to keeping your information secure and
                    confidential However, please note the following exceptions.{' '}
                    <br /> <br />
                    <Text className=' !text-amber-600'>
                      Legal Disclosures:
                    </Text>{' '}
                    We may disclose your information if required by law, such as
                    in response to a subpoena or court order. We may also
                    disclose your information to protect the rights and safety
                    of ourselves and others.
                    <br />
                    <br />
                  </span>
                  <span className='font-bold text-white-A700'>
                    4. Changes to this Privacy Policy:
                    <br />
                  </span>
                  <span className='text-white-A700 text-lg'>
                    We may update this Privacy Policy from time to time. We will
                    notify you of any changes by posting the updated Privacy
                    Policy on the Website. You are advised to review this
                    Privacy Policy periodically
                    <br />
                    <br />
                  </span>
                  <span className='font-bold text-white-A700'>
                    5. Contact Us:
                    <br />
                  </span>
                  <span className='text-white-A700 text-lg'>
                    If you have any questions about this Privacy Policy, please
                    contact us at <br /> coinotify@coinotify.io
                    <br />
                    <br />
                  </span>
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Policy
