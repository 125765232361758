import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import ChooseCoin from 'components/ChooseCoin/chooseCoin'
import { Text } from 'components'
import { Input } from 'components/Input'
import Footer from 'components/Footer'
// import MacBookPro14ThirteenColumn from 'components/MacBookPro14ThirteenColumn'
// import MacBookPro14ThirteenColumnOne from 'components/MacBookPro14ThirteenColumnOne'
// import MacBookPro14TenStackgroupseven from 'components/MacBookPro14TenStackgroupseven'
import TopNav from 'components/topnav'
// import Chart from 'chart.js/auto'
import AuthModal from 'components/AuthModal'
import CoinPieChart from './CoinPieChart'
import { Helmet } from 'react-helmet'
const shapes = { round: 'rounded' }
const variants = {
  fill: {
    yellow_900: 'bg-yellow-900 text-white-A700',
    amber_600: 'bg-amber-600'
  }
}
const sizes = { xs: 'py-2.5', sm: 'p-[13px]' }
const Img = ({
  className,
  src = 'defaultNoData.png',
  alt = 'testImg',
  ...restProps
}) => {
  return (
    <img
      className={className}
      src={src}
      alt={alt}
      {...restProps}
      loading={'lazy'}
    />
  )
}
const Button = ({
  children,
  className = '',
  leftIcon,
  rightIcon,
  shape = '',
  size = '',
  variant = '',
  color = '',
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${(shape && shapes[shape]) || ''} ${
        (size && sizes[size]) || ''
      } ${(variant && variants[variant]?.[color]) || ''}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  )
}
const Line = ({ className, ...restProps }) => {
  return <div className={className} {...restProps} />
}
const Portfolio = () => {
  const navigate = useNavigate()
  const [isVisible, setIsVisible] = useState(false)
  const [isAdd, setIsAdd] = useState(false)
  const [coinData, setCoinData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [coins, setCoins] = useState([])
  const [current, setCurrent] = useState([])
  const [totalprice, setTotalprice] = useState(0)
  const [showAuthModal, setShowAuthModal] = useState(false)
  const toggleVisibility = () => {
    setIsVisible(!isVisible)
  }
  const toggleAdd = () => {
    setIsAdd(!isAdd)
  }
  const handleCloseChooseCoin = () => {
    setIsAdd(false) // Close the ChooseCoin component by setting isAdd to false
    // window.location.reload()
  }
  useEffect(() => {
    fetchCoinData()
  }, [currentPage])

  // const fetchCoinData = async () => {
  //   try {
  //     const response = await fetch(
  //       "http://13.211.82.194:5001/market/coinMarket?page=1&limit=20&type=cryptoAsset"
  //     );
  //     if (response.ok) {
  //       const responseData = await response.json();
  //       console.log("Fetched data:", responseData); // Log the fetched data
  //       setCoinData(responseData.data); // Set coin data to the array of coins
  //     } else {
  //       console.error("Failed to fetch data from the API");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  const fetchCoinData = async () => {
    try {
      const token = localStorage.getItem('token') // Get token from localStorage

      if (token) {
        // console.log('Token exists:', token)
        // Proceed with operations that require the token

        const response = await fetch(
          'https://api.coinotify.io/portfolio/getFolio',
          {
            headers: {
              authorization: `token ${token}`
            }
          }
        )
        if (response.ok) {
          const responseData = await response.json()
          // console.log('Fetched data:', responseData)
          setCoinData(responseData.data)
          // setTotalprice(coinData.currentPortfolioValue)
          // setTotalprice(parseFloat(coinData.currentPortfolioValue.toFixed(4)))
          // setTotalprice(coinData.currentPortfolioValue.toFixed(4))
          const formattedValue = parseFloat(responseData.currentPortfolioValue)
          setTotalprice(formattedValue)
          // console.log(formattedValue)
          // console.log(totalprice)
        } else {
          console.error('Failed to fetch data from the API')
        }
      } else {
        // console.log('No token found.')
        // Handle the absence of a token (e.g., redirect to login page)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }
  useEffect(() => {
    // Check for token in localStorage
    const token = localStorage.getItem('token')
    if (!token) {
      // If token is not found, show the auth modal
      setShowAuthModal(true)
    }
  }, [])
  const handleCloseModal = () => {
    setShowAuthModal(false)
  }
  const chartRefs = useRef([])
  const loadNextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  // useEffect(() => {
  //   if (coinData.length > 0) {
  //     coinData.forEach((coin, index) => {
  //       const canvas = chartRefs.current[index]
  //       // const ctx = canvas.getContext("2d");

  //       // Check if a chart instance exists and destroy it
  //       if (canvas && canvas.chart) {
  //         canvas.chart.destroy()
  //       }
  //     })
  //   }

  //   return () => {
  //     // Clean up function
  //     coinData.forEach((coin, index) => {
  //       const canvas = chartRefs.current[index]
  //       if (canvas.chart) {
  //         canvas.chart.destroy() // Destroy chart instances when the component unmounts
  //         delete canvas.chart // Remove reference to the chart instance
  //       }
  //     })
  //   }
  // }, [coinData])

  const totalCurrentPrice = coinData?.reduce(
    (acc, coin) => acc + coin.currentPrice,
    0
  )

  // Calculate the total PnL
  const totalPnL = coinData?.reduce(
    (acc, coin) => acc + parseFloat(coin.pnl),
    0
  )

  const formattedTotalPnL = totalPnL.toLocaleString('en-US', {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4
  })

  // ///////////////////////////////////////////////////investment

  const [courses, setCourses] = useState([])

  useEffect(() => {
    async function fetchData () {
      try {
        const response = await fetch(
          'https://api.coinotify.io/portfolio/totalInvestment',
          {
            headers: {
              Authorization:
                'token eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NDgsInVzZXJuYW1lIjoicmFodWwxMSIsImVtYWlsIjoicmFodWwxMUBtYWlsaW5hdG9yLmNvbSIsImlhdCI6MTcxNzEzNzA4M30.kzMtyEUs6CPDnuTggful9Yc5NU5Jj3DM4JThbRIgu_A'
            }
          }
        )
        if (response.ok) {
          const data = await response.json()
          setCourses(data.netInvestment) // Update to access the 'data' array
          // console.log(data.netInvestment)
        } else {
          console.error('Failed to fetch courses:', response.statusText)
        }
      } catch (error) {
        console.error('Error fetching courses:', error)
      }
    }
    fetchData()
  }, [])

  // ///////////////////////////////

  // const totalCurrprofilt = totalprice - courses
  // const totalCurrprofiltoercent = ((totalprice - courses) / courses) * 100
  // const formattedTotalCurrprofilt = totalCurrprofilt.toLocaleString()
  // const formattedTotalCurrprofiltoercent =
  //   totalCurrprofiltoercent.toLocaleString()

  const totalCurrprofilt = totalprice - courses
  const totalCurrprofiltoercent = ((totalprice - courses) / courses) * 100

  const formattedTotalCurrprofilt = totalCurrprofilt.toFixed(2)
  const formattedTotalCurrprofiltoercent = totalCurrprofiltoercent.toFixed(2)

  const profitClass = totalCurrprofilt >= 0 ? 'text-green-500' : 'text-red-500'
  const percentClass =
    totalCurrprofiltoercent >= 0
      ? 'text-green-500 flex w-full items-center'
      : 'text-red-500 flex w-full items-center'
  const percentSymbol =
    totalCurrprofiltoercent >= 0 ? (
      <Img className='h-3 w-3' src='images/img_fedropup.svg' alt='fedropup' />
    ) : (
      <img className='h-3 w-3' src='images/img_fedropdown.svg' alt='trend' />
    )
  // //////////////////////////////////////////
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Coinotify || Portfolio</title>
        <link rel='canonical' href='https://coinotify.io/portfolio' />
        <meta
          name='description'
          content='Personal portfolio for your coins and amount '
        />
      </Helmet>

      <div
        className={`bg-gray-900 flex flex-col font-inter items-center justify-start mx-auto w-full `}
      >
        <div className='bg-gray-900 flex flex-col font-inter items-center justify-start mx-auto w-full'>
          <div className='flex flex-col items-center justify-start w-full'>
            {/* <TopNav /> */}
            <div className='bg-gray-900 flex flex-col font-raleway h-[57px] md:h-auto items-center justify-end md:ml-[0] ml-[69px] mt-[38px] md:px-5 px-6 py-[18px] w-[159px]'></div>
            <div className='flex md:flex-wrap flex-row md:gap-5 items-center md:items-start justify-start max-w-[1349px] mt-[78px] mx-auto md:px-5 w-full'>
              <div className='bg-yellow-900 flex flex-col gap-[13px] items-start justify-start p-[11px] rounded-lg h-[115px]'>
                <Text
                  className='ml-3 md:ml-[0] text-[15px] text-white-A700 '
                  size='txtRalewaySemiBold15'
                >
                  Total Balance
                </Text>
                <Text
                  className='ml-3 md:ml-[0] text-2xl md:text-[22px] text-white-A700 sm:text-xl'
                  size='txtAssistantRomanRegular24'
                >
                  ${totalprice.toFixed(2)}
                  {/* ${formattedTotalCurrentPrice} */}
                </Text>
              </div>
              <div className='bg-gray-900_01 flex flex-col gap-[17px] items-start justify-start md:ml-[0] ml-[15px] p-[7px] rounded-lg w-[500px] md:w-1/2'>
                <Text
                  className='text-[15px] text-gray-400'
                  size='txtRalewaySemiBold15Gray400'
                >
                  Total Profit / Loss
                </Text>
                {/* <Text
                className='mb-1 text-2xl md:text-[22px] text-gray-400 sm:text-xl'
                size='txtAssistantRomanRegular24Gray400'
              >
                ${formattedTotalCurrprofilt}
                <br />
                {formattedTotalCurrprofiltoercent}%
              </Text> */}
                <Text
                  className={`mb-1 text-2xl md:text-[22px] text-gray-400 sm:text-xl ${profitClass}`}
                  size='txtAssistantRomanRegular24Gray400'
                >
                  ${formattedTotalCurrprofilt}
                  <br />
                  <span className={percentClass}>
                    {percentSymbol} {formattedTotalCurrprofiltoercent}%
                  </span>
                </Text>
              </div>
              <div className='common-pointer bg-gray-900_01 flex md:flex-1 flex-col items-center justify-start md:ml-[0] ml-[849px] p-[13px] rounded-lg w-[5%] md:w-1/2'>
                <div>
                  <button onClick={toggleVisibility}>
                    <img
                      className='h-10 my-2.5 w-10'
                      src='images/img_contrast.svg'
                      alt='contrast'
                    />
                  </button>

                  {isVisible && (
                    <div className=' absolute inset-0 flex sm:flex-col flex-row font-assistant z-[99999999999999999999999] sm:gap-10 gap-[145px] items-center justify-center md:ml-[0] w-[100%] md:w-full bg-gray-900_01'>
                      {/* <MacBookPro14TenStackgroupseven /> */}
                      <CoinPieChart coinData={coinData} />
                      <button
                        className='absolute right-10 top-10'
                        onClick={toggleVisibility}
                      >
                        <img
                          className='h-10 my-2.5 w-10'
                          src='images/close.png'
                          alt='contrast'
                        />
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div>
                <button onClick={toggleAdd}>
                  <img
                    className='h-[87px] md:ml-[0] ml-[21px]'
                    src='images/img_floatingicon.svg'
                    alt='floatingicon'
                  />
                </button>
                {isAdd && (
                  <div className='flex sm:flex-col flex-row font-assistant sm:gap-10 gap-[145px] items-center justify-start md:ml-[0] ml-[47px] w-[77%] md:w-full z-[999999999999999]'>
                    <ChooseCoin onClose={handleCloseChooseCoin} />
                  </div>
                )}
              </div>
            </div>
            <div className='h-[1135px] md:h-[632px] max-w-[1362px] mt-[39px] mx-auto md:px-5 relative w-full overflow-x-auto'>
              <table className='w-full border-collapse bg-navyBlue-800 text-gray-100'>
                <thead>
                  <tr className='bg-navyBlue-700'>
                    <th className='p-2 font-bold text-left'>#</th>
                    <th className='p-2 font-bold text-left'>Name</th>
                    <th className='p-2 font-bold text-center'>Symbol</th>
                    <th className='p-2 font-bold text-right'>Price</th>
                    <th className='p-2 font-bold text-right'>Holdings</th>
                    <th className='p-2 font-bold text-right'>Avg. Buy Price</th>
                    <th className='p-2 font-bold text-right'>PNL</th>
                  </tr>
                </thead>
                <tbody>
                  {coinData &&
                    coinData.map((coin, index) => (
                      <tr
                        key={index}
                        className={`transition-all ${
                          index % 2 === 0
                            ? 'bg-navyBlue-900'
                            : 'bg-navyBlue-800'
                        } hover:bg-gray-700`}
                      >
                        <td>{index + 1}</td>
                        <td className=' p-2 flex items-center'>{coin.name}</td>
                        <td className='text-center'>{coin.symbol}</td>
                        <td className='text-right'>${coin.currentPrice}</td>
                        <td className='text-right'>{coin.quantity}</td>
                        <td className='text-right'>${coin.avgBuyPrice}</td>
                        <td
                          className={`p-2 text-right md:flex ${
                            coin.pnl < 0 ? 'text-red-500' : 'text-green-500'
                          }`}
                        >
                          ${coin.pnl < 0 ? -coin.pnl : coin.pnl}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            {/* <Footer className='bg-blue_gray-900_01 flex items-center justify-center mt-[546px] md:px-5 w-full' /> */}
          </div>
        </div>
        {/* <AuthModal isOpen={showAuthModal} onClose={handleCloseModal} /> */}
      </div>
    </>
  )
}

export default Portfolio
