import React, { useState, useEffect } from 'react'
import { Img, Text } from 'components'

const MacBookPro14FourMorenewsdark = props => {
  const [newsData, setNewsData] = useState([])
  const apiUrl =
    'http://3.107.48.120:5001/news/allNews?page=1&limit=10&type=allCoin'

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl)
        if (!response.ok) {
          throw new Error('Failed to fetch data')
        }
        const data = await response.json()
        setNewsData(data.data)
        // console.log(data.data);
      } catch (error) {
        console.error(error.message)
      }
    }

    fetchData()
  }, [])

  return (
    <>
      {newsData &&
        newsData.map((item, index) => (
          <div key={index} className={props.className}>
            <div className='flex flex-col gap-2.5 items-end justify-start w-full'>
              <div className='flex flex-row gap-3 items-center justify-between w-full'>
                <div className='flex flex-col h-[120px] md:h-auto items-start justify-start w-[140px]'>
                  <Img
                    className='md:h-auto h-full object-cover rounded-bl rounded-br w-full'
                    src={item.thumbNail}
                    alt='image'
                  />
                </div>
                <div className='flex flex-1 flex-col gap-[18px] h-full items-start justify-between w-full'>
                  <Text
                    className='leading-[130.00%] max-w-[224px] md:max-w-full text-base text-white-A700'
                    size='txtLoraRomanMedium16'
                  >
                    {item.articleTitle}
                  </Text>
                  <Text
                    className='max-w-[224px] md:max-w-full text-white-A700 text-xs'
                    size='txtLoraRomanMedium12'
                  >
                    {item.articleSummary}
                  </Text>
                  <div className='flex flex-row gap-2.5 items-start justify-between w-full'>
                    <Text
                      className='flex-1 text-white-A700_b2 text-xs underline w-auto'
                      size='txtAssistantRomanSemiBold12WhiteA700b2'
                    >
                      {new URL(item.articleUrl).hostname}
                    </Text>
                    <Text
                      className='text-black-900_b2 text-xs w-auto'
                      size='txtRobotoRomanRegular12'
                    >
                      {new Date(item.articleTimestamp).toLocaleString()}
                    </Text>
                  </div>
                </div>
              </div>
              <div className='flex flex-col h-5 items-center justify-start w-5'>
                <Img
                  className='h-5 w-5'
                  src='images/img_bookmark.svg'
                  alt='bookmark'
                />
              </div>
            </div>
          </div>
        ))}
    </>
  )
}

export default MacBookPro14FourMorenewsdark
