import { AuthContext } from 'Context/AuthContext'
import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
// import { AuthContext } from './AuthContext'

const PrivateRoute = ({ element, ...rest }) => {
  // const { user } = useContext(AuthContext)
  const user = localStorage.getItem('token')

  return user ? element : <Navigate to='/login' />
  //   return user ? element : <div className='mt-20'>Please log in first to see the portfolio.</div>
}

export default PrivateRoute
