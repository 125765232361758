// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lists {
  display: none !important;
}
.lists.active {
  display: block !important;
  position: fixed;
  z-index: 999999999999999999999999999999999;
  background: #062b43;
  /* background: rgba(248, 251, 248, 0.9);  */
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  /* left: 0px; */
  right: 0;
  top: 0px;
  width: 50%;
  padding: 0px 0 40px 0;
  /* // color: alpha(\$color: #000000); */
  /* height: 100vh; */
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/topnav/head.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;AACA;EACE,yBAAyB;EACzB,eAAe;EACf,0CAA0C;EAC1C,mBAAmB;EACnB,2CAA2C;EAC3C,0BAA0B;EAC1B,kCAAkC;EAClC,eAAe;EACf,QAAQ;EACR,QAAQ;EACR,UAAU;EACV,qBAAqB;EACrB,sCAAsC;EACtC,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".lists {\n  display: none !important;\n}\n.lists.active {\n  display: block !important;\n  position: fixed;\n  z-index: 999999999999999999999999999999999;\n  background: #062b43;\n  /* background: rgba(248, 251, 248, 0.9);  */\n  backdrop-filter: blur(5px);\n  -webkit-backdrop-filter: blur(5px);\n  /* left: 0px; */\n  right: 0;\n  top: 0px;\n  width: 50%;\n  padding: 0px 0 40px 0;\n  /* // color: alpha($color: #000000); */\n  /* height: 100vh; */\n  overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
