import React, { createContext, useState, useEffect } from 'react'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const token = localStorage.getItem('token')
    // console.log('Token from localStorage:', token)

    if (token) {
      fetch('https://api.coinotify.io/user/verify-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Token verification failed')
          }
          return response.json()
        })
        .then(data => {
          // console.log('Response data:', data)

          if (data.user) {
            setUser(data.user)
            // console.log('User set:', data.user)
          } else {
            console.warn('Token verification failed, no user data returned')
          }
        })
        .catch(error => {
          console.error('Error verifying token:', error)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [])

  const login = userData => {
    setUser(userData)
    localStorage.setItem('token', userData.token)
    // console.log('User logged in:', userData)
  }

  const logout = () => {
    setUser(null)
    localStorage.removeItem('token')
    window.location.reload()
    // console.log('User logged out')
  }

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}
