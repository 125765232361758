import React, { useState } from 'react'
import { Button } from 'components'
import { Input } from 'components/Input'
import { useNavigate } from 'react-router-dom'

const Line = ({ className, ...restProps }) => {
  return <div className={className} {...restProps} />
}

const AddFolio = ({ onClose, selectedCoin }) => {
  const getTodayDate = () => {
    const today = new Date()
    const yyyy = today.getFullYear()
    const mm = String(today.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
    const dd = String(today.getDate()).padStart(2, '0')
    return `${yyyy}-${mm}-${dd}`
  }
  const [formData, setFormData] = useState({
    name: selectedCoin.name,
    symbol: selectedCoin.symbol,
    quantity: '',
    buyPrice: '',
    currency: 'USD',
    buyDate: getTodayDate()
  })
  const handleInputChange = event => {
    const { name, value } = event.target || event
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [error, setError] = useState('')

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token')

      if (!token) {
        // console.log('No token found.')
        // Redirect to login page or show an error message
        return
      }

      const response = await fetch(
        'https://api.coinotify.io/portfolio/addTransaction',
        // 13.202.111.176
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Specify the content type
            Authorization: `Bearer ${token}` // Use the correct auth header format
          },
          body: JSON.stringify({
            pCoin: {
              name: formData.name,
              symbol: formData.symbol,
              quantity: formData.quantity,
              buyPrice: formData.buyPrice,
              currency: formData.currency,
              buyDate: formData.buyDate
            }
          })
        }
      )

      if (!response.ok) {
        // If the response is not 2xx, it is considered an error
        const errorData = await response.json() // Assuming the server responds with JSON
        console.error('Error adding transaction:', errorData)
        // alert(`Error: ${errorData.message}`) // Display the error message from the server
        setError(`${errorData.message}`)
        return
      }

      const responseData = await response.json()
      // console.log('Transaction added successfully:', responseData)
      setShowSuccessModal(true)
      setTimeout(() => {
        navigate('/portfolio')
        // window.location.reload()
        setShowSuccessModal(false)
        onClose()
      }, 2000)
      // alert('Transaction submitted successfully')
    } catch (error) {
      console.error('Error adding transaction:', error)
    }
  }

  const handleClose = () => {
    onClose()
  }

  const navigate = useNavigate()
  const handleCloseModal = () => {
    setShowSuccessModal(false)
    navigate('/portfolio')
    onClose()
  }

  const handleCloseError = () => {
    setShowSuccessModal(false)
  }
  return (
    <>
      <div className='absolute z-[9999] bg-[#374151] p-[11px] w-[90%] rounded-[5px]'>
        <div className='flex flex-col gap-[11px] justify-start mb-[35px] mt-20 w-[99%] md:w-full md:mt-1'>
          <div className='flex flex-col gap-[17px] items-start justify-start ml-2.5 md:ml-[0] w-[86%] md:w-full'>
            <button onClick={handleClose} className='common-pointer'>
              <img
                className='h-[38px] w-[38px]'
                src='images/close.png'
                alt='close_Two'
              />
            </button>
            <span className='text-[22px] sm:text-lg text-white-A700 md:text-xl'>
              Add Transaction to My Portfolio
            </span>
            <div className='flex flex-row items-start justify-end md:ml-[0] ml-[63px] w-[86%] md:w-full'>
              <span className='mt-0.5 text-sm text-white-A700'>Buy</span>
              <span className='ml-[138px] text-sm text-white-A700'>Sell</span>
              <span className='mb-0.5 ml-[116px] text-sm text-white-A700'>
                Transfer
              </span>
            </div>
          </div>
          <div className='flex flex-col gap-[27px] items-center justify-start w-full'>
            <div className='h-px relative w-full'>
              <Line className='bg-gray-700_01 h-px m-auto w-full' />
              <Line className='absolute bg-amber-600 h-px inset-y-[0] left-[0] my-auto w-[30%]' />
            </div>
            <div className='flex flex-col items-center justify-start w-[98%] md:w-full'>
              <div className='flex flex-col items-start justify-start w-full'>
                <span className='text-[13px] text-white-A700'>
                  <span className='text-white-A700 font-raleway text-left font-extrabold'>
                    Price Per Coin{' '}
                  </span>
                  <span className='text-white-A700 font-raleway text-left font-extrabold'>
                    *
                  </span>
                </span>
                <div className='bg-white flex flex-row items-center justify-between mt-[7px] p-1 rounded-[5px] w-full'>
                  <select
                    name='currency'
                    value={formData.currency}
                    onChange={handleInputChange}
                    className='text-black text-sm'
                  >
                    <option value='USD'>USD</option>
                    <option value='INR'>INR</option>
                  </select>
                  <input
                    type='number'
                    name='buyPrice'
                    className='text-black text-sm bg-white w-full border border-gray-300 rounded p-2'
                    placeholder='Enter price per coin'
                    value={formData.buyPrice}
                    onChange={handleInputChange}
                  />
                </div>
                <span className='mt-2 text-[13px] text-whiteA700'>
                  <span className='text-whiteA700 font-raleway text-left font-extrabold'>
                    Quantity{' '}
                  </span>
                  <span className='text-white-A700 font-raleway text-left font-extrabold'>
                    *
                  </span>
                </span>

                <input
                  type='number'
                  name='quantity'
                  value={formData.quantity}
                  onChange={handleInputChange}
                  className='text-black text-sm bg-white w-full border border-gray-300 rounded p-2'
                  placeholder='Enter quantity'
                />

                <input
                  type='date'
                  name='buyDate'
                  value={formData.buyDate}
                  onChange={handleInputChange}
                  placeholder='27-01-2024'
                  className='text-black leading-[normal] p0 placeholder:text-gray-400_01 text-left text-sm w-full bg-white border border-gray-300 rounded p-2 mt-3'
                  wrapClassName='mt-[7px] rounded-[5px] w-full'
                  color='white_A700'
                  size='sm'
                  variant='fill'
                />
                <span className='mt-[7px] text-[13px] textwhite-A700'>
                  Note
                </span>

                <input
                  type='text'
                  name='note'
                  // value={formData.note}
                  // onChange={handleChange}
                  placeholder='Enter note'
                  className='text-sm bg-white w-full border border-gray-300 rounded p-2'
                />
                <div className='flex sm:flex-col flex-row sm:gap-10 items-center justify-between ml-1 md:ml-[0] mt-[23px] w-[99%] md:w-full'>
                  <div className='flex flex-row items-center justify-start w-2/5 sm:w-full'>
                    <Button
                      onClick={handleSubmit}
                      className='cursor-pointer leading-[normal] min-w-[160px] text-center text-sm bg-amber-600 text-white'
                      shape='round'
                      size='sm'
                      variant='fill'
                    >
                      Submit
                    </Button>
                  </div>
                  <div className='flex flex-row items-center justify-start w-[45%] sm:w-full'>
                    <Button
                      className='cursor-pointer leading-[normal] min-w-[160px] text-center text-sm bg-gray-500 text-white'
                      shape='round'
                      size='sm'
                      variant='fill'
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='fixed bottom-28 w[500px] right-4 z-50 max-w-xs bg-red-500 text-white p-4 rounded-lg shadow-lg transform transition-transform duration-500 ease-in-out translate-x-full'
        style={{ transform: error ? 'translateX(0)' : 'translateX(130%)' }}
      >
        <div className='flex justify-between items-center'>
          <span>{error}</span>
          <button onClick={handleCloseError} className='ml-4 text-xl'>
            &times;
          </button>
        </div>
      </div>

      {showSuccessModal && (
        <div
          className='fixed top-40 right-4 w[500px] z-50 max-w-xs bg-[#15ff21] text-white p-4 rounded-lg shadow-lg transform transition-transform duration-500 ease-in-out translate-x-full'
          style={{
            transform: showSuccessModal ? 'translateX(0)' : 'translateX(130%)'
          }}
        >
          <div className='flex justify-between items-center'>
            <p className='mb-6'>"Transaction added successfully!"</p>
            <button onClick={handleCloseError} className='ml-4 text-xl'>
              &times;
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default AddFolio
