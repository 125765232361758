import React, { useState } from 'react'

const ProfileUpdateForm = () => {
  const [displayName, setDisplayName] = useState('')
  const [username, setUsername] = useState('')
  const [Phone, setPhone] = useState('')
  const [bio, setBio] = useState('')
  const [birthday, setBirthday] = useState('')
  const [website, setWebsite] = useState('')

  const handleSave = () => {
    // Handle save logic here
    // console.log('Profile saved:', {
    //   displayName,
    //   username,
    //   bio,
    //   birthday,
    //   website
    // })
  }

  return (
    <div className='max-w-xl mxauto p-4'>
      <h2 className='text-2xl text-left font-bold mb-4'>Profile</h2>
      {/* <h3 className='text-xl mb-4'>About me</h3> */}
      {/* <div className='flex items-center mb-4'>
        <img
          src='https://via.placeholder.com/40'
          alt='Avatar'
          className='h-10 w-10 rounded-full'
        />
        <button className='ml-4 px-4 py-2 bg-blue-600 text-white rounded'>
          Edit
        </button>
        <button className='ml-2 px-4 py-2 text-blue-600 rounded'>
          Get Avatar Frame
        </button>
      </div> */}
      <div className='mb-4'>
        <label className='block text-gray-700'>Display name</label>
        <input
          type='text'
          onChange={e => setDisplayName(e.target.value)}
          className='w-full px-3 py-2 border rounded'
          maxLength={20}
        />
        <span className='text-gray-500'>{displayName.length}/20</span>
      </div>
      <div className='mb-4'>
        <label className='block text-gray-700'>Username</label>
        <input
          type='text'
          onChange={e => setUsername(e.target.value)}
          className='w-full px-3 py-2 border rounded'
          maxLength={20}
        />
        <span className='text-gray-500'>{username.length}/20</span>
        <p className='text-sm text-gray-500'>
          * Username can only be changed once per 7 days
        </p>
      </div>
      <div className='mb-4'>
        <label className='block text-gray-700'>Mobile number</label>
        <input
          type='text'
          onChange={e => setPhone(e.target.value)}
          className='w-full px-3 py-2 border rounded'
          maxLength={10}
          placeholder=''
        />
        <span className='text-gray-500'>{Phone.length}/20</span>
        {/* <p className='text-sm text-gray-500'>
          * Username can only be changed once per 7 days
        </p> */}
      </div>
      <div className='mb-4'>
        <label className='block text-gray-700'>Bio</label>
        <textarea
          value={bio}
          onChange={e => setBio(e.target.value)}
          className='w-full px-3 py-2 border rounded'
          maxLength={250}
          placeholder='A brief introduction about yourself'
        />
        <span className='text-gray-500'>{bio.length}/250</span>
      </div>
      <div className='mb-4'>
        <label className='block text-gray-700'>Birthday</label>
        <input
          type='date'
          value={birthday}
          onChange={e => setBirthday(e.target.value)}
          className='w-full px-3 py-2 border rounded'
        />
      </div>
      <div className='mb-4'>
        <label className='block text-gray-700'>Website</label>
        <input
          type='text'
          value={website}
          onChange={e => setWebsite(e.target.value)}
          className='w-full px-3 py-2 border rounded'
          maxLength={100}
          placeholder='Add your website'
        />
        <span className='text-gray-500'>{website.length}/100</span>
      </div>
      <button
        onClick={handleSave}
        className='px-4 py-2 bg-blue-600 text-white rounded'
      >
        Save
      </button>
    </div>
  )
}

export default ProfileUpdateForm
