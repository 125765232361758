// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root { 
--black_900_7f:#0c0c0c7f; 
--white_A700_b2:#ffffffb2; 
--black_900_3d:#0000003d; 
--gray_900_af:#111111af; 
--black_900_b2:#09081fb2; 
--white_A700_1e:#ffffff1e; 
--light_blue_A100:#71d0ff; 
--red_300:#f17171; 
--indigo_A200:#627eea; 
--light_green_400:#a2d471; 
--black_900_3f:#0000003f; 
--green_A200:#66f9a1; 
--gray_50:#fcfcfc; 
--black_900_1e:#0000001e; 
--green_400:#5ded63; 
--black_900_42:#06060642; 
--white_A700_cc:#ffffffcc; 
--black_900_01:#00000001; 
--black_900_23:#03030323; 
--black_900_00:#00000000; 
--gray_900_c8:#141414c8; 
--gray_900_e3:#171717e3; 
--yellow_700:#f3ba2f; 
--yellow_900:#f98121; 
--blue_gray_900_02:#23292f; 
--blue_gray_900_01:#243345; 
--blue_gray_900:#353535; 
--black_900_69:#0a0a0a69; 
--black_900_05:#00000005; 
--blue_gray_200:#b4b7c1; 
--amber_600:#fab605; 
--gray_800:#4e4e4e; 
--gray_900:#151e29; 
--amber_700:#fba512; 
--gray_900_01:#1a1a1a; 
--red_300_01:#ee6d6d; 
--yellow_A700:#ffda05; 
--gray_300:#e3e3e1; 
--orange_300:#ffc44d; 
--gray_100:#f2f2f2; 
--black_900_0d:#0101010d; 
--black_900_54:#08080854; 
--black_900_31:#05050531; 
--indigo_100:#c6cbff; 
--black_900_96:#0f0f0f96; 
--black_900_17:#02020217; 
--white_A700:#ffffff; 
}`, "",{"version":3,"sources":["webpack://./src/styles/color.css"],"names":[],"mappings":"AAAA;AACA,wBAAwB;AACxB,yBAAyB;AACzB,wBAAwB;AACxB,uBAAuB;AACvB,wBAAwB;AACxB,yBAAyB;AACzB,yBAAyB;AACzB,iBAAiB;AACjB,qBAAqB;AACrB,yBAAyB;AACzB,wBAAwB;AACxB,oBAAoB;AACpB,iBAAiB;AACjB,wBAAwB;AACxB,mBAAmB;AACnB,wBAAwB;AACxB,yBAAyB;AACzB,wBAAwB;AACxB,wBAAwB;AACxB,wBAAwB;AACxB,uBAAuB;AACvB,uBAAuB;AACvB,oBAAoB;AACpB,oBAAoB;AACpB,0BAA0B;AAC1B,0BAA0B;AAC1B,uBAAuB;AACvB,wBAAwB;AACxB,wBAAwB;AACxB,uBAAuB;AACvB,mBAAmB;AACnB,kBAAkB;AAClB,kBAAkB;AAClB,mBAAmB;AACnB,qBAAqB;AACrB,oBAAoB;AACpB,qBAAqB;AACrB,kBAAkB;AAClB,oBAAoB;AACpB,kBAAkB;AAClB,wBAAwB;AACxB,wBAAwB;AACxB,wBAAwB;AACxB,oBAAoB;AACpB,wBAAwB;AACxB,wBAAwB;AACxB,oBAAoB;AACpB","sourcesContent":[":root { \n--black_900_7f:#0c0c0c7f; \n--white_A700_b2:#ffffffb2; \n--black_900_3d:#0000003d; \n--gray_900_af:#111111af; \n--black_900_b2:#09081fb2; \n--white_A700_1e:#ffffff1e; \n--light_blue_A100:#71d0ff; \n--red_300:#f17171; \n--indigo_A200:#627eea; \n--light_green_400:#a2d471; \n--black_900_3f:#0000003f; \n--green_A200:#66f9a1; \n--gray_50:#fcfcfc; \n--black_900_1e:#0000001e; \n--green_400:#5ded63; \n--black_900_42:#06060642; \n--white_A700_cc:#ffffffcc; \n--black_900_01:#00000001; \n--black_900_23:#03030323; \n--black_900_00:#00000000; \n--gray_900_c8:#141414c8; \n--gray_900_e3:#171717e3; \n--yellow_700:#f3ba2f; \n--yellow_900:#f98121; \n--blue_gray_900_02:#23292f; \n--blue_gray_900_01:#243345; \n--blue_gray_900:#353535; \n--black_900_69:#0a0a0a69; \n--black_900_05:#00000005; \n--blue_gray_200:#b4b7c1; \n--amber_600:#fab605; \n--gray_800:#4e4e4e; \n--gray_900:#151e29; \n--amber_700:#fba512; \n--gray_900_01:#1a1a1a; \n--red_300_01:#ee6d6d; \n--yellow_A700:#ffda05; \n--gray_300:#e3e3e1; \n--orange_300:#ffc44d; \n--gray_100:#f2f2f2; \n--black_900_0d:#0101010d; \n--black_900_54:#08080854; \n--black_900_31:#05050531; \n--indigo_100:#c6cbff; \n--black_900_96:#0f0f0f96; \n--black_900_17:#02020217; \n--white_A700:#ffffff; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
