import React, { useState, useEffect } from 'react'

import MacBookPro14FourTrendingdark from 'components/MacBookPro14FourTrendingdark'
import Slideshow from './Slideshow'
import './Sider.css'

import Hometradingcoin from 'componentsbyaman/hometradingcoins/Hometradingcoin'

import NewsLoad from 'componentsbyaman/allnewlaoding/NewsLoad'

import { Helmet } from 'react-helmet'

const MacBookPro14FourPage = ({
  selectedType,
  onTypeChange,
  onLangChange,
  selectedlang
}) => {
  const [newsData, setNewsData] = useState([])
  let apiUrl = ''
  if (selectedType == 'bitcoin') {
    // console.log('bitcoin')
    apiUrl = `https://api.coinotify.io/news/getUpdatesByCoin?page=1&limit=10&type=${selectedType}`
  } else {
    apiUrl = `https://api.coinotify.io/news/allNews?page=1&limit=20&type=${selectedType}`
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl)
        if (!response.ok) {
          throw new Error('Failed to fetch data')
        }
        const data = await response.json()
        setNewsData(data.data)
        // console.log(data.data)
      } catch (error) {
        console.error(error.message)
      }
    }

    fetchData()
  }, [apiUrl])

  // ////////////////////////////////////////coin trading////////////////////////////////////////////////////

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Coinotify</title>
        <link rel='canonical' href='https://coinotify.io' />
        <meta
          name='description'
          content='Coinotify covers Bitcoin, Altcoins, DeFi, GameFi, NFTs, fundamental and technical analysis, airdrops, portfolio management, and much more.'
        />
      </Helmet>
      <div className='bg-gray-900 flex flex-col !font-lora !font-normal items-center justify-start mx-auto w-full pb-20'>
        <div className='flex flex-col items-center justify-start w-full'>
          <div className='flex md:flex-col flex-row font-raleway gap-[50px] items-start justify-start max-w-[1310px] mt-16 mx-auto md:px-5 w-full'>
            <div className='flex md:flex-1 flex-col gap-8 items-center justify-start w-[64%] md:w-full'>
              <div className='h-[530px] md:h-[518px] relative w-full'>
                <div className='flex flex-col h-full items-center justify-start m-auto w-full'>
                  <div className='flex flex-col gap-3 justify-start -full w-full'>
                    <Slideshow
                      selectedType={selectedType}
                      newsDataa={newsData}
                    />
                  </div>
                </div>
              </div>

              <NewsLoad
                selectedType={selectedType}
                selectedlang={selectedlang}
              />
            </div>
            <div className='flex md:flex-1 flex-col gap-8 justify-start w-[33%] md:w-full'>
              <div className='flex flex-col items-center justify-start mr-[52px] w-[89%] md:w-full'>
                <MacBookPro14FourTrendingdark
                  selectedType={selectedType}
                  onTypeChange={onTypeChange}
                  onLangChange={onLangChange}
                  className='flex flex-col items-start justify-start rounded w-auto sm:w-full'
                />

                <Hometradingcoin />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MacBookPro14FourPage
