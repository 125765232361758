// import React, { useState } from "react";
// import Login from "pages/Login";
// import SignupPage from "pages/Signup";

// const AuthModal = ({ isOpen, onClose }) => {
//   const [activeTab, setActiveTab] = useState("login");

//   // Styles for the modal background
//   const modalBgStyle = {
//     position: "fixed",
//     top: 0,
//     left: 0,
//     width: "100%",
//     height: "100%",
//     backgroundColor: "rgba(0, 0, 0, 0.75)",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     zIndex: 50,
//   };

//   // Styles for the modal container
//   const modalContainerStyle = {
//     backgroundColor: "#2D3748", // Tailwind gray-800
//     padding: "20px",
//     width: "90%",
//     maxWidth: "500px",
//     borderRadius: "0.5rem", // Tailwind rounded-md
//   };

//   // Conditional rendering based on whether the modal is open or not
//   if (!isOpen) {
//     return null;
//   }

//   return (
//     <div style={modalBgStyle} onClick={onClose}>
//       <div style={modalContainerStyle} onClick={(e) => e.stopPropagation()}>
//         <div className="flex flex-row justify-center mb-4">
//           <button
//             className={`px-4 py-2 text-white font-semibold ${
//               activeTab === "login" ? "bg-blue-500" : "bg-gray-700"
//             } rounded-tl-md`}
//             onClick={() => setActiveTab("login")}
//           >
//             Login
//           </button>
//           <button
//             className={`px-4 py-2 text-white font-semibold ${
//               activeTab === "signup" ? "bg-blue-500" : "bg-gray-700"
//             } rounded-tr-md`}
//             onClick={() => setActiveTab("signup")}
//           >
//             Sign Up
//           </button>
//         </div>
//         <div>{activeTab === "login" ? <Login /> : <SignupPage />}</div>
//         <div className="text-right mt-4">
//           <button
//             className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
//             onClick={onClose}
//           >
//             Close
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AuthModal;
import React, { useState } from 'react'

const AuthModal = ({ isOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState('login')
  const [loginEmail, setLoginEmail] = useState('')
  const [loginPassword, setLoginPassword] = useState('')
  const [signupUsername, setSignupUsername] = useState('')
  const [signupEmail, setSignupEmail] = useState('')
  const [signupPassword, setSignupPassword] = useState('')

  if (!isOpen) {
    return null
  }

  const handleLogin = async event => {
    event.preventDefault()
    try {
      const response = await fetch('https://api.coinotify.io/user/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },

        body: JSON.stringify({
          user: {
            email: loginEmail,
            password: loginPassword
          }
        })
      })
      if (response.ok) {
        window.location.reload()
        const data = await response.json()
        localStorage.setItem('token', data.user.token) // Assuming the token is in data.user.token
        onClose() // Close the modal on successful login
      } else {
        console.error('Login failed')
        // Here you might want to set an error state and show an error message
      }
    } catch (error) {
      console.error('Login error:', error)
      // Here you might want to set an error state and show an error message
    }
  }

  const handleSignup = async event => {
    event.preventDefault()
    try {
      const response = await fetch('http://13.211.82.194:5001/user/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user: {
            username: signupUsername,
            email: signupEmail,
            password: signupPassword
          }
        })
      })
      if (response.ok) {
        window.location.reload()
        const data = await response.json()
        localStorage.setItem('token', data.user.token) // Assuming the token is in data.user.token
        onClose() // Close the modal on successful signup
      } else {
        console.error('Signup failed')
        // Here you might want to set an error state and show an error message
      }
    } catch (error) {
      console.error('Signup error:', error)
      // Here you might want to set an error state and show an error message
    }
  }

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center p-4'>
      <div className='bg-transparent max-w-md w-full'>
        <div className='overflow-hidden rounded-lg shadow-lg'>
          <div className='bg-gray-800 p-6'>
            <div className='flex justify-center space-x-4 mb-6'>
              <button
                className={`px-6 py-2 text-lg font-semibold text-white ${
                  activeTab === 'login' ? 'bg-blue-500' : 'bg-gray-700'
                } rounded-md`}
                onClick={() => setActiveTab('login')}
              >
                Login
              </button>
              <button
                className={`px-6 py-2 text-lg font-semibold text-white ${
                  activeTab === 'signup' ? 'bg-blue-500' : 'bg-gray-700'
                } rounded-md`}
                onClick={() => setActiveTab('signup')}
              >
                Sign Up
              </button>
            </div>

            {activeTab === 'login' ? (
              <form onSubmit={handleLogin} className='space-y-4'>
                <input
                  type='email'
                  placeholder='Email'
                  value={loginEmail}
                  onChange={e => setLoginEmail(e.target.value)}
                  className='w-full p-2 text-gray-700 bg-white border rounded-md'
                />
                <input
                  type='password'
                  placeholder='Password'
                  value={loginPassword}
                  onChange={e => setLoginPassword(e.target.value)}
                  className='w-full p-2 text-gray-700 bg-white border rounded-md'
                />
                <button
                  type='submit'
                  className='w-full p-2 bg-orange-500 text-white rounded-md'
                >
                  Sign In
                </button>
              </form>
            ) : (
              <form onSubmit={handleSignup} className='space-y-4'>
                <input
                  type='text'
                  placeholder='Username'
                  value={signupUsername}
                  onChange={e => setSignupUsername(e.target.value)}
                  className='w-full p-2 text-gray-700 bg-white border rounded-md'
                />
                <input
                  type='email'
                  placeholder='Email'
                  value={signupEmail}
                  onChange={e => setSignupEmail(e.target.value)}
                  className='w-full p-2 text-gray-700 bg-white border rounded-md'
                />
                <input
                  type='password'
                  placeholder='Password'
                  value={signupPassword}
                  onChange={e => setSignupPassword(e.target.value)}
                  className='w-full p-2 text-gray-700 bg-white border rounded-md'
                />
                <button
                  type='submit'
                  className='w-full p-2 bg-orange-500 text-white rounded-md'
                >
                  Create Account
                </button>
              </form>
            )}

            <div className='text-right mt-4'>
              <button
                className='text-sm text-gray-300 hover:text-gray-400'
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthModal
