import React from 'react'

const sizeClasses = {
  txtAssistantRomanRegular12: 'font-assistant font-normal',
  txtRalewayMedium14: 'font-medium font-raleway',
  txtRalewayMedium12: 'font-medium font-raleway',
  txtRalewaySemiBold14: 'font-raleway font-semibold',
  txtAssistantRomanSemiBold12WhiteA700cc: 'font-assistant font-semibold',
  txtRalewaySemiBold16: 'font-raleway font-semibold',
  txtLoraRomanRegular16: 'font-lora font-normal',
  txtRalewaySemiBold16Gray50: 'font-raleway font-semibold',
  txtLoraRomanSemiBold20: 'font-lora font-semibold',
  txtLoraRomanSemiBold42: 'font-lora font-semibold',
  txtLoraRomanMedium16: 'font-lora font-medium',
  txtAssistantRomanSemiBold12WhiteA700b2: 'font-assistant font-semibold',
  txtLoraRomanMedium12: 'font-lora font-medium',
  txtAssistantRomanSemiBold12: 'font-assistant font-semibold',
  txtInterExtraBold30: 'font-extrabold font-inter',
  txtAssistantRomanSemiBold12Green400: 'font-assistant font-semibold',
  txtAssistantRomanSemiBold16: 'font-assistant font-semibold',
  txtAssistantRomanRegular12WhiteA700: 'font-assistant font-normal',
  txtRobotoRomanRegular12: 'font-normal font-roboto',
  txtAssistantRomanSemiBold15: 'font-assistant font-semibold',
  txtRalewayMedium16: 'font-medium font-raleway',
  txtAssistantRomanRegular12Gray50: 'font-assistant font-normal'
}

const Text = ({ children, className = '', size, as, ...restProps }) => {
  const Component = as || 'p'

  return (
    <Component
      className={`text-left ${className} ${size && sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  )
}

export { Text }
