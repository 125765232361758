import React, { useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
// import { Button } from "components";
import { Link, useNavigate } from 'react-router-dom'
import { Heading } from 'components/Heading'
import { Input } from 'components/Input'
import { CheckBox } from 'components/CheckBox'
import { Text } from 'components'
import { AuthContext } from 'Context/AuthContext'

const shapes = { round: 'rounded' }
const variants = {
  fill: {
    yellow_900: 'bg-yellow-900 text-white-A700',
    amber_600: 'bg-amber-600'
  }
}
const sizes = { xs: 'py-2.5', sm: 'p-[13px]' }

const Button = ({
  children,
  className = '',
  leftIcon,
  rightIcon,
  shape = '',
  size = '',
  variant = '',
  color = '',
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${(shape && shapes[shape]) || ''} ${
        (size && sizes[size]) || ''
      } ${(variant && variants[variant]?.[color]) || ''}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  )
}

const Login = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const { login } = useContext(AuthContext)
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const [emailfill, setEmailfiill] = useState('')
  const [passfill, setPassfiill] = useState('')

  const handleClick = async () => {
    if (!email) {
      setEmailfiill(true)
      return
    } else if (!password) {
      setPassfiill(true)
      return
    }
    try {
      const response = await fetch('https://api.coinotify.io/user/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user: {
            email,
            password
          }
        })
      })
      if (response.ok) {
        // window.location.reload()
        // if (showSuccessModal !== false) {

        setShowSuccessModal(true)
        // }
        const data = await response.json()
        // console.log('login data', data.user.token)
        // console.log('login data', data)
        // alert('login data', data.user.token)
        login(data.user) // Use context login
        localStorage.setItem('token', data.user.token)
        setTimeout(() => navigate('/'), 2000)
      } else {
        return response.json().then(errorData => {
          const errorMessage =
            errorData.message || response.statusText || 'An error occurred'
          console.error(`Error: ${response.status} - ${errorMessage}`)

          setError(`${errorMessage}`)
          setTimeout(() => setError(''), 5000)
          console.error('Login failed')
          // console.error(response.statusText)
          // throw new Error(`Error: ${response.status} - ${errorMessage}`)
        })
      }
    } catch (error) {
      console.error('Network error:', error)
    }
  }

  const handleForgotPassword = async () => {
    try {
      const response = await fetch(
        'https://api.coinotify.io/user/forgotPassword',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email })
        }
      )

      if (response.ok) {
        const data = await response.json()
        // console.log('Password reset email sent:', data)
      } else {
        console.error('Error:', response.statusText)
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const handleCloseError = () => {
    setError('')
  }

  const handleCloseModal = () => {
    setShowSuccessModal(false)
    navigate('/portfolio')
  }

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Coinotify || Login</title>
        <link rel='canonical' href='https://coinotify.io/login' />
      </Helmet>
      <div className='flex justify-center w-full px-14 py-[88px] md:p-5 bg-gray-900 md:pt-20'>
        <div className='flex flex-col items-center w-[32%] md:w-full mb-[34px] gap-6 p-[21px] sm:p-5 bg-gray-700 rounded-[15px]'>
          <Heading
            size='8xl'
            as='h1'
            className='tracking-[0.14px] !font-raleway text-center'
          >
            Login
          </Heading>
          <div className='w-[84%] md:w-full mb-[5px]'>
            <input
              name='email'
              type='email'
              placeholder='Email'
              style={{
                width: '100%',
                padding: '10px',
                marginBottom: '15px',
                backgroundColor: '#4a5568',
                color: '#ffffff',
                // border: '1px solid #ffffff',
                borderColor: emailfill ? '#ff0000' : '#ffffff',
                borderRadius: '5px'
              }}
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <input
              name='password'
              placeholder='Password'
              type='password'
              style={{
                width: '100%',
                padding: '10px',
                marginBottom: '15px',
                backgroundColor: '#4a5568',
                color: '#ffffff',
                // border: '1px solid #ffffff',
                borderColor: passfill ? '#ff0000' : '#ffffff',
                borderRadius: '5px'
              }}
              value={password}
              onChange={e => setPassword(e.target.value)}
            />

            <Button
              className='cursor-pointer font-bold font-raleway h-[45px] leading-[normal] my-[30px] rounded-[12px] text-[15px] text-center tracking-[0.10px] w-[347px]] w-[100%]'
              color='yellow_900'
              size='sm'
              variant='fill'
              onClick={handleClick}
            >
              Sign In
            </Button>
            <p className='text-[#fff]'>
              New to Crypto News?{' '}
              <Link to='/signup' className='!text-indigo-300'>
                Create an Account
              </Link>
            </p>
            <Button
              size='lg'
              as='p'
              className='self-end mt-3 mb-[5px] !text-indigo-300 !font-plusjakartasans !font-normal'
              onClick={handleForgotPassword}
            >
              Forgot password ?
            </Button>
          </div>
        </div>

        {/* {showSuccessModal && (
          <div className='fixed inset- flex items-center justify-center bg-black bg-opacity50 z-[9999999999] bg-lime-600 h-[400px] w-[500px]'>
            <div className='bg-white p-6 rounded-lg shadow-lg'>
              <p className='mb-6'>"You have successfully logged in!"</p>
              <button
                onClick={handleCloseModal}
                className='bg-blue-500 text-white px-4 py-2 rounded'
              >
                OK
              </button>
            </div>
          </div>
        )} */}

        {showSuccessModal && (
          <div
            className='fixed top-40 right-4 w[500px] z-50 max-w-xs bg-[#15ff21] text-white p-4 rounded-lg shadow-lg transform transition-transform duration-500 ease-in-out translate-x-full'
            style={{
              transform: showSuccessModal ? 'translateX(0)' : 'translateX(130%)'
            }}
          >
            <div className='flex justify-between items-center'>
              <span>Login successfully!</span>
              <button onClick={handleCloseError} className='ml-4 text-xl'>
                &times;
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Login
